import React, { useEffect, useState } from "react";
import InvoiceTable from "../components/modals/InvoiceTable";
import AdHocModal from "../components/modals/AdHocModal";
import BillingNavbar from "../components/BillingNavbar";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card } from "primereact/card";
import { useLocation } from "react-router-dom";
import ChangeCustomerStatus from "../../customer_profile/change_customer_status/change_customer_status";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { createContext } from "react";
import ChangeDueDate from '../components/modals/ChangeDueDate'
import AutoPay from "../components/modals/AutoPay";
import UpdateAutoPay from "../components/modals/UpdateAutoPay";
import PayInvoices from "../components/PayInvoices/PayInvoice";
import PaymentLogs from "../components/modals/PaymentLogs";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const onAPISuccess = createContext();
const InvoicePage = () => {
    const [refresh, setRefresh] = useState(false);
    const [accountType, setAccountType] = useState("");
    const [invoices, setInvoices] = useState([]);
    const [changeCustomerStatusDialog, setChangeCustomerStatus] = useState(false);
    const [adHocInvoiceModal, setAdHocInvoiceModal] = useState(false);
    const [changeDueDate, setChangeDueDate] = useState(false)
    const [currentPlan, setCurrentPlan] = useState([]);
    const [userDetails, setUserDetails] = useState();
    const [payInvoicesVisble, setPayinvoicesVisible] = useState(false)
    const [cpData, setCpData] = useState([]);
    const [showAutoPay, setShowAutoPay] = useState(false);
    const [showUpdateAutoPay, setshowUpdateAutoPay] = useState(false)
    const [autoPayStatus, setAutoPayStatus] = useState("");
    const [showInvoiceTable, setShowInvoiceTable] = useState(true);
    const [showPaymentlogs, setShowPaymentlogs] = useState(false)
    const { state } = useLocation();

    const selectedId = state?.selectedId;
    const navigate = useNavigate()

    if (selectedId === undefined) {
        navigate("/customer-profile")
        setTimeout(() => {
            setRefresh(prev => !prev)
        }, 2)
    }

    const handleAPISuccess = (responseData) => {
        window.location.reload();
    };

    const getUserDetail = async () => {
        try {
            const response = await Axios.get(`${BASE_URL}/api/user/userDetails?userId=${selectedId}`);
            if (response?.status === 200 || response?.status === 201) {
                const userData = response?.data?.data || [];
                setCpData(userData);
                setUserDetails(userData);
                setAccountType(userData?.accountType);
                const customerId = userData?._id;
                if (customerId) {
                    getAutoPayStatus(customerId);
                }
            }
        } catch (error) {
            console.error("Error fetching user details:", error);
        }
    };

    const getAutoPayStatus = async (customerId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/api/user/getAutoPayStatus?customerId=${customerId}`);
            if (response?.status === 200 || response?.status === 201) {
                setAutoPayStatus(response?.data?.status);
            }
        } catch (error) {
            console.error("Error fetching auto pay status:", error);
        }
    };

    const getInvoice = async () => {
        const resp = await Axios.get(`${BASE_URL}/api/web/invoices/getinvoicebycustomerid?customerid=${selectedId}`);
        if (resp?.status == "200" || resp?.status == "201") {
            setCurrentPlan(resp?.data?.data);
            setInvoices(resp?.data?.data);
            localStorage.setItem("invoiceData", JSON.stringify(resp?.data?.data));
        }
    };
    useEffect(() => {
        getUserDetail();
        getInvoice();
        setRefresh(false)
    }, [selectedId, refresh]);

    return (  
        <> 
            <onAPISuccess.Provider value={{ handleAPISuccess }}>
                <Card>
                    <BillingNavbar refresh={refresh} userDetails={userDetails} setRefresh={setRefresh} setChangeCustomerStatus={setChangeCustomerStatus} changeCustomerStatusDialog={changeCustomerStatusDialog} />
                    <Dialog draggable={false} visible={changeCustomerStatusDialog} header={`Change Customer Status`} style={{ width: "70vw" }} onHide={() => setChangeCustomerStatus((prev) => !prev)}>
                        <ChangeCustomerStatus cpData={cpData} setChangeCustomerStatus={setChangeCustomerStatus} />
                    </Dialog>
                    <Dialog draggable={false} visible={adHocInvoiceModal} header={`Add Adhoc Invoice`} style={{ width: "50vw" }} onHide={() => setAdHocInvoiceModal((prev) => !prev)}>
                        <AdHocModal setRefresh={setRefresh} cpData={cpData} onAPISuccess={handleAPISuccess} adHocInvoiceModal={adHocInvoiceModal} setAdHocInvoiceModal={setAdHocInvoiceModal} />
                    </Dialog>
                    <Dialog draggable={false} visible={changeDueDate} header={`Change Due Date`} style={{ width: "40vw" }} onHide={() => setChangeDueDate((prev) => !prev)}>
                        <ChangeDueDate setRefresh={setRefresh} cpData={cpData} onAPISuccess={handleAPISuccess} invoiceData={invoices} setChangeDueDate={setChangeDueDate} setShowAutoPay={setShowAutoPay} />
                    </Dialog>
                    <Dialog draggable={false} visible={showAutoPay} header={`Set Auto Pay`} style={{ width: "70vw" }} onHide={() => setShowAutoPay((prev) => !prev)}>
                        <AutoPay setRefresh={setRefresh} setshowUpdateAutoPay={setshowUpdateAutoPay} cpData={cpData} setRefersh={setRefresh} invoiceData={invoices} setShowAutoPay={setShowAutoPay} />
                    </Dialog>
                    <Dialog draggable={false} visible={showUpdateAutoPay} header={`Auto pay Setup`} style={{ width: "50vw" }} onHide={() => setshowUpdateAutoPay((prev) => !prev)}>
                        <UpdateAutoPay setRefresh={setRefresh} cpData={cpData} setRefersh={setRefresh} refresh={refresh} invoiceData={invoices} setShowAutoPay={setShowAutoPay} userDetails={userDetails} autoPayStatus={autoPayStatus} />
                    </Dialog>
                    <Dialog draggable={false} visible={payInvoicesVisble} header="Pay Invoices" style={{ width: "98vw" }} onHide={() => { setPayinvoicesVisible((prev) => !prev);  setRefresh(prev => !prev) }}>
                        <PayInvoices setRefresh={setRefresh} cpData={cpData} setPayinvoicesVisible={setPayinvoicesVisible} />
                    </Dialog>
                    <hr />
                    <div className="flex">
                        <div className="mt-2 mb-2 ">
                            <Button
                                label="AD HOC Invoice"
                                className="btn"
                                onClick={() => {
                                    setAdHocInvoiceModal(true);
                                }}
                            />
                        </div>
                        {
                            userDetails?.autopaySetup ?
                                <div className="mt-2 mb-2 ml-5 ">
                                    <Button
                                        label="Auto Pay"
                                        className="btn"
                                        onClick={() => {
                                            setshowUpdateAutoPay(true);
                                        }}
                                    />
                                </div> : <div className="mt-2 mb-2 ml-5 ">
                                    <Button
                                        label="Auto Pay"
                                        text
                                        raised
                                        onClick={() => {
                                            setShowAutoPay(true);
                                        }}
                                    />
                                </div>
                        }
                        <div className="mt-2 mb-2 ml-5">
                            <Button
                                label="Pay Invoice"
                                className="btn"
                                onClick={() => {
                                    setPayinvoicesVisible(prev => !prev)
                                }}
                            />
                        </div>
                        <div className="mt-2 mb-2 ml-5">
                            {invoices.length > 0 ? <Button
                                label="Due Date"
                                className="btn"
                                onClick={() => {  setChangeDueDate(true); }}
                            /> : ""}
                        </div>
                    </div>
                    <hr />
                    <div className="flex">
                        <div className="mt-2 mr-5 " >
                            <Button
                                label="Invoices"
                                className="btn"
                                onClick={() => { setShowInvoiceTable(true); }}
                            />
                        </div>
                        <div className="mt-2 mr-5 " >
                            <Button
                                label="Payment Logs"
                                className="btn"
                                onClick={() => {
                                    { setShowInvoiceTable(false); setShowPaymentlogs(true) };
                                }}
                            />
                        </div>
                    </div>
                    <div className=" pt-3 ">
                        {
                            showInvoiceTable ? <InvoiceTable setRefresh={setRefresh} refresh={refresh}  userDetails={userDetails} className="mb-3" invoiceData={invoices} /> : showPaymentlogs ? <PaymentLogs setRefresh={setRefresh} refresh={refresh} userDetails={userDetails} /> : ""
                        }
                    </div>
                </Card>
            </onAPISuccess.Provider>
        </>
    );
};

export default InvoicePage;
export { onAPISuccess };