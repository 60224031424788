import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
const Preview_Final_component = ({parentDetails,initiationData, enrollment_id }) => {
    const navigate = useNavigate();

 
    const movepageToAll = () => {     
        if(parentDetails?.accountType === "Prepaid"){
        navigate("/prepaid-allenrollment"); 
        }
    };

    const handleNavigate = () => {
        navigate("/customer-profile", { state: { selectedId:initiationData?._id } });
        localStorage.setItem("selectedId", JSON.stringify(initiationData?._id));
    };
    return (
        <>
            <div className="final-pre" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h3 style={{ fontSize: "18px", fontFamily: "Inter", fontWeight: "600" }}>
                    Enrollement is successfully saved against Enrollment ID:
                    <span onClick={handleNavigate} style={{ cursor: "pointer", color: "#0475B4", marginLeft: "10px" }} className="steric">
                        {enrollment_id}
                    </span>
                </h3>
                <Button style={{ alignItems: "center" }} label="OK" onClick={  movepageToAll} className="btn" />
            </div>
        </>
    );
};

export default Preview_Final_component;
