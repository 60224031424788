import React from "react";
export const MenuIcon = () => {
    return (
        <svg className="menu-icon" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1320_2182)">
                <path
                    d="M13.9 -0.533203H1.1C0.22 -0.533203 -0.5 0.141797 -0.5 0.966797V13.9668C-0.5 14.7918 0.22 15.4668 1.1 15.4668H13.9C14.78 15.4668 15.5 14.7918 15.5 13.9668V0.966797C15.5 0.141797 14.78 -0.533203 13.9 -0.533203ZM13.3667 13.4668H1.63333V1.4668H13.3667V13.4668ZM3.76667 8.4668H11.2333V9.4668H3.76667V8.4668ZM3.76667 10.4668H11.2333V11.4668H3.76667V10.4668ZM4.83333 3.9668C4.8334 3.76975 4.87487 3.57464 4.95537 3.39262C5.03587 3.21059 5.15382 3.04522 5.3025 2.90593C5.45117 2.76664 5.62765 2.65617 5.82186 2.58082C6.01607 2.50548 6.22422 2.46673 6.4344 2.4668C6.64459 2.46686 6.8527 2.50574 7.04686 2.58121C7.24102 2.65667 7.41742 2.76726 7.56599 2.90664C7.71457 3.04602 7.8324 3.21147 7.91277 3.39354C7.99314 3.57562 8.03447 3.77075 8.0344 3.9678C8.03426 4.36575 7.8655 4.74736 7.56524 5.02866C7.26498 5.30997 6.85782 5.46793 6.43333 5.4678C6.00885 5.46766 5.6018 5.30945 5.30174 5.02796C5.00168 4.74646 4.83319 4.36475 4.83333 3.9668ZM7.5 5.4668H5.36667C4.48667 5.4668 3.76667 5.9168 3.76667 6.4668V7.4668H9.1V6.4668C9.1 5.9168 8.38 5.4668 7.5 5.4668Z"
                    fill="url(#paint0_linear_1320_2182)"
                />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1320_2182" x1="-0.5" y1="7.47584" x2="15.5" y2="7.47584" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EDAEF9" stop-opacity="0.905882" />
                    <stop offset="1" stop-color="#81B1FA" />
                </linearGradient>
                <clipPath id="clip0_1320_2182">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export const SidebarSelect = () => {
    return (
        <svg style={{ width: "inherit", height: "inherit" }} viewBox="0 0 228 111" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd_1320_2200)">
                <path
                    d="M5 55.6229C5 44.3892 14.1067 35.2825 25.3403 35.2825H196.717C215.099 35.2825 230 20.3814 230 2V56.3849V107C229.917 89.8456 215.988 75.9826 198.834 75.9808L117.425 75.9725L25.3383 75.9632C14.1054 75.9621 5 66.8557 5 55.6229Z"
                    fill="url(#paint0_linear_1320_2200)"
                    fill-opacity="0.14"
                    shape-rendering="crispEdges"
                />
            </g>
            <defs>
                <filter id="filter0_dd_1320_2200" x="0.3" y="0.3" width="230.4" height="110.4" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.733333 0 0 0 0 0.870588 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1320_2200" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="1" />
                    <feGaussianBlur stdDeviation="1.35" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.732954 0 0 0 0 0.871818 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_1320_2200" result="effect2_dropShadow_1320_2200" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1320_2200" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_1320_2200" x1="291" y1="43" x2="5" y2="54.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E0EDFE" />
                    <stop offset="1" stop-color="#6EABFF" />
                </linearGradient>
            </defs>
        </svg>
    );
};
export const SidebarSelector = () => {
    return (
        <svg style={{ width: "inherit", height: "inherit" }} viewBox="0 0 90 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.818848 -1V10.6948C0.818848 10.7942 0.819332 10.8927 0.820298 10.9902V53.6922C0.820298 53.9232 0.822911 54.149 0.82811 54.3695V96.6863C0.82811 97.0319 0.833954 97.3655 0.845551 97.6875H0.771973V141.63C0.771973 145.582 1.53638 147.97 2.92673 149.352C4.32375 150.741 6.22875 150.989 8.15367 150.989H10.4367C10.7309 150.989 10.9694 150.75 10.9694 150.456C10.9694 150.162 10.7309 149.923 10.4367 149.923H8.15367C6.27359 149.923 4.7541 149.667 3.67786 148.597C2.59497 147.52 1.83737 145.495 1.83737 141.63V102.738C2.15096 103.412 2.53435 103.963 2.98287 104.409C4.37989 105.798 6.28489 106.045 8.20981 106.045H10.4928C10.787 106.045 11.0255 105.807 11.0255 105.513C11.0255 105.218 10.787 104.98 10.4928 104.98H8.20981C6.32973 104.98 4.81023 104.723 3.734 103.653C2.65111 102.577 1.89351 100.552 1.89351 96.6863V59.8779C2.1948 60.4915 2.55657 60.9986 2.97506 61.4147C4.37207 62.8035 6.27708 63.0511 8.202 63.0511H10.485C10.7792 63.0511 11.0177 62.8126 11.0177 62.5184C11.0177 62.2242 10.7792 61.9857 10.485 61.9857H8.202C6.32192 61.9857 4.80242 61.729 3.72619 60.6591C2.70714 59.646 1.97617 57.7931 1.89351 54.3569V52.7441H1.8857V16.8676C2.18825 17.4869 2.55218 17.9983 2.97361 18.4173C4.08811 19.5253 5.52591 19.9069 7.03921 20.016C7.28867 21.7043 8.74374 23 10.5014 23C12.4344 23 14.0014 21.433 14.0014 19.5C14.0014 17.567 12.4344 16 10.5014 16C8.75637 16 7.30959 17.2771 7.04477 18.9477C5.69091 18.8414 4.57084 18.5029 3.72474 17.6617C2.66926 16.6124 1.92282 14.6622 1.8857 10.9849V9.75H1.88425V-1H0.818848Z"
                fill="url(#paint0_linear_1320_2222)"
            />
            <defs>
                <linearGradient id="paint0_linear_1320_2222" x1="0.771973" y1="75.0802" x2="14.0014" y2="75.0802" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EDAEF9" stop-opacity="0.905882" />
                    <stop offset="1" stop-color="#81B1FA" />
                </linearGradient>
            </defs>
        </svg>
    );
};
