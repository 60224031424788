import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import Axios from "axios";
import moment from "moment";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Carrier-Chart.css";
import CommonLoaderBlue from "../../../../../components/Loader/LoaderBlue";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const CarrierChart = ({ shortSelection, selectedRange }) => {  

    const [carrierData, setCarrierData] = useState([]);
    const [selectedCarrierId, setSelectedCarrierId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentSelect, setCurrentSelect] = useState("at&t");
    const [chartLabels, setChartLabels] = useState([]);
    const [dataChart, setDataChart] = useState([]);

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/carrier/all`)
            .then((res) => {
                const defaultCarrier = res?.data?.data.find((carrier) => carrier?.name === "AT&T" || carrier?.name === "TMB") || res?.data?.data[0];
                setCarrierData(res?.data?.data);
                if (defaultCarrier) {
                    setCurrentSelect(defaultCarrier.name.toLowerCase());
                    setSelectedCarrierId(defaultCarrier._id);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const chartRef = useRef(null);
    const [fontSize, setFontSize] = useState(12);
    const [barWidth, setBarWidth] = useState(24);

    const getFontSize = () => {
        if (window.innerHeight < 639) return 8;
        if (window.innerHeight < 800) return 10;
        return 12;
    };

    const getBarWidth = () => {
        return window.innerHeight < 800 ? 18 : 24;
    };

    useEffect(() => {
        const updateDimensions = () => {
            setFontSize(getFontSize());
            setBarWidth(getBarWidth());
        };
        window.addEventListener("resize", updateDimensions);
        updateDimensions();
        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    const fetchDataAndInitializeChart = () => {
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/dashboard/getEnrollmentsByCarrier`, {
            params: {
                accountType: "Prepaid",
                carrier: selectedCarrierId,
                filter: shortSelection,
                startDate: moment(selectedRange[0])?.isValid() ? moment(selectedRange[0])?.format("MM-DD-YYYY") : null,
                endData: moment(selectedRange[1])?.isValid() ? moment(selectedRange[1])?.format("MM-DD-YYYY") : null,
            },
        })
            .then((res) => {
                const labels = res.data.data.map((item) => item.month);
                const data = res.data.data.map((item) => item.totalEnrollments);
                setChartLabels(labels);
                setDataChart(data);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                if (err?.response?.data?.msg === "No customers found.") {
                    setChartLabels([]);
                    setDataChart([]);
                    setIsLoading(false);
                } 
                setIsLoading(false)
            });
    };

    useEffect(() => {
        if (selectedCarrierId) {
            fetchDataAndInitializeChart();
        }
    }, [selectedCarrierId, shortSelection, selectedRange]);

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: "Enrollment",
                data: dataChart,
                backgroundColor: (context) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
                    if (currentSelect === "tmb") {
                        gradient.addColorStop(0, "rgba(138, 237, 246, 1)");
                        gradient.addColorStop(1, "rgba(96, 162, 245, 0.5)");
                    } else {
                        gradient.addColorStop(0, "rgba(111, 209, 149, 1)");
                        gradient.addColorStop(1, "rgba(111, 209, 149, 0.5)");
                    }
                    return gradient;
                },
                borderRadius: 5,
                borderSkipped: false,
                maxBarThickness: 25,
                roundCap: false,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: { display: false, drawBorder: false },
                border: false,
                ticks: {
                    font: { size: fontSize, family: "Inter" },
                         
                    maxRotation: 0, // Disable rotation
                    minRotation: 0, // Disable rotation
                    autoSkip: true, // Enable auto-skipping of labels
                  
                },
            },
            y: {
                grid: { display: false, drawBorder: false },
                border: false,
                ticks: {
                    font: { size: fontSize, family: "Inter" },
                },
            },
        },
        plugins: {
            legend: { display: false },
            tooltip: { enabled: true,    
                titleFont: {
                    family: 'Inter', // Set font family for tooltip title
                    size: 13, // Font size for title
                    weight: '400', // Font weight for title
                  },   
                  bodyFont: {
                    family: 'Inter', // Set font family for tooltip body
                    size: 12, // Font size for body
                  },
                  footerFont: {
                    family: 'Inter', // Set font family for tooltip footer
                    size: 12, // Font size for footer
                  }, callbacks: { label: (context) => `Customers ${context.raw}` } },
            datalabels: {
                anchor: "end",
                align: "end",
                offset: -5,
                color: currentSelect === "tmb" ? "#60A2F5" : "#00FF63",
                font: { family: "Inter", size: fontSize },
                formatter: (value) => value,
            },
        },
    };

    return (
        <div className="carrier-chart-main">
            <h1>Enrollment By Carrier</h1>
            <div className="carrier-chart-main-container">
                {isLoading ? (
                    <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">
                        <CommonLoaderBlue sizehere={20} color={currentSelect === "tmb" ? "#6FD195" : "rgb(78, 105, 251)"} />
                    </div>
                ) : (
                    <Bar ref={chartRef} data={data} options={options} />
                )}
            </div>
            <div className="main-carrier-select flex flex-wrap flex-row justify-content-center">
                {carrierData?.map((item) => (
                    <div className="main-carrier-selection flex flex-wrap flex-row justify-content-start align-items-center" key={item._id}>
                        <div
                            onClick={() => {         
                                if(!isLoading && currentSelect !== item?.name.toLowerCase()){
                                setIsLoading(true);
                                setSelectedCarrierId(item?._id);
                                setCurrentSelect(item?.name.toLowerCase());    
                                }
                            }}
                            style={{
                                backgroundColor: (currentSelect === "tmb" && item?.name === "TMB") || (currentSelect === "at&t" && item?.name === "AT&T") ? (currentSelect === "tmb" ? "#7086FD" : "#6FD195") : "",
                                cursor: "pointer",
                                border: item?.name === "TMB" ? "solid #7086FD 0.7px" : "solid #6FD195 0.7px",
                            }}
                            className="carrier-rectangle"
                        ></div>
                        <h1 className={item?.name.toLowerCase()}>{item?.name}</h1>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CarrierChart;
