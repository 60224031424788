import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditPlan from "./edit_plan";
import Axios from "axios";
import { Card } from "primereact/card";
import PlansConfigurations from "./plan_configuration";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import CommonLoaderBlue from "../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
export default function ListAllPlans() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loader, setLoader] = useState(true);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [refresh, setRefresh] = useState(false);
    const [editPlanVisibility, setEditPlanVisibility] = useState(false);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (editPlanVisibility === false) {
            Axios.get(`${BASE_URL}/api/web/plan/all?serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    setPlanList(res?.data?.data);
                })
                .catch((err) => {});
        }
        if (addPlanVisibility === false) {
            Axios.get(`${BASE_URL}/api/web/plan/all?serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    setPlanList(res?.data?.data);
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
        }
    }, [refresh]);
    const [planList, setPlanList] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [addPlanVisibility, setAddPlanVisibility] = useState(false);
    return (
        <Card className="overflow-hidden">
            <ToastContainer />
            <div className="flex  flex-wrap flex-row justify-content-end">
                <Button
                    onClick={() => {
                        setAddPlanVisibility(true);
                    }}
                    className="text-center mr-0 btn"
                    label="Add Plan"
                />
            </div>
            <Dialog
                header="Add Plan"
                visible={addPlanVisibility}
                style={{ width: "80vw" }}
                onHide={() => {
                    setAddPlanVisibility(false);
                }}
            >
                <PlansConfigurations data={rowData} setRefresh={setRefresh} setAddPlanVisibility={setAddPlanVisibility} />
            </Dialog>
            <Dialog
                header="Update Plan"
                visible={editPlanVisibility}
                className="pt-0"
                style={{ width: "80vw" }}
                onHide={() => {
                    setEditPlanVisibility(false);
                }}
            >
                <EditPlan data={rowData} setRefresh={setRefresh} setEditPlanVisibility={setEditPlanVisibility} />
            </Dialog>
            <Dialog
                className="flex flex-wrap justify-content-center"
                header="Are You Sure You want to Delete This Plan ? &nbsp;&nbsp;"
                visible={visible}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
            >
                <div className="flex flex-wrap justify-content-center w-full">
                    <Button
                        label="yes"
                        className="btn"
                        onClick={() => {
                            Axios.patch(`${BASE_URL}/api/web/plan/updateStatus`, {
                                updatedBy: parseLoginRes._id,
                                serviceProvider: parseLoginRes.company,
                                id: rowData._id,
                                status: false,
                            })
                                .then((res) => {
                                    toast.success("Plan Removed Successfully");
                                    setRefresh((prev) => !prev);
                                })
                                .catch((err) => {
                                    toast.error("Plan Removal Failed");
                                });
                            setVisible(false);
                        }}
                    />
                    <Button className="ml-4 btn" label="No" onClick={() => setVisible(false)} />
                </div>
            </Dialog>
            <DataTable
                value={planList}
                size="small"
                stripedRows
                resizableColumns
                emptyMessage={
                    loader ? (
                        <div className="flex flex-wrap flex-row  mt-4 mb-4 " style={{ marginLeft: "calc(50% - 70px)" }}>
                            <CommonLoaderBlue />
                        </div>
                    ) : (
                        "No Plan Found"
                    )
                }
                className="mt-4"
            >
                <Column headerClassName="pl-1 p-4" header="Plan ID" field="planId" />
                <Column header="Name" field="name" />
                <Column header="Description" field="description" />

                <Column header="Inventory Type" field="inventoryType" />

                <Column header="Billing Model" field="type" />
                <Column header="Text Allowance" field="textAllowance" />
                <Column header="Data Allowance" field="dataAllowance" />
                <Column header="Voice Allowance" field="voiceAllowance" />
                <Column header="Retail Price" field="price" />

                <Column
                    header="Actions"
                    body={(rowData) => {
                        return (
                            <>
                                <div className="flex justify-content-center">
                                    <Button
                                        label="Update"
                                        onClick={() => {
                                            setRowData(rowData);
                                            setEditPlanVisibility(true);
                                        }}
                                        className="btn"
                                    />

                                    <Button
                                        className="btn ml-2"
                                        label="Delete"
                                        onClick={() => {
                                            setVisible(true);
                                            setRowData(rowData);
                                        }}
                                    />
                                </div>
                                {/* <Button
                                    label="Update"
                                    onClick={() => {
                                        setRowData(rowData);
                                        setEditPlanVisibility(true);
                                    }}
                                    className="btn"
                                    text
                                    raised
                                />
                                <Button
                                    label="Delete"
                                    onClick={() => {
                                        Axios.patch(`${BASE_URL}/api/web/plan/updateStatus`, {
                                            updatedBy: parseLoginRes._id,
                                            serviceProvider: parseLoginRes.company,
                                            id: rowData._id,
                                            status: false,
                                        })
                                            .then((res) => {
                                                toast.success("Plan Removed Successdully");
                                                setRefresh((prev) => !prev);
                                            })
                                            .catch((err) => {
                                                toast.error("Plan Removal Failed");
                                            });
                                    }}
                                    className="btn"
                                    text
                                    raised
                                /> */}
                            </>
                        );
                    }}
                    field="Edit"
                />
            </DataTable>
        </Card>
    );
}
