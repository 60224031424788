import React from "react";
import "./Prepaid-New-Enrollment-Card.css";
import { useNavigate } from "react-router-dom";
export default function PrepaidNewEnrollmentCard() {
    const navigate = useNavigate();
    return (
        <div
            class="prepaid-new-card-main-container"
            onClick={() => {
                navigate("/prepaid-newenrollment");
            }}
            style={{ cursor: "pointer" }}
        >
            <div className="circle7"></div>
            <div class="circle-main-container">
                <div class="circle0"></div>
                <div class="circle1"></div>
                <div class="circle2"></div>
                <div class="circle3"></div>
                <div class="circle4"></div>

                <div class="circle6"></div>
            </div>
            <p className="main-tag">New Enrollment</p>
            <div className="flex flex-wrap flex-row justify-content-center w-full">
                <div className="main-create-enrollment-tag flex flex-wrap flex-row justify-content-between ">
                    <p className="enrollment-tag">Prepaid</p>
                    <p className="enrollment-tag-create-new">Create A New Enrollment</p>
                </div>
            </div>
        </div>
    );
}
