import React from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentFailed.css";
import { Button } from "primereact/button";
export default function PaymentFailed({ setIsBankOfAmericaPrepaidPaymentSuceeded, setCheckForBankOfAmericaPayment }) {
    let navigate = useNavigate();
    return (
        <div className="main-payment fixed  top-0 left-0">
            <div className="main-container flex flex-wrap flex-row justify-content-center align-items-start w-full">
                <div className="check-time-main">
                    <i className="pi pi-times" />
                </div>
                <h1>Payment Failed!</h1>
                <p>Your Payment Has Been Declined</p>
                <div className="flex flex-wrap w-full flex-row justify-content-center items-center">
                    <button
                        onClick={() => {
                            setIsBankOfAmericaPrepaidPaymentSuceeded(false);
                            navigate("/");
                            setCheckForBankOfAmericaPayment((prev) => !prev);
                        }}
                        className="redirect-button"
                        label="Redirect Back"
                    >
                        Redirect Back
                    </button>
                </div>
            </div>
        </div>
    );
}
