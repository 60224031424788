import React from "react";
import "./Area-Chart-Filter-Dialog.css";
import { Button } from "primereact/button";
import DateRangeCalendar from "./Area-Chart-DatePicker";
export default function AreaChartFilterDialog({ setShowDateFilter, shortSelection, setShortSelection, selectedRange, setSelectedRange }) {
    return (
        <div
            onMouseLeave={() => {
                setShowDateFilter((prev) => !prev);
            }}
            className="main-area-chart-date-filter-dialog flex flex-wrap flex-row justify-content-between align-items-start "
        >
            <div className="direct-selection-filter flex flex-wrap flex-row justify-left">
                <p
                    className={`${shortSelection === "today" ? "selected-one" : ""}`}
                    onClick={() => {
                        setShortSelection("today");
                        setSelectedRange([null, null]);
                    }}
                >
                    Today
                </p>
                <p
                    className={`${shortSelection === "yesterday" ? "selected-one" : ""}`}
                    onClick={() => {
                        setShortSelection("yesterday");
                        setSelectedRange([null, null]);
                    }}
                >
                    Yesterday
                </p>
                <p
                    className={`${shortSelection === "lastWeek" ? "selected-one" : ""}`}
                    onClick={() => {
                        setShortSelection("lastWeek");
                        setSelectedRange([null, null]);
                    }}
                >
                    Last Week
                </p>
                <p
                    className={`${shortSelection === "lastMonth" ? "selected-one" : ""}`}
                    onClick={() => {
                        setShortSelection("lastMonth");
                        setSelectedRange([null, null]);
                    }}
                >
                    Last Month
                </p>
                <p
                    className={`${shortSelection === "last6Months" ? "selected-one" : ""}`}
                    onClick={() => {
                        setShortSelection("last6Months");
                        setSelectedRange([null, null]);
                    }}
                >
                    Last 6 Month
                </p>
                <p
                    className={`${shortSelection === "lastYear" ? "selected-one" : ""}`}
                    onClick={() => {
                        setShortSelection("lastYear");
                        setSelectedRange([null, null]);
                    }}
                >
                    Last Year
                </p>
                <p
                    onClick={() => {
                        setShortSelection("");
                        setSelectedRange([null, null]);
                    }}
                >
                    Reset
                </p>
            </div>
            <div className="main-main-range-container">
                <DateRangeCalendar setShortSelection={setShortSelection} selectedRange={selectedRange} setSelectedRange={setSelectedRange} />
            </div>
        </div>
    );
}
