import React from "react"; 
export const Users=()=>{ 
    return( 
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.3271 7.93615C17.9855 8.59451 17.9855 9.66192 17.3271 10.3203C16.6688 10.9786 15.6013 10.9786 14.943 10.3203C14.2846 9.66192 14.2846 8.59451 14.943 7.93615C15.6013 7.27779 16.6688 7.27779 17.3271 7.93615" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.8357 4.9268C12.8494 5.94054 12.8494 7.58413 11.8357 8.59787C10.8219 9.6116 9.17834 9.6116 8.1646 8.59787C7.15086 7.58414 7.15086 5.94055 8.1646 4.9268C9.17833 3.91307 10.8219 3.91307 11.8357 4.9268" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.05661 7.93615C5.71496 8.59451 5.71496 9.66192 5.05661 10.3203C4.39825 10.9786 3.33084 10.9786 2.67248 10.3203C2.01412 9.66192 2.01412 8.59451 2.67248 7.93615C3.33084 7.27779 4.39825 7.27779 5.05661 7.93615" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M19.1668 15.8331V14.9198C19.1668 13.7689 18.2343 12.8364 17.0835 12.8364H16.416" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.833008 15.8331V14.9198C0.833008 13.7689 1.76551 12.8364 2.91634 12.8364H3.58384" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.449 15.8334V14.4992C14.449 12.8884 13.1431 11.5825 11.5323 11.5825H8.46647C6.85564 11.5825 5.5498 12.8884 5.5498 14.4992V15.8334" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
} 
export const TotalEnrollment=()=>{ 
    return( 
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="6.66602" y="10.8335" width="2.5" height="3.33333" rx="0.833333" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.08301 8.33322L8.74967 8.33312" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.08301 5.83322L8.74967 5.83312" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.6663 7.08333H13.7497C12.8292 7.08333 12.083 6.33714 12.083 5.41667V2.5" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9341 5.1011L14.0652 3.23223C13.5964 2.76339 12.9605 2.5 12.2975 2.5H5.83301C4.4523 2.5 3.33301 3.61929 3.33301 5V15C3.33301 16.3807 4.4523 17.5 5.83301 17.5H14.1663C15.5471 17.5 16.6663 16.3807 16.6663 15V6.86887C16.6663 6.20583 16.4029 5.56994 15.9341 5.1011Z" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}  
export const ActiveClients=()=>{ 
    return( 
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0004 12.4793V11.6459C10.0126 10.9792 9.75318 10.3362 9.28166 9.86467C8.81015 9.39315 8.16713 9.13369 7.50041 9.14592H5.00041C4.3337 9.13369 3.69068 9.39315 3.21916 9.86467C2.74764 10.3362 2.48818 10.9792 2.50041 11.6459V12.4793" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.667 6.6665C11.667 7.58698 12.4132 8.33317 13.3337 8.33317C14.2541 8.33317 15.0003 7.58698 15.0003 6.6665" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.833 4.1665H15.833C16.7535 4.1665 17.4997 4.9127 17.4997 5.83317V9.99984C17.4997 10.9203 16.7535 11.6665 15.833 11.6665H12.4997" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.625 14.5835C14.1247 15.524 13.3635 16.3002 12.4329 16.8188C11.1543 17.5254 9.64484 17.6866 8.24588 17.2659C6.84692 16.8452 5.67672 15.8782 5 14.5835" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="6.25033" cy="4.58333" r="2.08333" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.0002 16.6673L15.0003 14.5841L12.917 14.584" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}  
export const Revenue=()=>{ 
    return( 
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.00202 5.87653H1.50098V3.37549" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.22461 15.6084C5.97492 16.0427 6.8061 16.3504 7.69147 16.5054" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.90332 11.6504C2.20845 12.4874 2.6503 13.2569 3.2122 13.9263" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.3164 16.5054C11.2018 16.3504 12.0321 16.0427 12.7833 15.6084" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.7969 13.9263C15.3588 13.2569 15.8006 12.4874 16.1058 11.6504" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5071 9.00361C16.5071 4.85939 13.1482 1.50049 9.00396 1.50049C5.9777 1.50049 3.37578 3.29624 2.18945 5.87731" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.00473 6.61694V6.08588" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.00473 11.3925V11.9227" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.62303 10.8215C7.82895 11.1591 8.18076 11.3917 8.60677 11.3917H9.00444H9.4788C10.0966 11.3917 10.5959 10.8915 10.5959 10.2746C10.5959 9.76189 10.2466 9.31587 9.74975 9.18999L8.25746 8.81483C7.76142 8.69061 7.41211 8.24459 7.41211 7.73188C7.41211 7.11412 7.91232 6.61475 8.52924 6.61475H9.00444H9.4021C9.82645 6.61475 10.1783 6.84734 10.3833 7.18415" stroke="#9C9EB6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    )
}