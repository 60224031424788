import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
// import DialogForReject from "./dialogs/DialogForReject";
import { InputText } from "primereact/inputtext";
// import DialogeForRemarks from "./dialogs/DialogeForRemarks";
// import DialogeForTransferUser from "./dialogs/DialogeForTransferUser";
// import DialogeForRemarksForIJ from "./dialogs/DialogeForRemarksForIJ";
import "./all_enrollment.css";
import { Backbtn, Editbutton, Export, ExportHover, Filter, Import, ImportHover, Nextbtn, Sortby } from "../../../../../utility";
import CommonLoaderBlue from "../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const All_Enrollmentsf = () => {
    const [isEnrolmentId, setIsEnrolmentId] = useState();
    const [CsrId, setCsrId] = useState();
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [zipCode, setZipCode] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDialogeForActivate, setOpenDialogeForActivate] = useState(false);
    const [OpenDialogeForRemarks, setOpenDialogeForRemarks] = useState(false);
    const [OpenDialogeForRemarksForIJ, setOpenDialogeForRemarksForIJ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setisButtonLoading] = useState(false);
    const [link, setLink] = useState();
    const [allEnrollments, setAllEnrollments] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [checkType, setCheckType] = useState();
    const [customerSearchName, setCustomerSearchName] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [dialogeForTransfer, setDialogeForTransfer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [checkRemarks, setCheckRemarks] = useState();
    const [hover, setHover] = useState(false);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [hoverLeave, setHoverLeave] = useState(false);
    const [hoverAfter, setHoverAfter] = useState(false);
    const [hoverLeaveAfter, setHoverLeaveAfter] = useState(false);
    const [enrollmentFrom, setEnrollmentFrom] = useState(1);
    const [enrollmentTo, setEnrollmentTo] = useState(10);

    const navigate = useNavigate();
    const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const roleName = parseLoginRes?.role?.role;
    const toCapital = roleName.toUpperCase();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const getAllEnrollments = async () => {
        setIsLoading(true);
        try {
            const res = await Axios.get(`${BASE_URL}/api/user/EnrollmentApprovedByUser?userId=${parseLoginRes?._id}&accountType=Postpaid`);
            if (res?.status === 200 || res?.status === 201) {
                if (!res?.data?.data) {
                    toast.success(" No enrollments have been received from the previous department yet");
                } else if (res?.data?.data) {
                    const updatedData = res?.data?.data.map((item) => ({
                        ...item,
                        enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                        name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                        createdDate: new Date(item.createdAt)
                            .toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                            })
                            .replace(/\//g, "-"),
                        createdTo: item.createdAt,
                    }));

                    setAllEnrollments(updatedData.slice(0, 10));
                    setCompleteListEnrollment(updatedData);
                    setTotalPage(Math.ceil(updatedData.length / 10));
                }
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(`${error?.response?.data?.msg}`);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllEnrollments();
    }, []);
    const approveRowByTl = async (rowData) => {
        setisButtonLoading(true);
        const approvedBy = parseLoginRes?._id;
        const enrolmentId = rowData?._id;
        const approved = true;
        const dataToSend = { approvedBy, enrolmentId, approved };

        setCheckRemarks(rowData?.QualityRemarks);

        if (rowData?.QualityRemarks) {
            if (rowData && rowData.QualityRemarks && rowData.QualityRemarks.includes("declined")) {
                toast.error("Declined sales can only rejected");
                setisButtonLoading(false);
            } else {
                try {
                    const response = await Axios.patch(`${BASE_URL}/api/user/approval`, dataToSend);
                    if (response?.status === 201 || response?.status === 200) {
                        toast.success("Approved");
                        Axios.post(`${BASE_URL}/api/web/order`, { orderNumber: rowData.enrollmentId })
                            .then((response) => {
                                toast.success("Order Displaced Successfully");
                                Axios.post(`${BASE_URL}/api/web/order/createLable`, { orderId: response.data.data.orderId.toString(), userId: parseLoginRes._id, testLabel: true })
                                    .then(() => {
                                        toast.success("Label Successfully");
                                    })
                                    .catch((err) => {
                                        // toast.success("Label Creation Failed");
                                    });
                            })
                            .catch((err) => {
                                toast.success("Order Displacing Failed");
                            });
                        setisButtonLoading(false);
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                    setisButtonLoading(false);
                }
                getAllEnrollments();
            }
        } else {
            toast.error("Please Add Remarks First");
            setisButtonLoading(false);
        }
    };

    const runNV = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/verifyEligibility?enrollmentId=${rowData?._id}`);
            if (response?.status == 200 || response?.status == 201) {
                const link1 = response?.data?.data?._links?.certification?.href;
                const link2 = response?.data?.data?._links?.resolution?.href;
                if (link1) {
                    setLink(link1);
                } else {
                    setLink(link2);
                }
                const respMsg = response?.data?.data?.status;

                if (respMsg.includes("complete") || respMsg.includes("COMPLETE") || respMsg.includes("Complete")) {
                    toast.success(response?.data?.data?.status);
                } else {
                    toast.warning(response?.data?.data?.status);
                }

                setSelectedRow(rowData);
            }
        } catch (error) {
            const status = error?.response?.status;

            if (status === 500 || status === 400) {
                toast.error(error?.response?.data?.data?.message);
            } else {
                const error1 = error?.response?.data?.data?.body;
                const error2 = error?.response?.data?.data?.errors[0]?.description;

                const errorMessage1 = Array.isArray(error1) ? error1.toString() : error1 && typeof error1 === "object" ? JSON.stringify(error1) : error1;
                const errorMessage2 = Array.isArray(error2) ? error2.toString() : error2 && typeof error2 === "object" ? JSON.stringify(error2) : error2;
                if (errorMessage1) {
                    toast.error("Error is " + errorMessage1);
                } else {
                    toast.error("Error is " + errorMessage2);
                }
            }
        } finally {
            setisButtonLoading(false);
        }
    };

    const enrollUser = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/enrollVerifiedUser?userId=${parseLoginRes?._id}&enrollmentId=${rowData?._id}`);

            if (response?.status == "200" || response?.status == "201") {
                toast.success("Successfully Enrolled");
                getAllEnrollments();
                setisButtonLoading(false);
            }
        } catch (error) {
            const body = error?.response?.data?.data?.body;

            const errorMessage = Array.isArray(body) ? body.toString() : body && typeof body === "object" ? JSON.stringify(body) : body;
            toast.error("Error is " + errorMessage);
            setisButtonLoading(false);
        }
    };

    const HnadleAllApprove = async () => {
        setisButtonLoading(true);
        if (allEnrollments) {
            const enrollmentIds = allEnrollments.map((enrollment) => enrollment._id);

            const dataToSend = {
                approvedBy: parseLoginRes?._id,
                enrolmentIds: enrollmentIds,
                approved: true,
            };
            try {
                const response = await Axios.patch(`${BASE_URL}/api/user/batchApproval`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    toast.success("Approved");
                    setisButtonLoading(false);
                    getAllEnrollments();
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
            setisButtonLoading(false);
        } else {
            toast.error("No Enrollment Found");
            setisButtonLoading(false);
        }
    };

    const handleApproveSelected = async () => {
        setisButtonLoading(true);
        if (allEnrollments) {
            const enrollmentIds = selectedRows.map((enrollment) => enrollment._id);

            const dataToSend = {
                approvedBy: parseLoginRes?._id,
                enrolmentIds: enrollmentIds,
                approved: true,
            };
            try {
                const response = await Axios.patch(`${BASE_URL}/api/user/batchApproval`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    toast.success("Approved");
                    setisButtonLoading(false);
                    getAllEnrollments();
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
            setisButtonLoading(false);
        } else {
            toast.error("No Enrollment Found");
            setisButtonLoading(false);
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <div className="flex flex-wrap justify-content-start tableeditbtn" text raised disabled={isButtonLoading}>
                    <Editbutton />
                </div>
            </div>
        );
    };
    const handleEnrollmentBill = (rowData) => {
        navigate("/invoice", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };

    const actionTemplateForTL = (rowData) => {
        return (
            <div>
                {parseLoginRes?.companyName.includes("IJ") || parseLoginRes?.companyName.includes("ij") ? (
                    <Button label="Add Remarks" onClick={() => handleOpenDialogForRemarksForIJ(rowData)} className=" p-button-sucess mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                ) : (
                    <Button label="Add Remarks" onClick={() => handleOpenDialogForRemarks(rowData)} className="pt-1 pb-1 p-button-sucess mr-2 ml-2" text raised disabled={isButtonLoading} />
                )}

                {/*<Button label="Edit" onClick={() => viewRow(rowData)} className="pt-1 pb-1" text raised disabled={isButtonLoading} />*/}
                <Button label="Billing" onClick={() => handleEnrollmentBill(rowData)} text raised disabled={isButtonLoading} className="pt-1 pb-1" />

                <Button
                    label="Approve"
                    onClick={() => {
                        if (!rowData.QualityRemarks) {
                            toast.error("Please Add Remarks");
                        } else if (rowData.QualityRemarks === "satisfactory" || rowData.QualityRemarks === "good" || rowData.QualityRemarks === "average") {
                            approveRowByTl(rowData);
                        } else {
                            if (rowData.QualityRemarks !== undefined) {
                                toast.error("Only enrollment with call quality marked as good, average or satisfactory will be Approved");
                            } else {
                                toast.error("Please Add Remarks");
                            }
                        }
                    }}
                    className=" p-button-success mr-2 ml-2  pt-1 pb-1 "
                    text
                    raised
                    disabled={isButtonLoading}
                />
                <Button
                    label="Reject"
                    onClick={() => {
                        if (!rowData?.QualityRemarks) {
                            toast.error("Please Add Remarks");
                        } else if (rowData.QualityRemarks === "declined") {
                            handleOpenDialog(rowData);
                        } else {
                            if (rowData.QualityRemarks !== undefined) {
                                toast.error("Only enrollment with call quality marked as declined will be rejected");
                            } else {
                                toast.error("Please Add Remarks");
                            }
                        }
                    }}
                    className=" p-button-danger pt-1 pb-1 mr-2 ml-2"
                    text
                    raised
                    disabled={isButtonLoading}
                />
            </div>
        );
    };

    const actionTemplateForPR = (rowData) => {
        return (
            <div>
                <Button label="Reject" onClick={() => handleOpenDialog(rowData)} className=" p-button-danger pt-1 pb-1 mr-2 ml-2" text raised disabled={isButtonLoading} />
                <Button label="Run NV" onClick={() => runNV(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                {selectedRow === rowData && link ? (
                    <Button
                        label="Go To Link"
                        onClick={() => {
                            window.open(link, "_blank");
                        }}
                        className=" mr-2 ml-2 pt-1 pb-1 p-button-warning"
                        text
                        raised
                        disabled={isButtonLoading}
                    />
                ) : null}
                <Button label="Enroll User" onClick={() => enrollUser(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                <Button label="Activate Sim" onClick={() => handleDialogeForActivate(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                {/* <Button label="Update User With NLAD" onClick={() => updateUser(rowData)} className=" mr-2 ml-2" text raised disabled={isButtonLoading} /> */}
                <Button label="Transfer User" onClick={() => transferUser(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
            </div>
        );
    };
    const handleOpenDialog = (rowData) => {
        setisButtonLoading(true);
        setIsModalOpen(true);
        setIsEnrolmentId(rowData?._id);
        setCsrId(rowData?.csr);
        setCheckType(rowData?.enrollment);
        setisButtonLoading(false);
    };

    const handleDialogeForActivate = (rowData) => {
        setOpenDialogeForActivate(true);
        setIsEnrolmentId(rowData?._id);
        setZipCode(rowData?.zip);
    };
    const handleOpenDialogForRemarks = (rowData) => {
        setOpenDialogeForRemarks(true);
        setIsEnrolmentId(rowData?._id);
    };

    const handleOpenDialogForRemarksForIJ = (rowData) => {
        setOpenDialogeForRemarksForIJ(true);
        setIsEnrolmentId(rowData?._id);
    };
    const transferUser = async (rowData) => {
        setDialogeForTransfer(true);
        setIsEnrolmentId(rowData?._id);
    };
    const [selectedNumberToShow, setSelectedNumberToShow] = useState(10);
    return (
        <div className="postpaidall">
            <ToastContainer className="custom-toast-container" />
            {/* <form>
                <Dialog visible={isModalOpen} style={{ width: "50vw" }} draggable={false} onHide={() => setIsModalOpen(false)}>
                    <DialogForReject setIsModalOpen={setIsModalOpen} checkType={checkType} enrollmentId={isEnrolmentId} CSRid={CsrId} getAllEnrollments={getAllEnrollments} />
                </Dialog>
                <Dialog header={"Activate Sim"} visible={openDialogeForActivate} style={{ width: "70vw" }} onHide={() => setOpenDialogeForActivate(false)}>
                    <DialogForActivateSim enrollmentId={isEnrolmentId} setOpenDialogeForActivate={setOpenDialogeForActivate} zipCode={zipCode} />
                </Dialog>
                <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarks} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarks(false)}>
                    <DialogeForRemarks getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} />
                </Dialog>
                <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarksForIJ} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarksForIJ(false)}>
                    <DialogeForRemarksForIJ getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} setOpenDialogeForRemarksForIJ={setOpenDialogeForRemarksForIJ} />
                </Dialog>
                <Dialog header={"Transfer User"} visible={dialogeForTransfer} style={{ width: "30vw" }} onHide={() => setDialogeForTransfer(false)}>
                    <DialogeForTransferUser enrollmentId={isEnrolmentId} setDialogeForTransfer={setDialogeForTransfer} />
                </Dialog>
            </form> */}
            <div className="flex w-full flex-column justify-content-start h-4" style={{ marginBottom: "1.5rem" }}>
                <h3 className="heading">Enrollments Queue</h3>
                <div style={{ width: "685px" }} className="flex flex-wrap justify-content-start mt-2 w-full">
                    <div className="flex flex-wrap w-full justify-content-between">
                        <div className="flex">
                            <InputText
                                className="searchbar"
                                value={customerSearchName}
                                onChange={(e) => {
                                    setCustomerSearchName(e.target.value);
                                    setCurrentPage(1);

                                    if (e.target.value.length > 0) {
                                        let searchResult = completeListEnrollment.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

                                        setAllEnrollments(searchResult);
                                        setTotalIncaseSearch(searchResult.length);
                                        setTotalSearchResult(searchResult);
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(searchResult.length > 10 ? 10 : searchResult.length);
                                    } else {
                                        setTotalSearchResult([]);
                                        setAllEnrollments(completeListEnrollment.slice(0, 10));
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(completeListEnrollment.length > 10 ? 10 : completeListEnrollment.length);
                                    }
                                }}
                                placeholder="Search Enrollment"
                            />
                        </div>
                        <div className="flex">
                            <button
                                onMouseEnter={() => {
                                    setHover(true);
                                    setHoverLeave(false);
                                }}
                                onMouseLeave={() => {
                                    setHover(false);
                                    setHoverLeave(true);
                                }}
                                className="importbtn"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <div className={hover ? "importexternal" : hoverLeave ? "importexternalleave" : ""}></div>
                                <span className="flex w-full justify-content-center align-items-center">
                                    {hover ? (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(160deg)" }}>
                                            <ImportHover />
                                        </span>
                                    ) : (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(0deg)" }}>
                                            <Import />
                                        </span>
                                    )}
                                    &nbsp; Import
                                </span>
                            </button>
                            <button
                                onMouseEnter={() => {
                                    setHoverAfter(true);
                                    setHoverLeaveAfter(false);
                                }}
                                onMouseLeave={() => {
                                    setHoverAfter(false);
                                    setHoverLeaveAfter(true);
                                }}
                                className="exportbtn cursor-pointer"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <div className={hoverAfter ? "exportexternal" : hoverLeaveAfter ? "exportexternalleave" : ""}></div>
                                <span className="flex w-full justify-content-center align-items-center">
                                    {hoverAfter ? (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(160deg)" }}>
                                            <ExportHover />
                                        </span>
                                    ) : (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(0deg)" }}>
                                            <Export />
                                        </span>
                                    )}
                                    &nbsp; Export
                                </span>
                            </button>
                            <button className="sortbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                <span>
                                    <Sortby />
                                </span>
                                &nbsp; Sort by
                            </button>
                            <button className="filterbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                <span>
                                    <Filter />
                                </span>
                                &nbsp; Filter
                            </button>
                        </div>
                        <div className="flex">
                            <span style={{ fontSize: "1.4rem" }} className="flex align-items-center ">
                                Result per page{" "}
                                <button className="resultbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                    {selectedNumberToShow}
                                </button>{" "}
                                &nbsp; {enrollmentFrom}-{enrollmentTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </span>
                            &nbsp;
                            <button
                                onClick={() => {
                                    const itemsPerPage = 10;
                                    const calculateTotalPages = (data) => Math.ceil(data.length / itemsPerPage);

                                    const handlePageNavigation = (data, setDataFn) => {
                                        const totalPages = calculateTotalPages(data);
                                        if (currentPage > 1) {
                                            let startIdx, endIdx;

                                            if (totalPages - 1 === currentPage) {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else if (totalPages === currentPage) {
                                                startIdx = data.length - (data.length % itemsPerPage) - itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            }

                                            setDataFn(data.slice(startIdx, endIdx));
                                            setCurrentPage((prev) => prev - 1);
                                            setEnrollmentFrom(startIdx + 1);
                                            setEnrollmentTo(endIdx);
                                        }
                                    };

                                    if (customerSearchName.length > 0) {
                                        handlePageNavigation(totalSearchResult, setAllEnrollments);
                                    } else {
                                        handlePageNavigation(completeListEnrollment, setAllEnrollments);
                                    }
                                }}
                                className="cursor-pointer backbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <Backbtn />
                            </button>
                            <button
                                onClick={() => {
                                    if (customerSearchName.length > 0) {
                                        let totalPages = Math.ceil(totalSearchResult.length / 10);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 10;
                                            if (totalPages - 1 === currentPage) {
                                                setAllEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(totalSearchResult.length);
                                            } else {
                                                setAllEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 10));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 10);
                                            }
                                        }
                                    } else {
                                        let totalPages = Math.ceil(completeListEnrollment.length / 10);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 10;
                                            if (totalPages - 1 === currentPage) {
                                                setAllEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(completeListEnrollment.length);
                                            } else {
                                                setAllEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 10));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 10);
                                            }
                                        }
                                    }
                                }}
                                className="cursor-pointer nextbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <Nextbtn />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    {isButtonLoading ? <ProgressSpinner className="flex flex-wrap justify-content-center flex-row mt-4" /> : null}

                    <DataTable
                        value={allEnrollments}
                        selection={selectedRows}
                        onSelectionChange={(e) => setSelectedRows(e.value)}
                        size="small"
                        stripedRows
                        resizableColumns
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        emptyMessage={
                            isLoading ? (
                                <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">
                                    <CommonLoaderBlue customSize={16} />
                                </div>
                            ) : (
                                "Enrollment Not Found"
                            )
                        }
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                        <Column selectionMode="multiple" style={{ width: "3rem" }} />
                        <Column
                            header="Enrollment ID"
                            field="enrollmentId"
                            body={(rowData) => (
                                <button style={{ fontSize: "1.272rem", fontWeight: "500", color: "#5E79FF", border: "none", backgroundColor: "transparent", cursor: "pointer", marginLeft: "-1rem" }} onClick={() => handleEnrollmentIdClick(rowData)}>
                                    {rowData.enrollmentId}
                                </button>
                            )}
                        ></Column>
                        <Column header="Name" field="name"></Column>
                        <Column field="contact" header="Contact" />
                        <Column field="createdBy.name" header="Created By" />
                        <Column field="createdDate" header="Created At" />
                        <Column
                            header="Linked"
                            body={(rowData) => {
                                return <p className={rowData?.linkedAccount ? "yes" : "no"}>{rowData?.linkedAccount ? "Yes" : "No"}</p>;
                            }}
                        />
                        <Column header="Actions" body={actionTemplate}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
};
export default All_Enrollmentsf;
