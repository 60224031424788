import React from "react";  
import "./TopCards.css"
import { ActiveClients, Revenue, TotalEnrollment, Users } from "./assets";
export default function TopCards(){    
     const data=[{ 
      svg:Users, 
      title:"TOTAL USERS", 
      count:200,  
      increase:true, 
      sinceYester:"5"
     },{ 
        svg:TotalEnrollment, 
        title:"TOTAL ENROLLMENTS", 
        count:31000,  
        normal:true, 
        sinceYester:"150"
     },{ 
        svg:ActiveClients, 
        title:"ACTIVE CLIENTS", 
        count:25000,  
        descrease:true, 
        sinceYester:"5"
     },{ 
        svg:Revenue, 
        title:"TOTAL REVENUE", 
        count:"15M", 
        sinceLastMonth:"3%"
     }]
    return( 
        <div className="top-card-main-container flex flex-wrap flex-row justify-content-between w-full"> 
           {  data.map((item,index)=> {  
            let Svg=item.svg
            return ( 
            <div className="top-card-main-wrapper  "> 
             <div  className="w-full flex flex-wrap top-content  flex-row  align-items-center justify-content-start"> 
                 <Svg/><p className="title-main">{item.title}</p>

             </div>   
             <div className="bottom-content w-full flex flex-wrap flex-row  justify-content-start">  
                <h1>5000</h1> 
                <p>{item?.sinceYester ? <p><span className={`${item?.descrease ? "decrease": item?.increase ? "increase":"normal"}`}>{item?.sinceYester}</span>since yesterday</p>:<p><span className="normal">{item?.sinceLastMonth}</span>Since last month</p>}</p>
                </div>
           </div>    )
           })
}
        </div>
    )
}