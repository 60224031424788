import React from 'react'
import BillingNavbar from '../billing_and_invoices/components/BillingNavbar'
const CustomerHistory = () => {
  return (
    <>
    <BillingNavbar/>
    </>
  )
}

export default CustomerHistory