import React, { useState, useEffect } from "react";
import { Calendar as PrimeCalendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../Calendar/calendar.css";

const Calendardropdown = () => {
    const [fromInput, setFromInput] = useState("");
    const [toInput, setToInput] = useState("");
    const [selectedRange, setSelectedRange] = useState({ from: null, to: null });

    const formatDate = (date) => {
        if (!date || !(date instanceof Date) || isNaN(date)) return "";
        return `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`;
    };

    const parseDate = (input) => {
        const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        if (!regex.test(input)) return null;
        const [day, month, year] = input.split("/").map((num) => parseInt(num, 10));
        const date = new Date(year, month - 1, day);
        return date instanceof Date && !isNaN(date) ? date : null;
    };

    const handleInputChange = (type, value) => {
        if (type === "from") {
            setFromInput(value);
            const date = parseDate(value);
            if (date) {
                setSelectedRange((prev) => ({ ...prev, from: date }));
            }
        } else if (type === "to") {
            setToInput(value);
            const date = parseDate(value);
            if (date) {
                setSelectedRange((prev) => ({ ...prev, to: date }));
            }
        }
    };

    const handleDateSelect = (event) => {
        if (!event.value) return;

        const [from, to] = event.value;
        setSelectedRange({
            from: from || null,
            to: to || null,
        });
    };

    useEffect(() => {
        setFromInput(selectedRange.from ? formatDate(selectedRange.from) : "");
        setToInput(selectedRange.to ? formatDate(selectedRange.to) : "");
    }, [selectedRange]);

    const handleCancel = () => {
        setFromInput("");
        setToInput("");
        setSelectedRange({ from: null, to: null });
    };

    const handleApply = () => {
        console.log("Selected range:", {
            from: selectedRange.from ? formatDate(selectedRange.from) : null,
            to: selectedRange.to ? formatDate(selectedRange.to) : null,
        });
    };

    return (
        <div className="calendarrange">
            <div className="flex w-full justify-content-evenly range">
                <div className="flex flex-column">
                    <label htmlFor="from">From</label>
                    <InputText className="input mt-2" id="from" type="text" value={fromInput} onChange={(e) => handleInputChange("from", e.target.value)} placeholder="DD/MM/YYYY" />
                </div>
                <div className="flex flex-column">
                    <label htmlFor="to">To</label>
                    <InputText className="input mt-2" id="to" type="text" value={toInput} onChange={(e) => handleInputChange("to", e.target.value)} placeholder="DD/MM/YYYY" />
                </div>
            </div>

            <PrimeCalendar
                value={[selectedRange.from instanceof Date && !isNaN(selectedRange.from.getTime()) ? selectedRange.from : null, selectedRange.to instanceof Date && !isNaN(selectedRange.to.getTime()) ? selectedRange.to : null].filter(Boolean)} // Filter out invalid values
                onChange={handleDateSelect}
                inline
                selectionMode="range"
                dateFormat="dd/mm/yy"
                className="w-full"
            />
            <hr
                style={{
                    border: "1px solid #1716190A",
                    width: "300px",
                    marginLeft: "-12px",
                }}
            />

            <div className="flex w-full justify-between mt-2 buttons">
                <Button className="cancel" label="Cancel" onClick={handleCancel} />
                <Button className="apply" label="Apply" onClick={handleApply} />
            </div>
        </div>
    );
};

export default Calendardropdown;
