import React, { useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import Preview_Final_component from "./Preview_Final_component";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import "./Preview.css";
import { LeftSideCircleInner, LeftSideCircleOuter, RightSideCircleInner, RightSideCircleOuter, Tag, TagBottom, Tickbtn, Tickbtnpreview } from "../../../../../../../utility";
import { useNavigate } from "react-router-dom";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Preview = ({
    setCurrentScreen,
    initiationData,
    parentDetails,
    isPostpaidIncomplete,
    prospectCases,
    setConfirmationActive,
    currentAllBillingConfig,
    currentAllPlans,
    setPreviewScreenActive,
    setPaymentScreenActive,
    paymentScreenDetails,
    paymentDone,
    setActiveIndex,
    setParentDetails,
    currentPlanSelect,
    inventoryData,
    inventoryIdSelected,
    payingAmount,
}) => {
    const loginRes = JSON.parse(localStorage.getItem("userData"));
    const parseLoginRes = loginRes;
    const _id = initiationData?._id;
    const csr = loginRes?._id;
    const companyName = loginRes?.companyName;
    const inventoryImage = inventoryData.find((item) => item?._id === inventoryIdSelected);
    const [visible, setVisible] = useState(false);
    const [dialog, setDialog] = useState(false);
    const navigate = useNavigate();
    const [confirmation, setConfirmation] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [finalComponent, setFinalComponenet] = useState(false);
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }

    const [checked, setChecked] = useState(false);
    const [fromIncomplete, setFromIncomplete] = useState(false);
    //get preview  information from local storage
    const previewsRes = localStorage.getItem("prepaidaddress");
    const parsepreviewsRes = JSON.parse(previewsRes);
    const previewInfo = parsepreviewsRes?.data;
    const zipRes = localStorage.getItem("prepaidzipData");
    //check that user come from incomplete or not
    const fromIncompl = localStorage.getItem("comingfromincomplete");
    const parsefromIncompl = JSON.parse(fromIncompl);
    const formatDate = (date) => {
        if (!date) return ""; // Handle null or undefined dates
        return new Date(date).toLocaleDateString("en-US");
    };
    const [additionalFeatureInCasePayment, setAdditionalFeatureInCasePayment] = useState("");
    const [discountInCasePayment, setDiscountInCasePayment] = useState("");
    const [totalAmountIncasPaymentincomplete, setTotalAmountIncasepaymentincomplete] = useState("");
    const [amountPaidIncasepayment, setAmountpaidincasepayment] = useState("");
    const selectedPlan = currentAllPlans.find((plan) => plan._id === currentPlanSelect);

    useEffect(() => {
        if (isPostpaidIncomplete && isPostpaidIncomplete?.activeBillingConfiguration) {
            if (Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0) {
                let additionalincasincompletepaymentdone = "";
                if (isPostpaidIncomplete.activeBillingConfiguration?.additionalFeature) {
                    for (let i = 0; i < isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature?.length; i++) {
                        if (i + 1 === isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature?.length) {
                            additionalincasincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature[i]?.name}`;
                        } else {
                            additionalincasincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.additionalFeature[i]?.name},`;
                        }
                    }
                    setAdditionalFeatureInCasePayment(additionalincasincompletepaymentdone);
                }
                let discountincaseincompletepaymentdone = "";
                if (isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount) {
                    for (let i = 0; i < isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount?.length; i++) {
                        if (i + 1 === isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount?.length) {
                            discountincaseincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount[i]?.name}`;
                        } else {
                            discountincaseincompletepaymentdone += `${isPostpaidIncomplete?.activeBillingConfiguration?.selectdiscount[i]?.name},`;
                        }
                    }
                    setDiscountInCasePayment(discountincaseincompletepaymentdone);
                }
                if (isPostpaidIncomplete?.invoice?.length > 0) {
                    Axios.get(`${BASE_URL}/api/web/invoices/getinvoicebyid?invoiceId=${isPostpaidIncomplete?.invoice[0]}`)
                        .then((res) => {
                            setTotalAmountIncasepaymentincomplete(res?.data?.data?.totalAmount);
                            setAmountpaidincasepayment(res?.data?.data?.amountPaid);
                        })
                        .catch((err) => {});
                }
            } else {
            }
        }
    }, []);
    const PostpaidHandover = async () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
        };
        try {
            const response = await Axios.post(`${BASE_URL}/api/user/handOverEnrollment`, dataToSend);
            setIsLoading(false);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
        setDialog(true);
    };
    const postData = async () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
            isWithInvoice: paymentScreenDetails?.prospectwithinvoice,
            isWithoutInvoice: paymentScreenDetails?.prospectwithoutinvoice,
        };
        Axios.post(`${BASE_URL}/api/user/esnAssingment`, dataToSend)
            .then(() => {
                toast.success("Esn Successfully Assigned");
                Axios.post(`${BASE_URL}/api/user/prepaidHandOver`, dataToSend)
                    .then((res) => {
                        Axios.post(`${BASE_URL}/api/web/order`, { orderNumber: initiationData?.enrollmentId })
                            .then((response) => {
                                toast.success("Order Placed Successfully");
                                let generate = localStorage.getItem("generatelabel");
                                generate = generate === "true"; // convering to boolean
                                if (generate) {
                                    Axios.post(`${BASE_URL}/api/web/order/createLable`, { orderId: response.data.data.orderId.toString(), userId: _id, testLabel: true })
                                        .then(() => {
                                            toast.success("Label created Successfully");
                                            setIsLoading(false);

                                            setDialog(true);
                                            setFromIncomplete(false);
                                        })
                                        .catch((err) => {
                                            setIsLoading(false);

                                            setDialog(true);
                                            setFromIncomplete(false);
                                        });
                                } else {
                                    setDialog(true);
                                    setFromIncomplete(false);
                                }
                            })
                            .catch((err) => {
                                toast.error("Order Placing Failed");
                            });
                    })
                    .catch((error) => {
                        toast.error(error?.response?.data?.msg);
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error?.response?.data?.msg);
            });
    };
    useEffect(() => {
        if (!zipRes && parsefromIncompl == false) {
            setIsChecked(true);
        } else if (!zipRes && parsefromIncompl == true) {
            setIsChecked(false);
        }
    }, []);
    let inventory;
    let oneTimeCharge;
    let discount = "";
    let additional = "";
    let discountobjectsendin = [];
    let additionalobjectsendin = [];
    let productName;
    let dueDate;
    let applyLateFee;
    let planname;
    let plancharges;

    for (let i = 0; i < currentAllPlans?.length; i++) {
        if (paymentScreenDetails?.plan === currentAllPlans[i]?._id) {
            planname = currentAllPlans[i]?.name;
            plancharges = currentAllPlans[i]?.price;
            inventory = currentAllPlans[i]?.inventoryType;
            //planId = currentAllPlans[i]?._id;
        }
    }

    productName = "SIM";
    let selectdiscount = paymentScreenDetails?.discount;
    let alldiscounts = currentAllBillingConfig?.selectdiscount;
    applyLateFee = currentAllBillingConfig?.applyLateFee;
    dueDate = currentAllBillingConfig?.dueDate;
    oneTimeCharge = currentAllBillingConfig?.oneTimeCharge;
    let simalladditional = currentAllBillingConfig?.additionalFeature;
    let additionallocal = paymentScreenDetails?.additional;
    for (let i = 0; i < additionallocal?.length; i++) {
        for (let k = 0; k < simalladditional?.length; k++) {
            if (additionallocal[i] === simalladditional[k]._id) {
                let obj = {
                    name: simalladditional[k]?.featureName,
                    amount: simalladditional[k]?.featureAmount,
                };
                additionalobjectsendin.push(obj);
                if (i + 1 === additionallocal?.length) {
                    additional += `${simalladditional[k].featureName}`;
                } else {
                    additional += `${simalladditional[k].featureName},`;
                }
            }
        }
    }

    for (let k = 0; k < selectdiscount?.length; k++) {
        for (let i = 0; i < alldiscounts?.length; i++) {
            if (selectdiscount[k] === alldiscounts[i]._id) {
                let obj = {
                    name: alldiscounts[i]?.discountname,
                    amount: alldiscounts[i]?.amount,
                };
                discountobjectsendin.push(obj);
                if (k + 1 === selectdiscount?.length) {
                    discount += `${alldiscounts[i].discountname}`;
                } else {
                    discount += `${alldiscounts[i].discountname},`;
                }
            }
        }
    }

    const handleSign = () => {
        setChecked(true);
    };
    const postDataWithinvoice = () => {
        setIsLoading(true);
        const dataToSend = {
            csr: csr,
            userId: _id,
            isWithInvoice: prospectCases.prospectWithInvoice,
            isWithoutInvoice: prospectCases.prospectWithoutInvoice,
        };

        const shipmentName = localStorage.getItem("shipmentName");
        const shipmentCost = localStorage.getItem("shipmentCost");
        Axios.post(`${BASE_URL}/api/user/prepaidHandOver`, dataToSend)
            .then(() => {
                let dataToSend = {
                    customerId: paymentScreenDetails.customerid,
                    invoiceType: "Sign Up",
                    totalAmount: paymentScreenDetails.totalamount,
                    additionalCharges: additionalobjectsendin,
                    discount: discountobjectsendin,
                    amountPaid: 0,
                    selectProduct: paymentScreenDetails.billId,
                    invoiceDueDate: dueDate,
                    lateFee: applyLateFee,
                    invoiceOneTimeCharges: oneTimeCharge,

                    invoicePaymentMethod: "Skip Payment",
                    invoiceStatus: "Unpaid",
                    planId: paymentScreenDetails?.plan,
                    planName: planname,
                    planCharges: plancharges,
                    chargingType: "monthly",
                    invoicePaymentMethod: "",
                    printSetting: "Both",
                    isWithInvoice: paymentScreenDetails?.prospectwithinvoice,
                    isWithoutInvoice: paymentScreenDetails?.prospectwithoutinvoice,
                    isInvoice: paymentScreenDetails.prospectwithinvoice,
                    billingPeriod: {
                        from: "onActivation",
                        to: "onActivation",
                    },
                    isShipmentCostDiscounted: checkLabel,
                    shipmentCost: {
                        name: shipmentName,
                        amount: shipmentCost,
                    },
                    userId: parseLoginRes?._id,
                };

                Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend).then(() => {
                    // toast.success("Label created Successfully");

                    setIsLoading(false);
                    setDialog(true);
                    setFromIncomplete(false);
                });
            })
            .catch((error) => {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            });
    };
    function ChangeIsoDateToECT(date) {
        // Given ISO formatted date/time
        const isoDate = date;

        // Convert ISO string to Date object
        const utcDate = new Date(isoDate);

        // Format the date according to Eastern Time Zone (EST/EDT)
        const estTimeString = utcDate.toLocaleString("en-US", {
            timeZone: "America/New_York",
        });
        return estTimeString;
    }
    const movepageToAll = () => {
        if (parentDetails?.accountType === "Prepaid") {
            navigate("/postpaid-allenrollment");
        }
    };

    const handleNavigate = () => {
        navigate("/customer-profile", { state: { selectedId: initiationData?._id } });
        localStorage.setItem("selectedId", JSON.stringify(initiationData?._id));
    };
    console.log("payment screen details", paymentScreenDetails);
    console.log("cpdata", parentDetails);
    return (
        <div className="preview-screen">
            <ToastContainer />
            <div className="initiationMain ">
                <div className="flex flex-wrap w-full justify-content-center  ">
                    <h1 className="enrollmentheading">ENROLLMENT ID : {parentDetails?.enrollmentId}</h1>
                </div>
                <div className="previewbg" style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* Left Side */}
                    <div style={{ position: "relative", marginLeft: ".0rem" }}>
                        <div className="outer">
                            <LeftSideCircleOuter style={{ position: "absolute", top: "0", left: "0" }} />
                        </div>
                        <div className="inner">
                            <LeftSideCircleInner style={{ position: "absolute", top: "0", left: "0" }} />
                        </div>
                    </div>

                    {/* Right Side */}
                    <div style={{ position: "relative", marginRight: ".0rem" }}>
                        <div className="outer">
                            <RightSideCircleOuter style={{ position: "absolute", top: "0", right: "0" }} />
                        </div>
                        <div className="inner">
                            <RightSideCircleInner style={{ position: "absolute", top: "0", right: "0" }} />
                        </div>
                    </div>
                </div>
                <div className="initiationData">
                    <div className="personalInformation">
                        <h1>Personal Information</h1>
                        <div className="flex  justify-content-between align-items-center" style={{ paddingRight: "4.0rem" }}>
                            <div className="flex flex-column">
                                <label>First Name</label>
                                <p>{parentDetails?.firstName || "-"}</p>
                            </div>
                            <div className="flex flex-column">
                                <label>Last Name</label>
                                <p>{parentDetails?.lastName || "-"}</p>
                            </div>

                            <div className="flex flex-column">
                                <label>Email</label>
                                <p>{parentDetails?.email || "-"}</p>
                            </div>

                            <div className="flex flex-column">
                                <label>Contact</label>
                                <p>{parentDetails?.contact || "-"}</p>
                            </div>
                            <div className="flex flex-column">
                                <label>Date of Birth</label>
                                <p>{parentDetails?.DOB ? new Date(parentDetails.DOB).toISOString().split("T")[0].replace(/-/g, "/") : "-"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="deliveryInfo">
                        <h1>Delivery Address</h1>
                        <div className="flex w-full justify-content-between" style={{ paddingRight: "4.0rem" }}>
                            <div className="flex flex-column">
                                <label>Street Address</label>
                                <p>{parentDetails?.address1 || "-"}</p>
                            </div>
                            <div className="flex flex-column">
                                <label>City</label>
                                <p>{parentDetails?.city || "-"}</p>
                            </div>

                            <div className="flex flex-column">
                                <label>State</label>
                                <p>{parentDetails?.state || "-"}</p>
                            </div>

                            <div className="flex flex-column">
                                <label>Zip Code</label>
                                <p>{parentDetails?.zip || "-"}</p>
                            </div>
                            <div className="flex flex-column">
                                <label>Country</label>
                                <p>{parentDetails?.country || "-"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="paymentInfo">
                        <div className="flex flex-wrap flex-row justify-content-between">
                            <div style={{ width: "45%" }}>
                                <h1>Payment Details</h1>
                                <div className="flex w-full justify-content-left" style={{ paddingRight: "7.0rem", gap: "8.0rem" }}>
                                    <div className="flex flex-column">
                                        <label>Due Amount</label>
                                        <p>{paymentScreenDetails?.totalamount - paymentScreenDetails?.paid}</p>
                                    </div>
                                    <div className="flex flex-column">
                                        <label>Amount Paid</label>
                                        <p>
                                            {isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0
                                                ? amountPaidIncasepayment
                                                : paymentScreenDetails?.paid
                                                  ? `$${paymentScreenDetails.paid}`
                                                  : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <span className="line"></span>
                            <div style={{ width: "55%" }}>
                                <h1>Other Info</h1>
                                <div className="flex w-full justify-content-between" style={{ paddingRight: "3.0rem" }}>
                                    <div className="flex flex-column">
                                        <label>SSN</label>
                                        <p>{parentDetails?.SSN || "-"}</p>
                                    </div>
                                    <div className="flex flex-column">
                                        <label>Linked</label>
                                        <p>{parentDetails?.linkedAccount ? "Yes" : "No"}</p>
                                    </div>
                                    <div className="flex flex-column">
                                        <label>Created By</label>
                                        <p>{parentDetails?.createdBy?.name || "-"}</p>
                                    </div>

                                    <div className="flex flex-column">
                                        <label>Created At</label>
                                        <p>{parentDetails?.createdAt || "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full flex-row justify-content-between lowerdiv ">
                        <div className="discounts">
                            <div className="discountdata">
                                <h1>Discounts</h1>
                                <div className="flex w-full justify-content-evenly">
                                    <p> {isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0 ? discountInCasePayment : discount?.toUpperCase() || "-"}</p>
                                </div>
                            </div>
                            <br />
                            <div className="additionalfeatures" style={{ marginTop: "1rem" }}>
                                <h1 className="flex w-full">Additional Feature</h1>
                                <div className="flex w-full justify-content-evenly">
                                    <p>
                                        {isPostpaidIncomplete && Object.keys(isPostpaidIncomplete)?.length > 0 && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0
                                            ? additionalFeatureInCasePayment
                                            : additional.toUpperCase() || "-"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="plan">
                            <div className="planinner">
                                <div className="flex flex-wrap w-full flex-column allownces justify-content-center align-items-center text-center">
                                    <div className="flex w-full flex-column justify-content-start tag " style={{ width: "13.0rem", height: "auto" }}>
                                        <div style={{ width: "8.823rem", marginLeft: "-.5rem" }}>
                                            <Tag text={selectedPlan?.name} />
                                        </div>
                                        <div className="tagbottom" style={{ width: "14%", marginLeft: "-3.5%", marginTop: "-1.0rem" }}>
                                            <TagBottom />
                                        </div>
                                    </div>
                                    <h1 style={{ fontSize: "2.174rem", fontWeight: "600", marginTop: "0rem" }}>$ {selectedPlan?.price}</h1>
                                    <p style={{ fontSize: "1.087rem", fontWeight: "600", marginTop: "-1.5rem" }}>No Hidden Fees / No Contracts</p>
                                    <div className="flex justify-conent-start flex-column" style={{ gap: "1rem" }}>
                                        <span className="flex flex-wrap flex-row " style={{ alignItems: "center" }}>
                                            <Tickbtnpreview />
                                            <span className="ml-1  " style={{ fontSize: "0.8rem", fontWeight: "600", color: "black" }}>
                                                VOICE ALLOWANCE {selectedPlan?.voiceAllowance} <span> {selectedPlan?.voiceAllowanceUnit}</span>
                                            </span>
                                        </span>
                                        <span className="flex flex-wrap flex-row" style={{ alignItems: "center" }}>
                                            <Tickbtnpreview />
                                            <span className="ml-1" style={{ fontSize: "0.8rem", fontWeight: "600", color: "black" }}>
                                                DATA ALLOWANCE {selectedPlan?.dataAllowance} <span> {selectedPlan?.dataAllowanceUnit}</span>
                                            </span>
                                        </span>
                                        <span className="flex flex-wrap flex-row " style={{ alignItems: "center" }}>
                                            <Tickbtnpreview />
                                            <span className="ml-1 " style={{ fontSize: "0.8rem", fontWeight: "600", color: "black" }}>
                                                TEXT ALLOWANCE {selectedPlan?.textAllowance} <span> {selectedPlan?.textAllowanceUnit}</span>
                                            </span>
                                        </span>
                                        {/* <span className="flex flex-wrap w-full justify-content-center">
                                            <button className="flex flex-wrap justify-content-center align-items-center selectedbtn">Selected</button>
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-content-center align-items-center inventory" style={{ marginBottom: ".5rem" }}>
                            <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="inventoryimage" src={`${BASE_URL}/inventory/${inventoryImage?.image}`} alt="inventoryImage" />
                        </div>
                        <div className="confirmcheck flex w-full justify-content-start align-items-center" style={{ marginLeft: "0.3%", marginTop: "-.4rem" }}>
                            <Checkbox style={{ zIndex: "9999" }} onChange={(e) => setIsChecked(e.checked)} checked={isChecked} onClick={handleSign} />
                            <label style={{ zIndex: "9999", marginLeft: ".5rem", marginTop: "-.7rem" }}>
                                I have read
                                <span className="pointer" style={{ color: "#3957ED", cursor: "pointer" }} onClick={() => setVisible(true)}>
                                    &nbsp;Terms & Conditions&nbsp;
                                </span>
                                and agree
                            </label>
                        </div>
                        <div className="confirminner">
                            {isChecked ? (
                                <div className="flex w-full justify-content-start" style={{ marginLeft: "5.2%" }}>
                                    <strong style={{ zIndex: "0" }}>
                                        This form is electronically signed by <strong>{parentDetails?.firstName + " " + parentDetails.lastName}</strong>
                                        <span> </span>
                                        on <span> </span> {ChangeIsoDateToECT(new Date().toISOString())}
                                    </strong>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>

                    <div className=" flex flex-wrap w-full justify-content-end align-items-end" style={{ position: "absolute", marginTop: "60rem", marginRight: "10rem" }}>
                        <Button
                            label="Submit"
                            className="btn confirmsubmit"
                            onClick={
                                paymentDone
                                    ? postData
                                    : isPostpaidIncomplete && isPostpaidIncomplete !== "" && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0
                                      ? postData
                                      : (paymentDone && parentDetails?.accountType === "Postpaid") || (isPostpaidIncomplete && isPostpaidIncomplete !== "" && isPostpaidIncomplete?.activeBillingConfiguration && Object.keys(isPostpaidIncomplete?.activeBillingConfiguration).length > 0)
                                        ? PostpaidHandover
                                        : postDataWithinvoice
                            }
                            disabled={!isChecked}
                            icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                            style={{ zIndex: "9999" }}
                        />
                    </div>
                </div>
                <Dialog
                    className="confirmationdialog"
                    headerClassName="confirmationheader"
                    closable={false}
                    visible={visible}
                    style={{
                        width: "85.8rem",
                        borderRadius: "2.5rem",
                        overflow: "hidden",
                        boxShadow: `.0rem .4rem .4rem .0rem #8497FF40, 
                    1.0rem 1.0rem 2.0rem .0rem #8497FF1A inset, 
                    -1.0rem -1.0rem 2.0rem .0rem #8497FF0D inset, 
                    -.5rem -.5rem 1.0rem .0rem #8497FF1A inset`,
                    }}
                >
                    <div className="flex flex-wrap flex-column justify-content-start" style={{ padding: "4.0rem 3.0rem" }}>
                        <h5>Agreement</h5>
                        <h3 className="mt-0">Term of services</h3>
                        <label>{companyName}, Inc agrees to provide the selected equipment and services to the customer at the rates discussed with customer</label>
                        <br />
                        <label>The services provided is pre-paid and the customer agress to pay the monthly bill at the start of each services month.</label>
                        <br />
                        <label>The customer agrees to switch their home phone or cell phone services to {companyName},Inc from their current service provider. </label>
                        <br />
                        <label>{companyName}, inc will make responsible efforts to ensure that the customer retains their existing phone number. In cases where number portability is not possible, the customer will be notified.</label>
                        <br />
                        <label>If the customer choose not to utilize the selected services and equipment , without encountering any technical issues, the customer agrees to pay the specified monthly bill, including taxes.</label>
                        <br />
                        <label>The customer can choose any desired date for the monthly bill within the days provided by {companyName}, Inc for the due date. In the event that the customer is unable to make the monthly payment on time, late fees, if applicable, will be applied to the bill.</label>
                        <br />
                        <label>{companyName}, Inc will proivide customer support to the customer for any service related quries or technical issues</label>
                        <br />
                        <label>By enrolling with {companyName}, Inc. the customer consents to receive communications, including bills, notices and updates, electronically.</label>
                        <br />
                        <label>{companyName}, Inc reserve the rights to terminate the services in the event of non-payment or violation of the terms and conditions.</label>
                        <br />
                        <label>{companyName}, Inc will take reasonable measures to project customer information and maintain data security</label>
                        <br />
                        <label>This ageerement shall be governed by and construed by in accordance with the law of the applicable jurisdiction</label>
                        <br />
                        <label>
                            I further consent to receive calls, emails and/or text messages that may deliver auto-dialed or pre-recorder messages from {companyName}, Inc or its duly appointed agent, either using my telephone number assigned by {companyName}, Inc or provided by me here in or later. i
                            understand this is not a condition of purchase
                        </label>
                        <br />
                        <label>
                            I hereby give my informed consent to electronically sign this form and i acknowledge that this electronic signature has the same legal effect as handwritten Signature. I understand that this action My Agreements to the Term and Conditions outlined in this form and my
                            related documents
                        </label>
                        <br />
                    </div>
                    <div className="flex w-full justify-content-center">
                        <button className="confirmationdialogbtn " onClick={() => setVisible(false)}>
                            Decline
                        </button>
                        <button
                            className="confirmationdialogbtn  ml-2"
                            onClick={() => {
                                setIsChecked(true);
                                setVisible(false);
                            }}
                        >
                            Accept
                        </button>
                    </div>
                </Dialog>
            </div>
            <Dialog visible={dialog} style={{ width: "50%", height: "auto", color: "red", borderRadius: "1.0rem" }} header={null} closable={false} className="custom-dialog">
                <h3 className="flex w-full justify-content-center" style={{ fontSize: "1.8rem", fontFamily: "Inter", fontWeight: "600", marginBottom: "-2rem" }}>
                    Enrollment is successfully saved against Enrollment ID:
                    <span onClick={handleNavigate} style={{ cursor: "pointer", color: "#0475B4", marginLeft: "1.0rem" }} className="steric">
                        {initiationData?.enrollmentId}
                    </span>
                </h3>
                <div className="flex w-full justify-content-center">
                    <Button style={{ alignItems: "center", marginBottom: "2rem" }} label="OK" onClick={movepageToAll} className="flex  justify-content-center btn" />
                </div>
            </Dialog>
            {/* <Preview_Final_component parentDetails={parentDetails} initiationData={initiationData} enrollment_id={initiationData?.enrollmentId} /> */}
        </div>
    );
};

export default Preview;
