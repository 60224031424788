import React, { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Axios from "axios";
import { Toast } from "primereact/toast";
import CommonLoader from "./loader/Loader";
import CommonLoaderBlue from "./loader/LoaderBlue";
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CheckoutForm = ({parentDetails,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails}) => {  
          let toast=useRef()   
          let checkLabel;
          try {
              const storedItem = localStorage.getItem("checkLabel");
              checkLabel = storedItem ? JSON.parse(storedItem) : null;
          } catch (error) {
              console.error("Error parsing checkLabel from localStorage", error);
              checkLabel = null;
          }  
          const [alternateCardToken, setAlternateCardToken] = useState("");
          const [alternateCardDetailVisibility, setAlternateCardDetailVisibility] = useState(false);
          const [autoPay, setAutoPay] = useState(true);
    const parseLoginRes=JSON.parse(localStorage.getItem("userData"))
  const [clientSecret, setClientSecret] = useState("");
  const [elements, setElements] = useState(null);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch the client secret as soon as the page loads
    initialize();
    //heckStatus();
  }, []);
  const formStyle = {
    fontFamily: '"Roboto", sans-serif', // Example font-family
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',    
   fontWeight:"500"
  };

  const buttonStyle = {
    fontFamily: '"Roboto", sans-serif',
    backgroundColor: '#5469d4',
    color: '#ffffff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px', 
    marginTop:"10px"
  };
  const initialize = async () => {
   
    let response=await Axios.post(`${BASE_URL}/api/web/billing/paymentintent`,{amount:paid})
    const clientSecret  = await response.data.clientSecret;
    setClientSecret(clientSecret);

    const appearance = {
      theme: "stripe",
      variables: { 
        fontWeightMedium:"600", 
        fontWeightNormal:"600", 
        
      } 
      ,rules: {
        '.Input:focus': {
         borderColor:"#e6e6e6",
              border:"1px solid #e6e6e6 " ,
              boxShadow:"none !important"
        }, 
    }
    };
    const stripe = await stripePromise;
    const elementsInstance = stripe.elements({ appearance, clientSecret });
    setElements(elementsInstance);

    const paymentElementOptions = {
      layout: "tabs",
    };

    const paymentElement = elementsInstance.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const stripe = await stripePromise;
    const { paymentIntent,error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
   
      },
      redirect: "if_required", 
    }); 
    if (error) {
        Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
            customerId: initiationData?._id,
            paymentMethod: "Credit Card",
            stripeId: paymentIntent?.id,  
            invoiceType:"Sign Up",
            action: "failure", 
            invoiceNo:[],
            reason: "failure",  
            cardNumber:error?.payment_method?.card?.last4,
            transactionStatus: error?.message,
            paymentStatus: "failure",
            amount: paid,  
            userId: parseLoginRes?._id,

        })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
            });
         toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message:"An error occurred while processing the payment"}</p> });

        setTimeout(() => {
            setPaymentDialogVisibility(false);
        }, 2000);
    } else {   
        toast.current.show({ severity: "success", summary: "Payment Processed", detail: <p>{paymentIntent?.status}</p> });
                        
        
        if (alternateCardToken === "") { 
             
         {/* stripe
                .createToken(elements.getElement(CardElement))
                .then(function (result) {
                    const token = result?.token?.id;
                    const datatosendincreateAccount = {
                        name: `${parentDetails?.firstName} ${parentDetails?.lastName}`,
                        email: `${parentDetails?.email}`,
                        phone: `+1${parentDetails?.contact}`,
                        description: "New customer account",
                    };
                    Axios.post(`${BASE_URL}/api/bannerRouter/create_account`, datatosendincreateAccount)
                        .then((customercreation) => {
                           */}
                            let customerId = ""//customercreation?.data?.data?.id;
                            let token=""
                            setPaymentDone(true);
                            //setActiveIndex(3);
                            let additionalFeature = [];
                            let discounts = [];
                            let dueDate = "";
                            let applyLateFee = "";
                            let oneTimeCharge = "";
                            let planName = "";
                            let planId = "";
                            let planCharges = "";
                            dueDate = currentBillingConfig?.dueDate;
                            oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                            applyLateFee = currentBillingConfig?.applyLateFee;
                            let selectedDiscounts = paymentScreenDetails.discount;

                            for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                                for (let k = 0; k < selectedDiscounts.length; k++) {
                                    if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                        let obj = {
                                            name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                            amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                        };
                                        discounts.push(obj);
                                    }
                                }
                            }
                            for (let i = 0; i < allplans?.length; i++) {
                                if (object.plan === allplans[i]?._id) {
                                    planName = allplans[i]?.name;
                                    planCharges = allplans[i]?.price;

                                    planId = allplans[i]?._id;
                                }
                            }

                            let additionalFeatures = paymentScreenDetails?.additional;
                            for (let k = 0; k < additionalFeatures?.length; k++) {
                                for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                    if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                        let obj = {
                                            name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                            amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                        };
                                        additionalFeature.push(obj);
                                    }
                                }
                            }

                            let plan = object?.plan;
                            // let dateincasepart
                            //object.totalAmount === paid ? dueDate:"Partial"
                            const loginRes = localStorage.getItem("userData");
                            const parseLoginRes = JSON.parse(loginRes);
                            const shipmentName = localStorage.getItem("shipmentName");
                            const shipmentCost = localStorage.getItem("shipmentCost");
                            let dataToSend = {
                                paymentId: paymentIntent.id,
                                isAutopay: autoPay,
                                customerId: object.customerid,
                                invoiceType: "Sign Up",
                                totalAmount: object.totalamount,
                                additionalCharges: additionalFeature,
                                discount: discounts,
                                amountPaid: paid,
                                selectProduct: object?.billId,
                                invoiceDueDate: dueDate,
                                isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                                lateFee: applyLateFee,
                                invoiceOneTimeCharges: oneTimeCharge,
                                invoiceStatus:  parseFloat(object.totalamount) <= parseFloat(paid) ? "Paid" : "Partial",
                                planId: plan,
                                planName: planName,
                                planCharges: planCharges,
                                chargingType: "monthly",
                                invoicePaymentMethod: object?.paymentMode,
                                printSetting: "Both",  
                                isWithInvoice:true, 
                                 
                                isInvoice: true,
                                userId: parseLoginRes?._id,
                                billingPeriod: {
                                    from: "onActivation",
                                    to: "onActivation",
                                },
                                isShipmentCostDiscounted: checkLabel,
                                shipmentCost: {
                                    name: shipmentName,
                                    amount: shipmentCost,
                                },
                            };
                            if (autoPay) {
                                dataToSend.paymentMethodId = token;
                                dataToSend.stripeCustomerId = customerId;
                            }
                            setDataSendForInvoice(dataToSend);
                            Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                                .then((response) => {  
                                    Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                                        customerId: initiationData?._id,
                                        paymentMethod: "Credit Card",
                                        stripeId: paymentIntent?.id,
                                        action: "success",
                                        reason: "success",
                                        transactionStatus: paymentIntent?.status,
                                        paymentStatus: "success",  
                                        "invoiceNo":response?.data?.data?.invoice,
                                        amount: paid,    
                                        userId: parseLoginRes?._id,
                                    })
                                        .then((res) => {
                                            toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });   
                                            setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                            setPaymentScreenActive(false);
                                            setPreviewScreenActive(true); 
                                            },500)
                                        })
                                        .catch((err) => {
                                            toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" }); 
                                            setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                                setPaymentScreenActive(false);
                                                setPreviewScreenActive(true); 
                                                },500)
                                        });
                                      

                                    
                                })
                                .catch((err) => {
                                });
                       // })
                        //.catch((err) => {
                        //});
                //})
                //.catch((err) => {});  
        } else {
            const datatosendincreateAccount = {
                name: `${parentDetails?.firstName} ${parentDetails?.lastName}`,
                email: `${parentDetails?.email}`,
                phone: `+1${parentDetails?.contact}`,
                description: "New customer account",
            };
            Axios.post(`${BASE_URL}/api/bannerRouter/create_account`, datatosendincreateAccount)
                .then((customercreation) => {
                    let customerId = customercreation?.data?.data?.id;
                    setPaymentDone(true);
                    //setActiveIndex(3);

                    let additionalFeature = [];
                    let discounts = [];
                    let dueDate = "";
                    let applyLateFee = "";
                    let oneTimeCharge = "";
                    let planName = "";
                    let planId = "";
                    let planCharges = "";
                    dueDate = currentBillingConfig?.dueDate;
                    oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                    applyLateFee = currentBillingConfig?.applyLateFee;
                    let selectedDiscounts = paymentScreenDetails.discount;

                    for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                        for (let k = 0; k < selectedDiscounts.length; k++) {
                            if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                    amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    for (let i = 0; i < allplans?.length; i++) {
                        if (object.plan === allplans[i]?._id) {
                            planName = allplans[i]?.name;
                            planCharges = allplans[i]?.price;

                            planId = allplans[i]?._id;
                        }
                    }

                    let additionalFeatures = paymentScreenDetails?.additional;
                    for (let k = 0; k < additionalFeatures?.length; k++) {
                        for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                            if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                let obj = {
                                    name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                    amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }

                    let plan = object?.plan;
                    // let dateincasepart
                    //object.totalAmount === paid ? dueDate:"Partial"

                    const loginRes = localStorage.getItem("userData");
                    const parseLoginRes = JSON.parse(loginRes);
                    const shipmentName = localStorage.getItem("shipmentName");
                    const shipmentCost = localStorage.getItem("shipmentCost");
                    let dataToSend = {
                        paymentId: paymentIntent.id,
                        isAutopay: autoPay,

                        customerId: object.customerid,
                        invoiceType: "Sign Up",
                        totalAmount: object.totalamount,
                        additionalCharges: additionalFeature,
                        discount: discounts,
                        amountPaid: paid,
                        selectProduct: object?.billId,  
                        isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                        invoiceDueDate: dueDate,
                        lateFee: applyLateFee,
                        invoiceOneTimeCharges: oneTimeCharge,
                        invoiceStatus: parseFloat(object.totalamount) <= parseFloat(paid) ? "Paid" : "Partial",
                        planId: plan,
                        planName: planName,    
                        isWithInvoice:true, 
                        planCharges: planCharges,
                        chargingType: "monthly",
                        invoicePaymentMethod:object?.paymentMode,
                        printSetting: "Both",
                        isInvoice: true,

                        billingPeriod: {
                            from: "onActivation",
                            to: "onActivation",
                        },
                        isShipmentCostDiscounted: checkLabel,
                        shipmentCost: {
                            name: shipmentName,
                            amount: shipmentCost,
                        },

                        userId: parseLoginRes?._id,
                    };
                    if (autoPay) {
                        dataToSend.paymentMethodId = alternateCardToken;
                        dataToSend.stripeCustomerId = customerId;
                    }
                    setDataSendForInvoice(dataToSend);
                    Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                        .then((response) => {
                          
                            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                                customerId: initiationData?._id,
                                paymentMethod: "Credit Card",
                                stripeId: paymentIntent?.id,
                                action: "success",
                                reason: "success",
                                transactionStatus: paymentIntent?.status,
                                paymentStatus: "success",  
                                "invoiceNo":response?.data?.data?.invoice,
                                amount: paid, 
                                userId:parseLoginRes?._id
                            })
                                .then((res) => {
                                    toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });   
                                    setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                    setPaymentScreenActive(false);
                                    setPreviewScreenActive(true); 
                                    },500)
                                })
                                .catch((err) => {
                                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" }); 
                                    setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                        setPaymentScreenActive(false);
                                        setPreviewScreenActive(true); 
                                        },500)
                                });
                           
                        })
                        .catch((err) => {});

                })
                .catch((err) => {});
        }
    }
  {/*
    if (error.type === "card_error" || error.type === "validation_error") {
      showMessage(error.message);
    } else {
      showMessage("An unexpected error occurred.");
    } 

    setIsLoading(false);*/} 
  };

  const checkStatus = async () => {
    const clientSecretFromURL = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecretFromURL) {
      return;
    }
 
    const stripe = await stripePromise;
    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecretFromURL);

    switch (paymentIntent.status) {
      case "succeeded":
        showMessage("Payment succeeded!");
        break;
      case "processing":
        showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        showMessage("Your payment was not successful, please try again.");
        break;
      default:
        showMessage("Something went wrong.");
        break;
    }
  };

  const showMessage = (messageText) => {
    setMessage(messageText);
    setTimeout(() => setMessage(""), 4000);
  };

  const setLoading = (isLoading) => {
    setIsLoading(isLoading);
  };

  return ( 
    <div> 
        <Toast ref={toast}/>  
         { 
            !clientSecret ? <div className="flex flex-wrap flex-row justify-content-center align-items-center mt-4 mb-4">  
                <CommonLoaderBlue/> 
                 </div>:undefined
         }
    <form id="payment-form" onSubmit={handleSubmit} style={formStyle}>
      <div id="payment-element"></div>
      <button id="submit" style={buttonStyle} disabled={!clientSecret || isLoading}>
        <span id="button-text">{isLoading ? <div className="w-full flex flex-wrap flex-row justify-center align-items-center"><CommonLoader/></div> : "Pay now"}</span>
        <div id="spinner" className={isLoading ? "" : "hidden"}></div>
      </button>
      {message && <div id="payment-message">{message}</div>}   
      
    </form> 
     </div>
  );
};

const Payment = ({  parentDetails,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,}) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm    setAllPaymentInfo={setAllPaymentInfo}
                            parentDetails={parentDetails}
                            setPreviewScreenActive={setPreviewScreenActive}
                            setPaymentScreenActive={setPaymentScreenActive}
                            setDataSendForInvoice={setDataSendForInvoice}
                            paymentScreenDetails={paymentScreenDetails}
                            currentBillingConfig={currentBillingConfig}
                            paid={paid}
                            plan={plan}
                            paymentDone={paymentDone}
                            setPaymentDone={setPaymentDone}
                            setPaymentScreenDetails={setPaymentScreenDetails}
                            setPaymentDialogVisibility={setPaymentDialogVisibility}
                    
                            initiationData={initiationData}
                            object={object} />
    </Elements>
  );
};

export default Payment;
