import React from "react";
import "./User.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
export default function Users() {
    const userTable = [
        {
            Id: "0001",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0002",
            name: "Adeel Abid",
            email: "Adeelabid@gmail.com",
            Role: "Team Lead",
            Enrollments: "4000",
        },
        {
            Id: "0003",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0004",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0005",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0006",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
    ];
    return (
        <div className="main-user-conatainer">
            <div className="dashboard-users-header flex flex-wrap flex-row justify-content-between align-items-center">
                <h1>Users</h1>
                <div className="flex flex-wrap flex-row justify-content-start align-items-center">
                    <button>VIEW ALL</button>
                    <button>ADD NEW</button>
                </div>
            </div>
            <DataTable headerClassName="header-user-table" className="user-table-main" value={userTable} tableStyle={{ minWidth: "50rem" }}>
                <Column headerClassName="header-user-table " className="column header-user-id" field="Id" header="Id"></Column>
                <Column headerClassName="header-user-table" className="column" field="name" header="Name"></Column>
                <Column headerClassName="header-user-table" className="column" field="email" header="Email"></Column>
                <Column headerClassName="header-user-table" className="column" field="Role" header="Role"></Column>
            </DataTable>
        </div>
    );
}
