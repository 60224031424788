import React, { useEffect, useState } from "react";
import "./Initiation.css"
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function InitiateEnrollment({  setParentDetails,setInitiationData,parentDetails,setPersonalInfoActive, setInitiateActive, billingModelSelected, setCurrentBillingModel, setBillingModelSelected }) {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes); 
    const [billingModel, setBillingModelList] = useState([])     
     const [billingModelError,setBillingModelError]=useState(false)
    const [initiationLoading,setInitiationLoading]=useState(false)   
    useEffect(() => {
        Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                let permissions = localStorage.getItem("permissions")
                let parsedLoginPerms = JSON.parse(permissions)
                let permittedroutes = []
                for (let i = 0; i < parsedLoginPerms.length; i++) {
                    if (parsedLoginPerms[i].module === "Prepaid Orders" || parsedLoginPerms[i].module === "Postpaid Orders") {
                        permittedroutes.push(parsedLoginPerms[i].module)
                    }
                }
                let billingmodels = res?.data?.data;
                let permittedbillingmodel = []
                for (let i = 0; i < billingmodels.length; i++) {
                    if (billingmodels[i].billingModel === "PREPAID" && permittedroutes.includes("Prepaid Orders")) {
                        permittedbillingmodel.push(billingmodels[i])     

                    }
                    else if (billingmodels[i].billingModel === "POSTPAID" && permittedroutes.includes("Postpaid Orders")) {
                        permittedbillingmodel.push(billingmodels[i])
                    }
                }
                setBillingModelList(permittedbillingmodel);

            })
            .catch((err) => { });
    }, []);
    return (
        <div className="flex flex-wrap initiatemain flex-row justify-content-center">       
          <div>
            <Dropdown options={billingModel} onChange={(e) => {
                setBillingModelSelected(e.value) 
                setBillingModelError(false)
                for (let i = 0; i < billingModel.length; i++) {
                    if (e.value === billingModel[i]._id) {  
                        if(billingModel[i].billingModel === "PREPAID"){  
                        setParentDetails(prev=>({ 
                            ...prev,accountType:"Prepaid"
                        })) 
                        }  
                        else if(billingModel[i].billingModel === "POSTPAID"){  
                            setParentDetails(prev=>({ 
                                ...prev,accountType:"Postpaid"
                            })) 
                            }
                        setCurrentBillingModel(billingModel[i].billingModel)          
                        break;
                    }
                }

            }}

                optionLabel="billingModel"
                optionValue="_id"
                value={billingModelSelected} placeholder="Select Billing Model" />  
                 { 
                    billingModelError ? <p className="p-error mt-2">Billing Model is Required</p>:""
                 }
                 </div>
            <Button 
            style={{height:"40px"}}
                onClick={() => {       
                    if(billingModelSelected !== ""){
                    setInitiationLoading(true)  
                    Axios.post(`${BASE_URL}/api/user/createChildaccount`,{enrollment:parentDetails?.enrollmentId,userId:parseLoginRes?._id,serviceProvider:parseLoginRes?.company})
                    .then((res) => {   
                        setInitiationData(res?.data?.data)
                    setInitiateActive(false)
                    setPersonalInfoActive(true)     
                     }).catch(err=>{ 

                    }) 
                } 
                else{ 
                    setBillingModelError(true)
                }
                }
                }
                icon="pi pi-arrow-right" loading={initiationLoading} disabled={initiationLoading}  iconPos="right" label="Initiate" className="initiationbutton" />
        </div>
    )
}