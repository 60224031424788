import React, { useEffect } from "react";
import "./css/dashboard.css";
import moment from "moment";
import { useState } from "react";
import AreaChart from "./components/Area-Chart";
import Axios from "axios";
import "./components/Area-Chart.css";
import CarrierEarningChart from "./components/Carrirer-Earning-Chart";
import SubscriptonPlanChart from "./components/Subscription-Chart";
import PrepaidNewEnrollmentCard from "./components/SideoneCards/PrepaidNewEnrollmentCard/Prepaid-New-Enrollment-Card";
import PrepaidEnrollmentQueueCard from "./components/SideoneCards/Enrollment-Queue-Card/Prepaid-Enrollment-Queue-Card";
import PrepaidEnrollmentCompletedCard from "./components/SideoneCards/Completed-Enrollment-Card/Prepaid-Enrollment-Completed-Card";
import PrepaidEnrollmentInCompletedCard from "./components/SideoneCards/InCompleted-Enrollment-Card copy/Prepaid-Enrollment-InCompleted-Card";
const Dashboard = ({ permittedRoutes }) => {
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [shortSelection, setShortSelection] = useState();
    const [sideCardsData, setSideCardsData] = useState({
        EnrollmentQueue: {
            count: 0,
            percentageIncrease: "0.00%",
        },
        CompletedEnrollment: {
            count: 0,
            percentageIncrease: "0.00%",
        },
        IncompleteEnrollment: {
            count: 0,
            percentageIncrease: "0.00%",
        },
    });
    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/dashboard/getEnrollmentsSummary?accountType=Prepaid`, {
            params: {
                filter: shortSelection,
                startDate: moment(selectedRange[0])?.isValid() ? moment(selectedRange[0])?.format("MM-DD-YYYY") : null,
                endData: moment(selectedRange[1])?.isValid() ? moment(selectedRange[1])?.format("MM-DD-YYYY") : null,
            },
        })
            .then((res) => {
                setSideCardsData(res?.data?.data);
            })
            .catch((err) => {});
    }, [shortSelection, selectedRange]);
    return (
        <div className="dashboar-main-container">
            <div className="flex dashboard-top-content  flex-row justify-content-between align-items-start">
                <div style={{ flex: "1" }} className="dashboard-area-chart-wrapper">
                    <AreaChart setSelectedRange={setSelectedRange} selectedRange={selectedRange} shortSelection={shortSelection} setShortSelection={setShortSelection} />
                </div>
                <div style={{ width: "auto", aspectRatio: "143/133", marginTop: "-2px" }} className="flex h-full flex-wrap flex-row justify-content-center">
                    <PrepaidNewEnrollmentCard sideCardsData={sideCardsData} shortSelection={shortSelection} selectedRange={selectedRange} />

                    <PrepaidEnrollmentQueueCard sideCardsData={sideCardsData} shortSelection={shortSelection} selectedRange={selectedRange} />
                </div>
            </div>
            <div className="w-full carrier-earning-animate-main flex flex-wrap flex-row justify-content-between align-items-start">
                <div
                    style={{
                        flex: "1",
                    }}
                    className="h-full carrier-earning-chart-main flex flex-wrap flex-row justify-content-between align-items-center"
                >
                    <CarrierEarningChart shortSelection={shortSelection} selectedRange={selectedRange} />
                </div>
                <div style={{ width: "auto", aspectRatio: "143/133", marginTop: "-2px" }} className="flex flex-wrap flex-row justify-content-center h-full ">
                    <PrepaidEnrollmentCompletedCard sideCardsData={sideCardsData} shortSelection={shortSelection} selectedRange={selectedRange} />
                    <PrepaidEnrollmentInCompletedCard sideCardsData={sideCardsData} shortSelection={shortSelection} selectedRange={selectedRange} />
                </div>
            </div>
            <SubscriptonPlanChart shortSelection={shortSelection} selectedRange={selectedRange} />
        </div>
    );
};
export default Dashboard;
