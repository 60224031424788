import React, { useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const shipmentName = localStorage.getItem("shipmentName");
const shipmentCost = localStorage.getItem("shipmentCost");
let checkLabel;
try {
    const storedItem = localStorage.getItem("checked");
    checkLabel = storedItem ? JSON.parse(storedItem) : null;
} catch (error) {
    console.error("Error parsing checkLabel from localStorage", error);
    checkLabel = null;
}
const EcheckAuthPayment = ({
    parentDetails,
    currentBillingModel,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
}) => {
    let amount = parseFloat(object.totalamount).toFixed(2);
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        totalAmount: Yup.string().required("Please Select Amount"),
        routingNumber: Yup.string().required("This is required"),
        AccountNumber: Yup.string().required("This is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            totalAmount: "",
            amount: amount,
            routingNumber: "",
            AccountNumber: "",
            NameOnAccount: "",
            refId: "",
            accountId: initiationData?.accountId,
        },

        onSubmit: async (values, actions) => {
            setIsLoading(true);
            const loginRes = localStorage.getItem("userData");
            const parseLoginRes = JSON.parse(loginRes);
            const dataToSend = {
                amount: formik.values.totalAmount,
                nameOnAccount: formik.values.NameOnAccount,
                routingNumber: formik.values.routingNumber,
                accountNumber: formik.values.AccountNumber,
                accountType: formik.values.accountType,
                accountId: formik.values.accountId,
                customerId: initiationData?._id,
                userId: parseLoginRes?._id,
                invoiceId: "",
            };
            try {
                const response = await Axios.post(`${BASE_URL}/api/web/invoices/paymentEcheck`, dataToSend);
                if (response?.data?.data?.responseCode == "1")  {
                    let additionalFeature = [];
                    let discounts = [];
                    setPaymentDone(true);
                    let dueDate = "";
                    let applyLateFee = "";
                    let oneTimeCharge = "";
                    let planName = "";
                    let planId = "";
                    let planCharges = "";
                    dueDate = currentBillingConfig?.dueDate;  
                    let selectedDiscounts = paymentScreenDetails.discount;

                    oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                    applyLateFee = currentBillingConfig?.applyLateFee;
                    for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                        for (let k = 0; k < selectedDiscounts.length; k++) {
                            if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                    amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    for (let i = 0; i < allplans?.length; i++) {
                        if (object.plan === allplans[i]?._id) {
                            planName = allplans[i]?.name;
                            planCharges = allplans[i]?.price;

                            planId = allplans[i]?._id;
                        }
                    }

                    let additionalFeatures = paymentScreenDetails?.additional;
                    for (let k = 0; k < additionalFeatures?.length; k++) {
                        for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                            if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                let obj = {
                                    name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                    amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }
                    toast.success("Successfully Paid");
                    setPaymentDone(true);
                    if (currentBillingModel === "POSTPAID") {
                        const dataToSend = {
                            invoiceType: "Sign Up",
                            customerId: initiationData?._id,
                            planId: object.plan,
                            paymentId: response?.data?.data?.transId,
                            additionalCharges: object.additional,
                            discount: discounts,
                            totalAmount: amount,
                            amountPaid: formik.values.totalAmount,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            invoiceStatus: parseFloat(object.totalamount) <= parseFloat(formik.values.totalAmount) ? "Paid" : parseFloat(object.totalamount) > parseFloat(formik.values.totalAmount) && parseFloat(formik.values.totalAmount) > 0 ? "Partial" : "Unpaid",
                            paymentMethod:object?.paymentMode,
                            chargingType: "Monthly",
                            printSetting: "Both",
                            selectProduct: object.billId,
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };
                        setDataSendForInvoice(dataToSend);
                        try {
                            const response = await Axios.post(`${BASE_URL}/api/user/postpaidpaymentDetails`, dataToSend);
                            if (response?.status === 201 || response?.status === 200) { 
                                setPaymentScreenDetails(prev => ({ ...prev, paid: formik.values.totalAmount }));
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            }
                        } catch (error) {
                            toast.error(error?.response?.data?.msg);
                            setIsLoading(false);
                        }
                        setIsLoading(false);
                    } else {
                        const loginRes = localStorage.getItem("userData");
                        const parseLoginRes = JSON.parse(loginRes);
                        let dataToSend = {
                            paymentId: response?.data.data?.transId,
                            isAutopay: false,
                            customerId: object.customerid,
                            invoiceType: "Sign Up",
                            totalAmount: object.totalamount,
                            additionalCharges: additionalFeature,
                            discount: discounts,
                            amountPaid: formik.values.totalAmount,
                            selectProduct: object?.billId,
                            invoiceDueDate: dueDate,
                            isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                            lateFee: applyLateFee,
                            invoiceOneTimeCharges: oneTimeCharge,
                            invoiceStatus:  parseFloat(object.totalamount) <= parseFloat(formik.values.totalAmount) ? "Paid" : parseFloat(object.totalamount) > parseFloat(formik.values.totalAmount) && parseFloat(formik.values.totalAmount) > 0 ? "Partial" : "Unpaid",
                            planId: plan,
                            planName: planName,
                            planCharges: planCharges,
                            chargingType: "monthly",
                            invoicePaymentMethod: "Credit/Debit Card",
                            printSetting: "Both",
                            isInvoice: true,
                            userId: parseLoginRes?._id,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };

                        setDataSendForInvoice(dataToSend);
                        Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                            .then((response) => { 
                                setPaymentScreenDetails(prev => ({ ...prev, paid: formik.values.totalAmount }));
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            })
                            .catch((err) => {
                             
                            });
                    }   
                    toast.current.show({ severity: "success", summary: "Payment Processed", detail: response?.data?.data?.messages[0]?.description });
                    setIsLoading(false)   
                } else {
                    if(response?.data?.data?.responseCode === "2"){ 
                    
                        toast.current.show({ severity: "error", summary: "Payment Proceed", detail: response?.data?.data?.errors[0]?.errorText});
                        
                        } 
                }
            } catch (error) {
             
                toast.current.show({ severity: "error", summary: "Payment Proceed", detail: "Payment Proceeding Failed" });
                setIsLoading(false);
            }
            setIsLoading(false);
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-12  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>
                                        <tr className="text-lg">
                                            <td>
                                                Total Amount: <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="amount" value={formik.values.amount} onChange={formik.handleChange} disabled />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Amount <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="totalAmount" value={formik.values.totalAmount} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("totalAmount") }, "input_text")} />
                                                {getFormErrorMessage("totalAmount")}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-lg">
                                                Account Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("AccountNumber") }, "input_text")} type="text" id="AccountNumber" value={formik.values.AccountNumber} maxLength={16} onChange={formik.handleChange} />
                                                {getFormErrorMessage("AccountNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Routing Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("routingNumber") }, "input_text")} type="text" id="routingNumber" maxLength={9} value={formik.values.routingNumber} onChange={formik.handleChange} />
                                                {getFormErrorMessage("routingNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Name On Account <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("NameOnAccount") }, "input_text")} type="text" id="NameOnAccount" value={formik.values.NameOnAccount} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Account Type <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("accountType") }, "input_text")} type="text" id="accountType" value={formik.values.accountType} onChange={formik.handleChange} />
                                            </td>
                                        </tr>
                                        <div className="mt-2">
                                            <Button label="Submit" type="submit" disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};
export default EcheckAuthPayment;