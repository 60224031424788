const inventorytype=[{
    label:"Device", 
    value:"device"
},{ 
    label:"ESN/SIM", 
    value:"esnsim"
}]  
const actions=[{
    label:"Receive Return Agent Box", 
    value:"receivereturnagentbox"
},{ 
    label:"Re-Assign Return Agent Box",

    value:"reassignreturnagentbox"
}]  
export {inventorytype,actions}