import React, { useEffect, useRef, useState } from "react"; 
import Axios from "axios";   
import "./css/Wifi-Calling.css"   
import { useFormik } from "formik";
import * as Yup from "yup";
import { Mdn, Sim } from "./icons/Svg";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button"; 
import { Toast } from "primereact/toast";
export default function WifiCalling(){            
    const [loader,setLoader]=useState(false)   
    const toast=useRef() 
     let loginDate=JSON.parse(localStorage.getItem("userData"))   
    const formik = useFormik({
        initialValues: {
            address1: "",
            address2: "", 
            city:"", 
            state:"", 
            zip:"",   
            userId:loginDate?._id, 
            enrollmentId:""
             

        },
        validationSchema: Yup.object({
            address1: Yup.string().required("Address is required"),
            city:Yup.string().required("City is required"),  
             state:Yup.string().required("State is required"), 
             zip:Yup.string().required("Zip is required"), 
              
        }),
        onSubmit: async (values) => {
               setLoader(true)
               Axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/addWfc`,values).then((res)=>{ 
                toast.current.show({ severity: "success", summary: "Wifi Calling", detail:"Wifi Calling Enabled Successfully" });
       
                setLoader(false)   
            }).catch(err=>{   
                toast.current.show({ severity: "error", summary: "Wifi Calling", detail:err?.response?.data?.status?.errors?.error?.message });
       
                  setLoader(false)
               })
         }})  
          //for pushing co de
     const [customerData,setCustomerData]=useState()  
      useEffect(()=>{ 
    Axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/userDetails?userId=${JSON.parse(localStorage.getItem("selectedId"))}`).then((res)=>{ 
       setCustomerData(res?.data?.data)  
        formik.setFieldValue("address1",res?.data?.data?.address1)  
        
        formik.setFieldValue("address2",res?.data?.data?.address2) 
         
        formik.setFieldValue("city",res?.data?.data?.city) 
        
        formik.setFieldValue("state",res?.data?.data?.state) 
        
        formik.setFieldValue("zip",res?.data?.data?.zip) 
         formik.setFieldValue("enrollmentId",res?.data?.data?._id)
    }).catch(err=>{ 

    })
            
      },[])   
     return(   <form onSubmit={formik.handleSubmit} className="card">   
         <div className="flex esn-mdn flex-wrap flex-row justify-content-left "> 
   <div>  
     <div className="label-icon flex flex-wrap flex-row justify-content-left">  
         <div  className="icon">
           <Sim/> 
            </div>
      <label> 
    SIM/ESN
      </label>  
       </div>
       <p> 
         {customerData?.esn !== undefined ? customerData?.esn : customerData?.esnId?.esn !== undefined ? customerData?.esnId?.esn : "NIL"}
       </p>
   </div>  
   <div>       
   <div className="label-icon flex flex-wrap flex-row justify-content-left">  
         <div className="icon" >
           <Mdn/> 
            </div>  
      <label> 
    MDN
      </label>  
       </div>
       <p> 
         {customerData?.phoneNumber !== undefined ? customerData?.phoneNumber : "NIL"}
       </p>
   </div>
         </div>
     <div className="customer-address"> 
     <h1 className="address-header">Customer Address</h1>
     </div>       
      <div className="flex flex-wrap flex-row  form-container justify-content-left"> 
           <div className=" mt-4 form-item"> 
         <label> 
             Address1 :
         </label> 
           <InputText  name="address1" onChange={formik.handleChange} value={formik.values.address1} className="w-full mt-4"/>
           {formik.touched.address1 && formik.errors.address1 ? (
                                    <p style={{ height: "10px" }} className="text-red-500 p-error ml-1 text-sm mt-0 ">
                                        {formik.errors.address1}
                                    </p>
                                ) : null}
           </div>  
           <div className=" mt-4 form-item"> 
         <label> 
             Address2 :
         </label> 
           <InputText  name="address2" onChange={formik.handleChange} value={formik.values.address2} className="w-full mt-4"/>
           {formik.touched.address2 && formik.errors.address2 ? (
                                    <p style={{ height: "10px" }} className="text-red-500 p-error ml-1 text-sm mt-0 ">
                                        {formik.errors.address2}
                                    </p>
                                ) : null}
           </div>  
           <div className=" mt-4 form-item"> 
         <label> 
            City :
         </label> 
           <InputText  name="city" onChange={formik.handleChange} value={formik.values.city} className="w-full mt-4"/>
           {formik.touched.city && formik.errors.city ? (
                                    <p style={{ height: "10px" }} className="text-red-500 p-error ml-1 text-sm mt-0 ">
                                        {formik.errors.city}
                                    </p>
                                ) : null}
           </div>    
           <div className=" mt-4 form-item"> 
         <label> 
             State :
         </label> 
           <InputText  name="state" onChange={formik.handleChange} value={formik.values.state} className="w-full mt-4"/>
           {formik.touched.state && formik.errors.state ? (
                                    <p style={{ height: "10px" }} className="text-red-500 p-error ml-1 text-sm mt-0 ">
                                        {formik.errors.state}
                                    </p>
                                ) : null}
           </div>  
           <div className=" mt-4 form-item"> 
         <label> 
             Zip :
         </label> 
           <InputText  name="zip" onChange={formik.handleChange} value={formik.values.zip} className="w-full mt-4"/>
           {formik.touched.zip && formik.errors.zip ? (
                                    <p style={{ height: "10px" }} className="text-red-500 p-error ml-1 text-sm mt-0 ">
                                        {formik.errors.zip}
                                    </p>
                                ) : null}
           </div> 
      </div>     
      <Toast ref={toast} /> 
      <Button disabled={loader}  loading={loader} type="submit" className="btn mt-4" label="Submit" />
     </form>
     )
}