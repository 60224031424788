import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MenuIcon, SidebarSelect } from "./assets/svgs/svg-assets-main";
import "./AppMenu.css";

export const AppMenu = ({ setMobileMenuActive, setSearchByValueClick, setSearchBy, model }) => {
    const location = useLocation();
    const [active, setActive] = useState(false);
    useEffect(() => {
        if (model && model.length > 0 && model[0]?.items) {
            model[0].items.forEach((element, index) => {
                element.items.forEach((element2) => {
                    if (element2?.to === location?.pathname) {
                        setCurrentActive(index);
                        setCurrentLocation(element2?.to);
                    }
                });
            });
        }
        if (location.pathname === "/") {
            setCurrentActive();
            setCurrentLocation();
        }
    }, [location, model]);

    let navigate = useNavigate();
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const capitalCompanyName = parseLoginRes?.companyName?.toUpperCase();
    const [currentLocation, setCurrentLocation] = useState();
    const [currentActive, setCurrentActive] = useState();
    return (
        <div className="layout-menu-container sidebar-wrapper ">
            <div className="w-full main-menu  flex flex-wrap flex-row justify-content-center align-content-start align-items-start" style={{ marginTop: "60px" }}>
                {model[0]?.items?.map((element, index) => {
                    return (
                        <div className="cursor-pointer sidebar-container w-full flex flex-wrap flex-row align-items-center justify-content-between">
                            <div
                                onClick={() => {
                                    if (currentActive !== index) {
                                        setCurrentActive(index);
                                        setActive(true);
                                    } else {
                                        setCurrentActive();
                                        setCurrentLocation();
                                    }
                                }}
                                className={
                                    currentActive === index && active
                                        ? "notactivemain  w-full flex flex-wrap flex-row align-items-center justify-content-between"
                                        : currentActive === index
                                          ? "activemenu  w-full flex flex-wrap flex-row align-items-center justify-content-between"
                                          : "main-sidebar-item-label  w-full flex flex-wrap flex-row align-items-center justify-content-between"
                                }
                            >
                                <div className=" main-sidebar-item-container wifull flex flex-wrap flex-row align-items-center justify-content-start">
                                    <div dangerouslySetInnerHTML={{ __html: element?.icon }} />
                                    <span>{element.label}</span>
                                </div>
                                <div>{element.items && <i className=" dropdown-svg pi pi-fw pi-angle-down menuitem-toggle-icon"></i>}</div>
                            </div>
                            {currentActive === index ? (
                                <div className="w-full flex  flex-wrap flex-row justify-content-center" style={{ position: "relative" }}>
                                    {element.items?.map((element2) => {
                                        return (
                                            <div className="main-dropdown-list w-full flex flex-wrap flex-row align-items-center justify-content-between">
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (window.innerWidth < 978) {
                                                            setMobileMenuActive(false);
                                                        }
                                                        navigate(element2?.to);
                                                        setCurrentLocation(element2?.to);
                                                        setActive(false);
                                                    }}
                                                    style={{ gap: "10px" }}
                                                    className="flex flex-wrap flex-row align-items-center justify-content-start"
                                                >
                                                    <span className={`${currentLocation === element2.to ? "selected-span-sidebar" : "sidebar-dropdown-menu-span"} `}>{element2.label}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
