import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "./app/store/auth/AuthSlice";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";
import "./apptopbar.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "../public/setting.svg";
import "../public/user.svg";
import "./app.css";
import "primeicons/primeicons.css";
import { Sidebar } from "primereact/sidebar";
import {
    AngleDown,
    AppButton,
    BatchIcon,
    BatchIconActive,
    BulkIcon,
    BulkIconActive,
    CustomIcon,
    CustomIconActive,
    DarkTheme,
    Home,
    InventoryIcon,
    InventoryIconActive,
    LanguageButton,
    LightTheme,
    Loginbg,
    LogoutIcon,
    LogoutIconActive,
    NotificationIcon,
    PostpaidIcon,
    PostpaidIconActive,
    PrepaidIcon,
    PrepaidIconActive,
    Prepaidnotification,
    PrivateIcon,
    PrivateIconActive,
    ProfileIcon,
    ProfileIconActive,
    ReportsIcon,
    ReportsIconActive,
    SearchIco,
    SettingIcon,
    SettingIconActive,
    SettingsIcon,
    SubsidizedOrderIcon,
    SubsidizedOrderIconActive,
    UserManagementIcon,
    UserManagementIconActive,
} from "./utility";
import Axios from "axios";
import io from "socket.io-client"; // Add this import for socket.io-client
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const AppTopbar = (props) => {
    const [visibleRight, setVisibleRight] = useState(false);
    const location = useLocation();
    const [counter, setCounter] = useState("");

    const [currentLocation, setCurrentLocation] = useState(location.pathname);
    const [profileActive, setProfileActive] = useState(false);
    const [settingActive, setSettingActive] = useState(false);
    const [privateActive, setPrivateActive] = useState(false);
    const [logoutActive, setLogoutActive] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [CustomerProfileIcon, setCustomerProfileIcon] = useState(false);
    const [subsidizedIcon, setSubsidizedIcon] = useState(false);
    const [prepaidIcon, setPrepaidIcon] = useState(false);
    const [postpaidIcon, setPostpaidIcon] = useState(false);
    const [inventoryIcon, setInventoryIcon] = useState(false);
    const [bulkIcon, setBulkIcon] = useState(false);
    const [batchIcon, setBatchIcon] = useState(false);
    const [settingsIcon, setSettingsIcon] = useState(false);
    const [userIcon, setUserIcon] = useState(false);
    const [reportsIcon, setReportsIcon] = useState(false);
    const [allNotification, setAllNotification] = useState(true);
    const [unreadNotification, setUnreadNotification] = useState(false);
    const [visibleSearch, setVisibleSearch] = useState(false);
    const [notification, setNotification] = useState([]);
    const unreadNotes = notification.filter((item) => !item?.read);
    console.log("unread", unreadNotification);
    console.log("read", allNotification);

    useEffect(() => {
        const handleRouteChange = (newLocation) => {
            if (newLocation.pathname !== currentLocation) {
                // if(props.searchByValueClick || props.searchBy){
                props.setSearchBy(null);
                props.setSearchByValueClick(false);
                setCurrentLocation(newLocation.pathname);
                //  }
            }
        };
        handleRouteChange(location);
        window.addEventListener("popstate", handleRouteChange);
        return () => {
            window.removeEventListener("popstate", handleRouteChange);
        };
    }, [location]);
    useEffect(() => {
        document.addEventListener("click", docOnClick, false);
    });
    const socket = useRef(null);

    useEffect(() => {
        socket.current = io(BASE_URL, {
            transports: ["websocket"],
        });
        socket.current.on("connect", () => {});
        socket.current.on("newNote", () => {
            fetchNotifications();
        });

        socket.current.on("error", (err) => {});
        socket.current.on("error", (err) => {});

        // Fetch notifications when the component mounts (for login and refresh scenarios)
        fetchNotifications();

        return () => {
            socket.current.disconnect();
        };
    }, []);
    useEffect(() => {
        if (props.showApps) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [props.showApps]);
    const fetchNotifications = async () => {
        try {
            const response = await Axios.get(`${BASE_URL}/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
            const data = response?.data?.unreadCount;
            const note = response?.data?.notifications;
            setNotification(note);
            setCounter(data);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    function docOnClick(e) {
        setVisibleSearch(false);
    }
    //Dialogues for Advance Search
    const countries = [
        { name: "Inventory Search", code: "inventorysearch" },
        // { name: "Payment Search", code: "paymentsearch" },
        { name: "Recent Searches", code: "recentsearches" },
        { name: "Advance Search", code: "advance search" },
    ];

    const countryTemplate = (option) => {
        return (
            <div className="flex listbox-container align-items-center">
                {option.name === "Payment Search" ? (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/payment-search.png"
                        alt="none"
                    />
                ) : option.name === "Recent Searches" ? (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/recent-search.png"
                        alt="none"
                    />
                ) : option.name === "Advance Search" ? (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/advance-search.png"
                        alt="none"
                    />
                ) : (
                    <img
                        onError={(e) => {
                            e.target.src = "./placeholderimage.jpg";
                        }}
                        src="/images/Dashboard-Search/inventory-search.png"
                        alt="none"
                    />
                )}
                <div>{option.name}</div>
            </div>
        );
    };
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Get user data from localStorage
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    const capitalCompanyName = parseLoginRes?.companyName?.toUpperCase();
    const handleLogout = () => {
        props.setSearchValue("");
        props.setSearchByValueClick(false);
        props.setSearchBy(null);
        dispatch(logout());
        navigate("/login");
    };
    // const CustomMessage = () => (
    //     <div
    //         className="flex flex-column w-15rem"
    //         onClick={(e) => {
    //             e.stopPropagation();
    //         }}
    //     >
    //         <i className="pi pi-user p-mr-2 text-center" style={{ fontSize: "2rem" }}></i>
    //         <p className="text-center mt-2" style={{ fontSize: "1.5rem" }}>
    //             {parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}
    //         </p>
    //         <p className="flex flex-wrap justify-content-center" style={{ fontSize: "1rem" }}>
    //             {parseLoginRes?.role?.role}
    //         </p>
    //     </div>
    // );
    // useEffect(() => {
    //     const getCounter = async () => {
    //         setInterval(async () => {
    //             try {
    //                 const response = await Axios.get(`${BASE_URL}/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
    //                 const data = response?.data?.unreadCount;
    //                 const note = response?.data?.notifications;
    //                 setNotification(note);
    //                 setCounter(data);
    //             } catch (error) {
    //                 toast.error(error?.response?.data?.msg);
    //             }
    //         }, 15000);
    //     };
    //     getCounter();
    // }, [props.refreshBell]);

    const handleReadNotification = async (notificationId) => {
        try {
            await Axios.put(`${BASE_URL}/api/web/notes/markReadnotifications?notificationId=${notificationId}&userId=${parseLoginRes?._id}`);
            // const response = await Axios.put(`${BASE_URL}/api/web/notes/markReadnotifications?notificationId=${notificationId}&userId=${parseLoginRes?._id}`);

            const res = await Axios.get(`${BASE_URL}/api/web/notes/notifications?userId=${parseLoginRes?._id}`);
            const { unreadCount, notifications } = res?.data;
            setNotification(notifications);
            setCounter(unreadCount);
        } catch (error) {
            toast(error?.response?.data?.msg);
        }
    };
    const handleNavigate = (customerId) => {
        if (customerId !== undefined) {
            localStorage.setItem("selectedId", JSON.stringify(customerId));
            navigate("/customer-profile", { state: { selectedId: customerId } });
            if (location.pathname === "/customer-profile") {
                props.setRefreshNotificationComponent((prev) => !prev);
            }
        }
    };

    return (
        <div>
            <ToastContainer />

            {!props.showApps ? (
                <div className="notshowapps-main">
                    <div className="hamburgur">
                        <button type="button" className="layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                            <i
                                style={{
                                    fontSize: "1.25em",
                                    marginTop: "-5px",
                                    display: "inline-block",
                                    color: "rgb(112, 134, 253)",
                                }}
                                className="pi pi-bars"
                            />
                        </button>
                    </div>
                    <div
                        className="layout-topbar"
                        onClick={(e) => {
                            setVisibleSearch(false);
                            e.stopPropagation();
                        }}
                    >
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-start">
                            <div className="flex logo-search flex-wrap flex-row justify-content-start align-items-center">
                                <div className="sidebar-company-logo flex flex-wrap flex-row justify-content-center">
                                    <Link
                                        to="/"
                                        className=""
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.setSearchBy(null);
                                            props.setSearchByValueClick(false);
                                        }}
                                    >
                                        <img
                                            onError={(e) => {
                                                e.target.src = "./placeholderimage.jpg";
                                            }}
                                            src={`${capitalCompanyName.includes("IJ") ? "/companyLogo1.png" : capitalCompanyName.includes("ZISFONE") ? "/zisfonelogo.png" : capitalCompanyName.includes("MAGNET") ? "/magnetlogo.png" : "/Talkdaily.png"}`}
                                            alt="Logo"
                                        />
                                    </Link>
                                </div>
                                <div className="pl-2 search-customer flex flex-wrap flex-row justify-content-center align-items-center">
                                    <SearchIco />
                                    <InputText
                                        onChange={(e) => {
                                            props.setSearchValue(e.target.value);
                                        }}
                                        value={props.searchValue}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                                if (props.searchValue !== "") {
                                                    props.setSearchByValueClick(true);
                                                    props.setCallSearchApi((prev) => !prev);
                                                }
                                                setVisibleSearch(false);
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (props.searchValue !== "") {
                                                props.setSearchByValueClick(true);
                                                props.setCallSearchApi((prev) => !prev);
                                            }
                                            setVisibleSearch(true);
                                        }}
                                        placeholder="Search Customer"
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                    />
                                    {/* <i
                        className="pi pi-search search-toggle"
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibleSearch(false);
                            props.setSearchBy(null);
                            props.setSearchByValueClick(true);
                            if (props.searchByValueClick === true) {
                                props.setCallSearchApi((prev) => !prev);
                            }
                        }}
                        style={{ border: "none" }}
                    /> */}
                                </div>
                            </div>
                            <div className="buttonsdiv align-items-start">
                                <div className="appbutton" onClick={() => props.setShowApps(true)} style={{ cursor: "pointer" }}>
                                    <span className="app-span flex justify-content-center align-items-center gradient-text">
                                        <Home />
                                    </span>
                                </div>
                                {/*   <div className="themebutton">
                                    <span className="theme-span flex justify-content-center align-items-center gradient-text">
                                        <DarkTheme /> <LightTheme />
                                    </span>
                                </div>  */}
                                <div className="appbutton" style={{ cursor: "pointer" }} onClick={() => setVisibleRight(true)}>
                                    <div>
                                        <span className="notification-span flex justify-content-center align-items-center gradient-text">
                                            <NotificationIcon />
                                        </span>
                                    </div>
                                    {/* <span className="gradient-text notification-count">{counter <= 9 ? counter : "9+"}</span> */}
                                </div>
                                {/* <div className="languagebutton ">
                                    <div className="language-span flex justify-content-center align-items-center gradient-text">
                                        <LanguageButton /> EN
                                    </div>
                                </div>    */}

                                {showProfileMenu ? (
                                    <div
                                        onMouseLeave={() => {
                                            setShowProfileMenu(false);
                                        }}
                                        className="userloginexpand "
                                    >
                                        <div className="main-p flex flex-wrap justify-content-start align-items-center mt-1">
                                            <img
                                                onError={(e) => {
                                                    e.target.src = "./topbar-image-placeholder.png";
                                                }}
                                                src="/topbar-image-placeholder.png"
                                                alt=""
                                            />
                                            <p className="gradient-text" style={{ fontWeight: "500", paddingLeft: "5px" }}>
                                                {parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}
                                            </p>
                                        </div>
                                        <div className="userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setProfileActive(true)} onMouseLeave={() => setProfileActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {profileActive ? <ProfileIconActive /> : <ProfileIcon />}
                                            <span>View Profile</span>
                                        </div>
                                        <div className=" userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setSettingActive(true)} onMouseLeave={() => setSettingActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {settingActive ? <SettingIconActive /> : <SettingIcon />}
                                            <span>Setting</span>
                                        </div>
                                        <div className="userexpand-list flex  cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setPrivateActive(true)} onMouseLeave={() => setPrivateActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {privateActive ? <PrivateIconActive /> : <PrivateIcon />}
                                            <span>Private</span>
                                        </div>
                                        <div
                                            className=" userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2"
                                            onMouseEnter={() => setLogoutActive(true)}
                                            onMouseLeave={() => setLogoutActive(false)}
                                            onClick={() => {
                                                setShowProfileMenu(false);
                                                handleLogout();
                                            }}
                                        >
                                            {logoutActive ? <LogoutIconActive /> : <LogoutIcon />}
                                            <span>Sign Out</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="userlogin cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowProfileMenu(true);
                                        }}
                                    >
                                        <div className="main-p pr-1 flex justify-content-between w-full align-items-center gradient-text" onClick={() => setVisible(true)}>
                                            <img
                                                onError={(e) => {
                                                    e.target.src = "./placeholderimage.jpg";
                                                }}
                                                src="/topbar-image-placeholder.png"
                                                alt=""
                                            />

                                            <AngleDown />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className="flex justify-content-center listbox"
                        >
                            <ListBox
                                value={props.searchBy}
                                style={{ display: `${visibleSearch === true ? "block" : "none"}`, backgroundColor: "#e0edfe" }}
                                onChange={(e) => {
                                    if (e.value !== null) {
                                        props.setSearchByValueClick(false);
                                        props.setSearchBy(e.value);
                                        setVisibleSearch(false);
                                    }
                                }}
                                options={countries}
                                optionLabel="name"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (e.target.textContent === "Advance Search") {
                                    }
                                }}
                                itemTemplate={countryTemplate}
                                className="w-full "
                                listStyle={{ maxHeight: "250px" }}
                            />
                        </div>
                        <div className="flex  ">
                            <Sidebar closeable={false} style={{ width: "408px" }} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                                <div className="flex justify-content-start align-items-center" style={{ height: "25px", width: "116px", marginTop: "15px" }}>
                                    <button
                                        className={allNotification ? "allbtnactive" : "allbtn"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setUnreadNotification(false);
                                            setAllNotification(true);
                                        }}
                                    >
                                        ALL
                                    </button>
                                    <button
                                        className={unreadNotification ? "unreadbtnactive" : "unreadbtn"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setUnreadNotification(true);
                                            setAllNotification(false);
                                        }}
                                    >
                                        Unread
                                    </button>
                                </div>
                                <hr />
                                {unreadNotification
                                    ? unreadNotes.map((item, index) => (
                                          <div
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                  handleNavigate(item?.customerId);
                                                  setVisibleRight(false);
                                                  handleReadNotification(item?._id);
                                                  props.setHandleHighlight(item?.noteId);
                                              }}
                                              className={item?.read ? "notificationread" : "notificationunread"}
                                          >
                                              <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                  <div>
                                                      <Prepaidnotification />
                                                  </div>
                                                  <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                      <h2>New Prepaid SIM Activation Request</h2>
                                                      <p>{item.message}</p>
                                                      <span style={{ cursor: "pointer" }}>
                                                          <h5
                                                              style={{ fontSize: "12px" }}
                                                              onClick={() => {
                                                                  handleReadNotification(item?._id);
                                                              }}
                                                          >
                                                              {new Date(item?.timestamp).toLocaleDateString("en-US", {
                                                                  weekday: "long",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              })}
                                                          </h5>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                    : notification.map((item, index) => (
                                          <div
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                  handleNavigate(item?.customerId);
                                                  setVisibleRight(false);
                                                  handleReadNotification(item?._id);
                                                  props.setHandleHighlight(item?.noteId);
                                              }}
                                              className={item?.read ? "notificationread" : "notificationunread"}
                                          >
                                              <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                  <div>
                                                      <Prepaidnotification />
                                                  </div>
                                                  <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                      <h2>New Prepaid SIM Activation Request</h2>
                                                      <p>{item.message}</p>
                                                      <span style={{ cursor: "pointer" }}>
                                                          <h5
                                                              style={{ fontSize: "12px" }}
                                                              onClick={() => {
                                                                  handleReadNotification(item?._id);
                                                              }}
                                                          >
                                                              {new Date(item?.timestamp).toLocaleDateString("en-US", {
                                                                  weekday: "long",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              })}
                                                          </h5>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                            </Sidebar>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="appdialog">
                    <div
                        className="layout-topbar"
                        onClick={(e) => {
                            setVisibleSearch(false);
                            e.stopPropagation();
                        }}
                    >
                        <div className="w-full flex flex-wrap flex-row justify-content-between align-items-start">
                            <div className="flex logo-search flex-wrap flex-row justify-content-start align-items-center">
                                <div className="sidebar-company-logo flex flex-wrap flex-row justify-content-center">
                                    <Link
                                        to="/"
                                        className=""
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.setSearchBy(null);
                                            props.setSearchByValueClick(false);
                                        }}
                                    >
                                        <img
                                            onError={(e) => {
                                                e.target.src = "./placeholderimage.jpg";
                                            }}
                                            src={`${capitalCompanyName.includes("IJ") ? "/companyLogo1.png" : capitalCompanyName.includes("ZISFONE") ? "/zisfonelogo.png" : capitalCompanyName.includes("MAGNET") ? "/magnetlogo.png" : "/Talkdaily.png"}`}
                                            alt="Logo"
                                        />
                                    </Link>
                                </div>
                                <div className="pl-2 search-customer flex flex-wrap flex-row justify-content-center align-items-center">
                                    <SearchIco />
                                    <InputText
                                        onChange={(e) => {
                                            props.setSearchValue(e.target.value);
                                        }}
                                        value={props.searchValue}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                e.stopPropagation();
                                                if (props.searchValue !== "") {
                                                    props.setSearchByValueClick(true);
                                                    props.setCallSearchApi((prev) => !prev);
                                                }
                                                setVisibleSearch(false);
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (props.searchValue !== "") {
                                                props.setSearchByValueClick(true);
                                                props.setCallSearchApi((prev) => !prev);
                                            }
                                            setVisibleSearch(true);
                                        }}
                                        placeholder="Search Customer"
                                        style={{ border: "none", backgroundColor: "transparent" }}
                                    />
                                    {/* <i
                        className="pi pi-search search-toggle"
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibleSearch(false);
                            props.setSearchBy(null);
                            props.setSearchByValueClick(true);
                            if (props.searchByValueClick === true) {
                                props.setCallSearchApi((prev) => !prev);
                            }
                        }}
                        style={{ border: "none" }}
                    /> */}
                                </div>
                            </div>
                            <div className="buttonsdiv align-items-start">
                                <div className="appbutton" onClick={() => props.setShowApps(true)} style={{ cursor: "pointer" }}>
                                    <span className="app-span flex justify-content-center align-items-center gradient-text">
                                        <Home />
                                    </span>
                                </div>
                                {/*   <div className="themebutton">
                                    <span className="theme-span flex justify-content-center align-items-center gradient-text">
                                        <DarkTheme /> <LightTheme />
                                    </span>
                                </div>  */}
                                <div className="appbutton" style={{ cursor: "pointer" }} onClick={() => setVisibleRight(true)}>
                                    <div>
                                        <span className="notification-span flex justify-content-center align-items-center gradient-text">
                                            <NotificationIcon />
                                        </span>
                                    </div>
                                    {/* <span className="gradient-text notification-count">{counter <= 9 ? counter : "9+"}</span> */}
                                </div>
                                {/* <div className="languagebutton ">
                                    <div className="language-span flex justify-content-center align-items-center gradient-text">
                                        <LanguageButton /> EN
                                    </div>
                                </div>    */}

                                {showProfileMenu ? (
                                    <div
                                        onMouseLeave={() => {
                                            setShowProfileMenu(false);
                                        }}
                                        className="userloginexpand "
                                    >
                                        <div className="main-p flex flex-wrap justify-content-start align-items-center mt-1">
                                            <img
                                                onError={(e) => {
                                                    e.target.src = "./topbar-image-placeholder.png";
                                                }}
                                                src="/topbar-image-placeholder.png"
                                                alt=""
                                            />
                                            <p className="gradient-text" style={{ fontWeight: "500", paddingLeft: "5px" }}>
                                                {parseLoginRes?.userName ? parseLoginRes?.userName.toUpperCase() : ""}
                                            </p>
                                        </div>
                                        <div className="userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setProfileActive(true)} onMouseLeave={() => setProfileActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {profileActive ? <ProfileIconActive /> : <ProfileIcon />}
                                            <span>View Profile</span>
                                        </div>
                                        <div className=" userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setSettingActive(true)} onMouseLeave={() => setSettingActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {settingActive ? <SettingIconActive /> : <SettingIcon />}
                                            <span>Setting</span>
                                        </div>
                                        <div className="userexpand-list flex  cursor-pointer flex-wrap justify-content-center align-items-center pl-2" onMouseEnter={() => setPrivateActive(true)} onMouseLeave={() => setPrivateActive(false)} onClick={() => setShowProfileMenu(false)}>
                                            {privateActive ? <PrivateIconActive /> : <PrivateIcon />}
                                            <span>Private</span>
                                        </div>
                                        <div
                                            className=" userexpand-list flex cursor-pointer flex-wrap justify-content-center align-items-center pl-2"
                                            onMouseEnter={() => setLogoutActive(true)}
                                            onMouseLeave={() => setLogoutActive(false)}
                                            onClick={() => {
                                                setShowProfileMenu(false);
                                                handleLogout();
                                            }}
                                        >
                                            {logoutActive ? <LogoutIconActive /> : <LogoutIcon />}
                                            <span>Sign Out</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="userlogin cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowProfileMenu(true);
                                        }}
                                    >
                                        <div className="main-p pr-1 flex justify-content-between w-full align-items-center gradient-text" onClick={() => setVisible(true)}>
                                            <img
                                                onError={(e) => {
                                                    e.target.src = "./placeholderimage.jpg";
                                                }}
                                                src="/topbar-image-placeholder.png"
                                                alt=""
                                            />

                                            <AngleDown />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className="flex justify-content-center listbox"
                        >
                            <ListBox
                                value={props.searchBy}
                                style={{ display: `${visibleSearch === true ? "block" : "none"}`, backgroundColor: "#e0edfe" }}
                                onChange={(e) => {
                                    if (e.value !== null) {
                                        props.setSearchByValueClick(false);
                                        props.setSearchBy(e.value);
                                        setVisibleSearch(false);
                                    }
                                }}
                                options={countries}
                                optionLabel="name"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (e.target.textContent === "Advance Search") {
                                    }
                                }}
                                itemTemplate={countryTemplate}
                                className="w-full "
                                listStyle={{ maxHeight: "250px" }}
                            />
                        </div>
                        <div className="flex  ">
                            <Sidebar closeable={false} style={{ width: "408px" }} visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
                                <div className="flex justify-content-start align-items-center" style={{ height: "25px", width: "116px", marginTop: "15px" }}>
                                    <button
                                        className={allNotification ? "allbtnactive" : "allbtn"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setUnreadNotification(false);
                                            setAllNotification(true);
                                        }}
                                    >
                                        ALL
                                    </button>
                                    <button
                                        className={unreadNotification ? "unreadbtnactive" : "unreadbtn"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setUnreadNotification(true);
                                            setAllNotification(false);
                                        }}
                                    >
                                        Unread
                                    </button>
                                </div>
                                <hr />
                                {unreadNotification
                                    ? unreadNotes.map((item, index) => (
                                          <div
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                  handleNavigate(item?.customerId);
                                                  setVisibleRight(false);
                                                  handleReadNotification(item?._id);
                                                  props.setHandleHighlight(item?.noteId);
                                              }}
                                              className={item?.read ? "notificationread" : "notificationunread"}
                                          >
                                              <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                  <div>
                                                      <Prepaidnotification />
                                                  </div>
                                                  <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                      <h2>New Prepaid SIM Activation Request</h2>
                                                      <p>{item.message}</p>
                                                      <span style={{ cursor: "pointer" }}>
                                                          <h5
                                                              style={{ fontSize: "12px" }}
                                                              onClick={() => {
                                                                  handleReadNotification(item?._id);
                                                              }}
                                                          >
                                                              {new Date(item?.timestamp).toLocaleDateString("en-US", {
                                                                  weekday: "long",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              })}
                                                          </h5>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                      ))
                                    : notification.map((item, index) => (
                                          <div
                                              key={index}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                  handleNavigate(item?.customerId);
                                                  setVisibleRight(false);
                                                  handleReadNotification(item?._id);
                                                  props.setHandleHighlight(item?.noteId);
                                              }}
                                              className={item?.read ? "notificationread" : "notificationunread"}
                                          >
                                              <div className="innernotification flex align-items-start justify-content-start " style={{ marginLeft: "-15px" }}>
                                                  <div>
                                                      <Prepaidnotification />
                                                  </div>
                                                  <div className="flex flex-column align-items-start justify-content-start ml-4" style={{ paddingTop: "2px" }}>
                                                      <h2>New Prepaid SIM Activation Request</h2>
                                                      <p>{item.message}</p>
                                                      <span style={{ cursor: "pointer" }}>
                                                          <h5
                                                              style={{ fontSize: "12px" }}
                                                              onClick={() => {
                                                                  handleReadNotification(item?._id);
                                                              }}
                                                          >
                                                              {new Date(item?.timestamp).toLocaleDateString("en-US", {
                                                                  weekday: "long",
                                                                  month: "short",
                                                                  day: "numeric",
                                                              })}
                                                          </h5>
                                                      </span>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                            </Sidebar>
                        </div>
                    </div>
                    <div className="appsicons">
                        <div className="flex  w-full bggradapps" style={{ position: "absolute", zIndex: "0", height: "100%" }}>
                            <Loginbg />
                        </div>
                        <div className="flex flex-column justify-content-center align-items-center w-full" style={{ zIndex: "0" }}>
                            <h1>Effortless Telecom Navigation Simplified for You</h1>
                            <div className="mainappicons">
                                <div
                                    className="iconcontainer"
                                    onClick={() => {
                                        props.setShowApps(false);
                                        setCustomerProfileIcon(false);
                                        navigate("/customer-profile");
                                    }}
                                    onMouseEnter={() => setCustomerProfileIcon(true)}
                                    onMouseLeave={() => setCustomerProfileIcon(false)}
                                >
                                    <div className={CustomerProfileIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{CustomerProfileIcon ? <CustomIconActive /> : <CustomIcon />}</span>
                                        <h3>Customer Profile</h3>
                                        <p>View customer details and order history</p>
                                    </div>
                                </div>
                                <div className="iconcontainer" onMouseEnter={() => setSubsidizedIcon(true)} onMouseLeave={() => setSubsidizedIcon(false)}>
                                    <div className={subsidizedIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{subsidizedIcon ? <SubsidizedOrderIconActive /> : <SubsidizedOrderIcon />}</span>
                                        <h3>ACP Orders</h3>
                                        <p>View customer details and order history</p>
                                    </div>
                                </div>
                                <div
                                    className="iconcontainer"
                                    onMouseEnter={() => setPrepaidIcon(true)}
                                    onMouseLeave={() => setPrepaidIcon(false)}
                                    onClick={() => {
                                        props.setShowApps(false);
                                        setPrepaidIcon(false);
                                        navigate("/prepaid-allenrollment");
                                    }}
                                >
                                    <div className={prepaidIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{prepaidIcon ? <PrepaidIconActive /> : <PrepaidIcon />}</span>
                                        <h3>Prepaid SIMs</h3>
                                        <p>View customer details and order history</p>
                                    </div>
                                </div>
                                <div
                                    className="iconcontainer"
                                    onMouseEnter={() => setPostpaidIcon(true)}
                                    onMouseLeave={() => setPostpaidIcon(false)}
                                    onClick={() => {
                                        props.setShowApps(false);

                                        setPostpaidIcon(false);
                                        navigate("/postpaid-allenrollment");
                                    }}
                                >
                                    <div className={postpaidIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{postpaidIcon ? <PostpaidIconActive /> : <PostpaidIcon />}</span>
                                        <h3>Postpaid Connections</h3>
                                        <p>View customer details and order history</p>
                                    </div>
                                </div>
                                <div
                                    className="iconcontainer"
                                    onClick={() => {
                                        props.setShowApps(false);

                                        setInventoryIcon(false);
                                        navigate("/manageinventory");
                                    }}
                                    onMouseEnter={() => setInventoryIcon(true)}
                                    onMouseLeave={() => setInventoryIcon(false)}
                                >
                                    <div className={inventoryIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{inventoryIcon ? <InventoryIconActive /> : <InventoryIcon />}</span>
                                        <h3>Inventory Management</h3>
                                        <p>Add and control inventory</p>
                                    </div>
                                </div>
                                <div className="iconcontainer" onMouseEnter={() => setBulkIcon(true)} onMouseLeave={() => setBulkIcon(false)}>
                                    <div className={bulkIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{bulkIcon ? <BulkIconActive /> : <BulkIcon />}</span>
                                        <h3>Bulk SMS/Email</h3>
                                        <p>Send bulk messages and emails to customers</p>{" "}
                                    </div>
                                </div>
                                <div className="iconcontainer" onMouseEnter={() => setBatchIcon(true)} onMouseLeave={() => setBatchIcon(false)}>
                                    <div className={batchIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{batchIcon ? <BatchIconActive /> : <BatchIcon />}</span>
                                        <h3>Batch Download</h3>
                                        <p>Download invoices and labels in bulk</p>
                                    </div>
                                </div>
                                <div
                                    className="iconcontainer"
                                    onClick={() => {
                                        setSettingsIcon(false);
                                        props.setShowApps(false);
                                        navigate("/billingconfiguration");
                                    }}
                                    onMouseEnter={() => setSettingsIcon(true)}
                                    onMouseLeave={() => setSettingsIcon(false)}
                                >
                                    <div className={settingsIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{settingsIcon ? <SettingIconActive /> : <SettingIcon />}</span>
                                        <h3>Settings</h3>
                                        <p>Configure billing, plans, and inventory options</p>{" "}
                                    </div>
                                </div>
                                <div className="iconcontainer" onMouseEnter={() => setUserIcon(true)} onMouseLeave={() => setUserIcon(false)}>
                                    <div className={userIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{userIcon ? <UserManagementIconActive /> : <UserManagementIcon />}</span>
                                        <h3>User Management</h3>
                                        <p>Control user access and permissions</p>
                                    </div>
                                </div>
                                <div className="iconcontainer" onMouseEnter={() => setReportsIcon(true)} onMouseLeave={() => setReportsIcon(false)}>
                                    <div className={reportsIcon ? "appinnerhover" : "appinner"}>
                                        <span className="appiconbtn">{reportsIcon ? <ReportsIconActive /> : <ReportsIcon />} </span>
                                        <h3>Reports</h3>
                                        <p>Download reports across all modules</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
