import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import Axios from "axios";
import { RadioButton } from "primereact/radiobutton";
import { ToastContainer, toast } from "react-toastify";
import CardAuthPayment from "./dialog/CardAuthPayment";
import EcheckAuthPayment from "./dialog/EcheckAuthPayment";
import PaymentStripModule from "./dialog/stripe_payment";
import "./css/Agree.css"
import { Tickbtn } from "../../../../../utility";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Agree = ({ handleNext, handleBack, enrollment_id, _id, planVal, setPlanVal, inventoryVal, setInventoryVal, currentScreen, setCurrentScreen, carrierVal, setCarrierVal, servicesVal, setServicesVal, deliveryVal, setDeliveryVal, checked, setChecked }) => {
    const basicData = JSON.parse(localStorage.getItem("basicData"));
    const comingfromincomplete = localStorage.getItem("fromIncomplete");
    const shippingLabelDiscount = basicData?.data?.shippingLabelDiscount;
    const labelSubmissionval = basicData?.data?.labelSubmissionval;

    const [inventory, setInventory] = useState();
    const [paymentDialogVisibility, setPaymentDialogVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogForCardAuth, setdialogForCardAuth] = useState(false);
    const [dialogForEcheck, setDialogForEcheck] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const companyName = parseLoginRes?.companyName;
    const toCapitalCompanyName = companyName.toUpperCase();
    const [paidAmountRequired, setPaidAmountRequired] = useState(false);
    const [current, setCurrentSelect] = useState("");
    const [newinvenotoryselect, setNewInventorySelect] = useState(false);
    const [currentPlanSelect, setCurrentPlanSelect] = useState("");
    const [previousPlanPrice, setPreviousPlanPrice] = useState(0);
    const [active, setActive] = useState(false);
    const [activePlan, setActivePlan] = useState(false);
    const [carrier, setCarrier] = useState([]);
    const [services, setServices] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [rates, setRates] = useState([]);
    const [previousRate, setPreviousRate] = useState();
    // const [checkLabel, setCheckLabel] = useState(false);
    // JSON.stringify(localStorage.setItem("checkLabel", checkLabel));
    // try {
    //     const storedItem = localStorage.getItem("checkLabel");
    //     checkLabel = storedItem ? JSON.parse(storedItem) : null;
    // } catch (error) {
    //     try {
    //         console.error("Error parsing checkLabel from localStorage", error);
    //         checkLabel = null;
    //     } catch (e) {}
    // }
    const [ratesLoading, setRatesLoading] = useState(false);
    const [labelCheck, setLabelCheck] = useState(comingfromincomplete ? labelSubmissionval : false);
    const deliveryFedEx = [
        { name: "None", value: "none" },
        { name: "Delivery", value: "delivery" },
        { name: "Signature", value: "signature" },
        { name: "Direct_Signature", value: "direct_signature" },
        { name: "Adult_Signature", value: "adult_signature" },
    ];

    const checkinvenyory = JSON.parse(localStorage.getItem("inventoryselect"));
    const checkplan = JSON.parse(localStorage.getItem("planselect"));
    const simdata = JSON.parse(localStorage.getItem("simpricing"));
    const simdiscount = simdata?.selectdiscount;
    const devicedata = JSON.parse(localStorage.getItem("devicepricing"));
    const devicediscount = devicedata?.selectdiscount;
    //Handle Back
    let paymentInfo = JSON.parse(localStorage.getItem("dataToSend"));

    const paymentStatus = localStorage.getItem("paymentstatus");

    const validationSchema = Yup.object().shape({
        //billId: Yup.string().required("Product is required"),
        paymentMode: Yup.string().required("Payment Mode are required"),
        plan: Yup.string().required("Please Select it first"),
    });
    function capitalizeSentence(sentence) {
        // Split the sentence into words
        const words = sentence.split(" ");
        // Capitalize the first letter of each word and convert the rest to lowercase
        const capitalizedWords = words.map((word) => {
            // Convert the first letter to uppercase and the rest to lowercase
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });
        // Join the words back into a sentence
        return capitalizedWords.join(" ");
    }
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            plan: "",
            billId: [],
            paymentMode: "",
            discounts: "",
            additional: [],
            totalamount: "",
            customerid: _id,
            type: "Sign Up ",
            paid: "",
            productName: "",
        },

        onSubmit: async (values, actions) => {
            if (formik.values.paymentMode == "Skip Payment") {
                const shipmentName = localStorage.getItem("shipmentName");
                const shipmentCost = localStorage.getItem("shipmentCost");

                localStorage.setItem("skippayment", "true");
                setIsLoading(true);
                const dataToSend = {
                    invoiceType: "Sign Up",
                    customerId: _id,
                    planId: formik.values.plan,
                    planCharges: planCharges,
                    additionalCharges: formik.values.additional,
                    discount: discounts,
                    totalAmount: formik.values.totalamount,
                    amountPaid: "0",
                    invoiceDueDate: dueDate,
                    billingPeriod: {
                        from: "onActivation",
                        to: "onActivation",
                    },
                    invoiceStatus: "pending",
                    paymentMethod: "Skip Payment",
                    invoiceOneTimeCharges: oneTimeCharge,
                    lateFee: applyLateFee,
                    planName: planName,
                    chargingType: "Monthly",
                    printSetting: "Both",
                    productName: "",
                    selectProduct: formik.values.billId,
                    isShipmentCostDiscounted: checked ? true : false,
                    shipmentCost: {
                        name: shipmentName,
                        amount: shipmentCost,
                    },
                };
          
                try {
                    const response = await Axios.post(`${BASE_URL}/api/user/postpaidpaymentDetails`, dataToSend);
                    if (response?.status === 201 || response?.status === 200) {
                        localStorage.setItem("dataToSend", JSON.stringify(dataToSend));
                        localStorage.setItem("productData", JSON.stringify(response?.data?.data));
                        handleNext();
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                    setIsLoading(false);
                }
                setIsLoading(false);
            } else if (paymentStatus === "paid") {
                handleNext();
            } else if (formik.values.paymentMode === "E-Check (Authorize)") {
                setDialogForEcheck(true);
            } else if (formik.values.paymentMode == "Credit Card (Authorize)") {
                setdialogForCardAuth(true);
            } else if (formik.values.paymentMode == "Credit Card") {
                if (formik.values.paid !== "") {
                    setPaymentDialogVisibility(true);
                } else {
                    setPaidAmountRequired(true);
                }
            }
            else if (formik.values.paymentMode == "Credit Card" && toCapitalCompanyName.toUpperCase() == "MAGNET WIRELESS, INC") {
                if (formik.values.paid !== "") {
                    setPaymentDialogVisibility(true);
                } else {
                    setPaidAmountRequired(true);
                }
            }
        },
    });

    let additionalFeature = [];
    let discounts = [];

    let devicepricing = JSON.parse(localStorage.getItem("devicepricing"));
    let simpricing = JSON.parse(localStorage.getItem("simpricing"));
    let dueDate = "";
    let applyLateFee = "";
    let oneTimeCharge = "";
    let planName = "";
    let planId = "";
    let planCharges = "";
    if (formik.values.plan === simpricing?._id) {
        dueDate = simpricing?.dueDate;

        oneTimeCharge = simpricing?.oneTimeCharge;
        applyLateFee = simpricing?.applyLateFee;
        for (let i = 0; i < simpricing?.selectdiscount?.length; i++) {
            let obj = {
                name: simpricing?.selectdiscount[i]?.discountname,
                amount: simpricing?.selectdiscount[i]?.amount,
            };
            discounts.push(obj);
        }
        for (let i = 0; i < simpricing?.monthlyCharge?.length; i++) {
            if (formik.values.billId === simpricing?.monthlyCharge[i]?._id) {
                planName = simpricing?.monthlyCharge[i]?.name;
                planCharges = simpricing?.monthlyCharge[i]?.price;
                planId = simpricing?.monthlyCharge[i]?._id;
            }
        }
        let simadditional = JSON.parse(localStorage.getItem("simadditionalfeaturearray"));
        for (let k = 0; k < simadditional?.length; k++) {
            for (let i = 0; i < simpricing?.additionalFeature?.length; i++) {
                if (simpricing?.additionalFeature[i]?.featureName?._id === simpricing?.additionalFeature[i]?._id) {
                    let obj = {
                        name: simpricing?.additionalFeature[i]?.featureName,
                        amount: simpricing?.additionalFeature[i]?.featureAmount,
                    };
                    additionalFeature.push(obj);
                }
            }
        }
    } else {
        dueDate = devicepricing?.dueDate;

        applyLateFee = devicepricing?.applyLateFee;
        oneTimeCharge = devicepricing?.oneTimeCharge;
        for (let i = 0; i < devicepricing?.selectdiscount?.length; i++) {
            let obj = {
                name: devicepricing?.selectdiscount[i]?.discountname,
                amount: devicepricing?.selectdiscount[i]?.amount,
            };
            discounts.push(obj);
        }
        for (let i = 0; i < devicepricing?.monthlyCharge?.length; i++) {
            if (formik.values.plan === devicepricing?.monthlyCharge[i]?._id) {
                planName = devicepricing?.monthlyCharge[i]?.name;
                planCharges = devicepricing?.monthlyCharge[i]?.price;

                planId = devicepricing?.monthlyCharge[i]?._id;
            }
        }
        let deviceadditional = JSON.parse(localStorage.getItem("deviceadditionalfeaturearray"));

        for (let k = 0; k < deviceadditional?.length; k++) {
            for (let i = 0; i < devicepricing?.additionalFeature?.length; i++) {
                if (deviceadditional[k] === devicepricing?.additionalFeature[i]?._id) {
                    let obj = {
                        name: devicepricing?.additionalFeature[i]?.featureName,
                        amount: devicepricing?.additionalFeature[i]?.featureAmount,
                    };
                    additionalFeature.push(obj);
                }
            }
        }
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error block">{formik.errors[name]}</small>;
    };
    useEffect(() => {
        if (paymentInfo && paymentInfo) {
            setPaymentDialogVisibility(false);
            formik.setFieldValue("billId", paymentInfo.selectProduct);
            formik.setFieldValue("plan", paymentInfo.planId);
            formik.setFieldValue("additionalFeature", paymentInfo.additionalCharges);
            formik.setFieldValue("discount", paymentInfo.discount);
            formik.setFieldValue("totalamount", paymentInfo.totalAmount);
            formik.setFieldValue("paymentMode", paymentInfo.paymentMethod);
        }
    }, []);
    const Next = () => {
        setCurrentScreen(currentScreen + 1);
    };
    const Back = () => {
        setCurrentScreen(currentScreen - 1);
    };
    const onPlanSelect = (item) => {
        if (formik.values.plan === "") {
            let devicepricing = JSON.parse(localStorage.getItem("planprices"));
            for (let i = 0; i < devicepricing?.length; i++) {
                if (devicepricing[i]._id === item._id) {
                    formik.setFieldValue("totalamount", (parseFloat(formik.values.totalamount) + devicepricing[i].price).toString());
                    setPreviousPlanPrice(devicepricing[i].price);
                }
            }
            formik.setFieldValue("plan", item._id);
            //formik.handleChange(e);
        } else {
            let devicepricing = JSON.parse(localStorage.getItem("planprices"));

            for (let i = 0; i < devicepricing?.length; i++) {
                if (devicepricing[i]._id === item._id) {
                    let currentamount = parseFloat(formik.values.totalamount);
                    let currentafterremovingprevious = currentamount - previousPlanPrice;
                    formik.setFieldValue("totalamount", (currentafterremovingprevious + devicepricing[i].price).toString());
                    setPreviousPlanPrice(devicepricing[i].price);
                }
            }
            formik.setFieldValue("plan", item._id);
            //formik.handleChange(e);
        }

        localStorage.setItem("selfplanselect", item._id);

        setCurrentPlanSelect(item._id);
    };
    function onInventorySelect(item) {
        setCurrentPlanSelect("");
        setCurrentSelect(item.label);
        localStorage.setItem("product", item.label);

        setPreviousPlanPrice(0);
        if (checkinvenyory) {
            formik.values.totalamount = "0";
            formik.values.plan = "";
        } else {
            formik.setFieldValue("totalamount", 0);
            formik.setFieldValue("plan", "");
        }
        formik.setFieldValue("billId", item.value);
        let inventory;
        let inventoryType = JSON.parse(localStorage.getItem("inventoryType"));
        for (let i = 0; i < inventoryType?.length; i++) {
            if (item.value === inventoryType[i]?.value) {
                inventory = inventoryType[i].label;
                localStorage.setItem("product", item.label);
                break;
            }
        }
        setInventory(inventory);
        if (inventory === "SIM") {
            formik.setFieldValue("discount", JSON.parse(localStorage.getItem("simdiscountobjectarray")));
            let oneTimeCharge = parseFloat(JSON.parse(localStorage.getItem("simpricing")).oneTimeCharge);
            let amountafteradditionalfeature = parseFloat(JSON.parse(localStorage.getItem("simadditionaltotal")));

            formik.setFieldValue("plan", "");
            let amountafterdiscount = (oneTimeCharge + (amountafteradditionalfeature ? amountafteradditionalfeature : 0) - parseFloat(JSON.parse(localStorage.getItem("totalsimdiscount")))).toString();
            formik.setFieldValue("additional", JSON.parse(localStorage.getItem("simadditionalfeaturearraytotal"))?.length > 0 ? JSON.parse(localStorage.getItem("simadditionalfeaturearraytotal")) : []);
            formik.setFieldValue("discounts", JSON.parse(localStorage.getItem("simdiscountobjectarraytotal"))?.length > 0 ? JSON.parse(localStorage.getItem("simdiscountobjectarraytotal")) : []);
            if (checkinvenyory) {
                formik.values.totalamount = amountafterdiscount;
            } else {
                formik.setFieldValue("totalamount", amountafterdiscount);
            }
            setPreviousPlanPrice(0);
        } else if (inventory === "WIRELESS DEVICE") {
            formik.setFieldValue("plan", "");
            formik.setFieldValue("discount", JSON.parse(localStorage.getItem("devicediscountobjectarray")));
            formik.setFieldValue("additional", JSON.parse(localStorage.getItem("deviceadditionalfeaturearraytotal")));
            let oneTimeCharge = JSON.parse(localStorage.getItem("devicepricing")).oneTimeCharge;
            let amountafteradditionalfeature = parseFloat(JSON.parse(localStorage.getItem("deviceadditionaltotal")));
            let amountafterdiscount = (parseFloat(oneTimeCharge) + (amountafteradditionalfeature ? amountafteradditionalfeature : 0) - parseFloat(JSON.parse(localStorage.getItem("totaldevicediscount")))).toString();
            if (checkinvenyory) {
                formik.values.totalamount = amountafterdiscount;
            } else {
                formik.setFieldValue("totalamount", amountafterdiscount);
            }
            formik.setFieldValue("discounts", JSON.parse(localStorage.getItem("devicediscountobjectarraytotal"))?.length > 0 ? JSON.parse(localStorage.getItem("devicediscountobjectarraytotal")) : []);
            setPreviousPlanPrice(0);
        }
        localStorage.setItem("selfinventoryselect", item.value);

        if (checkplan) {
            let item2 = {};
            let id = checkplan?.data?.plan;
            item2._id = id;
            onPlanSelect(item2);
        }
    }

    const paymentstatus = localStorage.getItem("paymentstatus");
    const skippayment = localStorage.getItem("skippayment");
    let address = JSON.parse(localStorage.getItem("address"));

    useEffect(() => {
        if (carrierVal !== "") {
            const getServices = async () => {
                const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/listServices?carrierCode=${carrierVal}`);
                const data = response?.data?.data;
                setServices(data);
            };
            if (carrierVal !== undefined) {
                getServices();
                // setDeliveryVal("");
            }
        }
    }, [carrierVal]);

    useEffect(() => {
        const delivery = async () => {
            const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/getDeliveryConfirmation`);
            const data = response?.data;
            setDelivery(data);
        };
        delivery();
        const getCarrier = async () => {
            const response = await Axios.get(`${BASE_URL}/api/web/shipment/carrier/carriersList`);
            const data = response?.data?.data;

            setCarrier(data);
        };
        getCarrier();
        if (comingfromincomplete) {
            let postpaidcustomerdata = JSON.parse(localStorage.getItem("basicData"));
            setLabelCheck(postpaidcustomerdata?.data?.labelSubmissionval);
            setCarrierVal(postpaidcustomerdata?.data?.carrierVal);
            setServicesVal(postpaidcustomerdata?.data?.servicesVal);
            setChecked(postpaidcustomerdata?.data?.shippingLabelDiscount);
            setDeliveryVal(postpaidcustomerdata?.data?.deliveryVal);
        }
    }, []);

    useEffect(() => {
        if (carrierVal !== "" && servicesVal !== "" && deliveryVal !== "") {
            setRatesLoading(true);

            const dataToSend = {
                serviceprovider: basicData?.data?.serviceProvider,
                carrierCode: carrierVal,
                serviceCode: servicesVal,
                // toPostalCode: basicData?.data?.zip,
                toPostalCode: basicData?.data?.isSameServiceAddress ? basicData?.data?.zip : basicData?.data?.isNotSameServiceAddress ? basicData?.data?.mailingZip : basicData?.data?.isPoBoxAddress ? basicData?.data?.poBoxZip : "",

                toCountry: "US",
                // toState: basicData?.data?.state,
                // toCity: basicData?.data?.city,
                toState: basicData?.data ? basicData?.data?.state?.toUpperCase() : basicData?.data?.isNotSameServiceAddress ? basicData?.data?.mailingState?.toUpperCase() : basicData?.data?.isPoBoxAddress ? basicData?.data?.poBoxState?.toUpperCase() : "",
                toCity: basicData?.data ? basicData?.data?.city?.toUpperCase() : basicData?.data?.isNotSameServiceAddress ? basicData?.data?.mailingCity?.toUpperCase() : basicData?.data?.isPoBoxAddress ? basicData?.data?.poBoxCity?.toUpperCase() : "",

                packageCode: "",
                confirmation: deliveryVal,
                residential: "false",
                inventoryType: localStorage.getItem("product"),
            };

            const getRates = async () => {
                if (labelCheck) {
                    try {
                        const response = await Axios.post(`${BASE_URL}/api/web/shipment/getRates`, dataToSend);
                        if (response?.data?.msg && response?.data?.data === undefined) {
                            toast.error(response.data.msg);
                            setRatesLoading(false);
                            return;
                        }
                        const data = response?.data?.data;

                        if (Array.isArray(data) && data.length > 0) {
                            setRates(data);
                            setRatesLoading(false);
                        } else {
                            setRatesLoading(false);
                        }
                    } catch (error) {
                        toast.error("Rates Not Found");
                    }
                }
            };

            getRates();
        } else {
        }
    }, [carrierVal && servicesVal, deliveryVal]);
    useEffect(() => {
        if (!labelCheck) {
            setCarrierVal("");
            setServicesVal("");
            setDeliveryVal("");
            setChecked("");
            localStorage.removeItem("shipmentName");
            localStorage.removeItem("shipmentCost");
        }
        let data = localStorage.getItem("basicData");
        let parseData = JSON.parse(data);
        const dataToSend = {
            customerId: parseData?.data?._id,
            carrierVal: !labelCheck ? "" : carrierVal,
            servicesVal: !labelCheck ? "" : servicesVal,
            deliveryVal: !labelCheck ? "" : deliveryVal,
            labelSubmissionval: labelCheck,
            shippingLabelDiscount: checked,
        };

        Axios.post(`${BASE_URL}/api/user/storeServices`, dataToSend)
            .then((res) => { })
            .catch((err) => { });
    }, [carrierVal, servicesVal, deliveryVal, labelCheck, checked]);
    useEffect(() => {
        let add = localStorage.getItem("checkLabel");
        add = add === "true";
        let cost = 0;
        rates.map((item) => {
            if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                cost = item?.shipmentCost + item?.otherCost;
            }
        });
        let currentamount = parseFloat(formik.values.totalamount);
        if (add) {
            currentamount -= previousRate;
        } else {
            currentamount += cost;
        }

        formik.setFieldValue("totalamount", currentamount);
        setPreviousRate(cost);
    }, [checked, rates]);
    const parseVal = localStorage.getItem("carrierVal");
    const carrierVals = JSON.parse(parseVal);
    const formIncomplete = localStorage.getItem("formIncomplete");
    // if (formIncomplete === true) {
    //     localStorage.removeItem("product");
    //     localStorage.removeItem("skippayment");
    // }
    const agreeData = localStorage.getItem("agreeData");
    const agreeDataObj = JSON.parse(agreeData);

    return (
        <form onSubmit={formik.handleSubmit}>
            <ToastContainer />
            <Dialog className="stripe-dialog-width" header="Crad Payment" visible={dialogForCardAuth} onHide={() => setdialogForCardAuth(false)}>
                <CardAuthPayment amount={formik.values.totalamount} object={formik.values} handleNext={handleNext} />
            </Dialog>
            <Dialog className="stripe-dialog-width" header="E-Check Payment" visible={dialogForEcheck} onHide={() => setDialogForEcheck(false)}>
                <EcheckAuthPayment amount={formik.values.totalamount} object={formik.values} handleNext={handleNext} />
            </Dialog>

            {agreeDataObj?.data?.activeBillingConfiguration === undefined ? (
                <div className="flex flex-wrap justify-content-left">
                    {currentScreen === 0 ? (
                        <div className="mt-2 w-full flex flex-wrap flex-row justify-content-evenly">
                            <h1 className="block w-full selectProduct heading">Select Product</h1>

                            {JSON.parse(localStorage.getItem("inventoryType"))?.map((item) => {
                                return (
                                    <div style={{ opacity: `${item.label === current || inventoryVal ? "0.5" : ""}` }} className="inventorySelect">
                                        <div className="flex flex-wrap w-full  ">
                                            <RadioButton
                                                variant="filled"
                                                onClick={() => {
                                                    setNewInventorySelect(true);
                                                    onInventorySelect(item);
                                                    setActive(true);
                                                    setInventoryVal(true);
                                                    setPlanVal(false);
                                                }}
                                                disabled={item?.label === current || localStorage.getItem("paymentallinfoself") || paymentstatus === "paid" || skippayment === "true"}
                                                style={{ marginTop: "5px" }}
                                            />
                                            {/* <button
                                                type="button"
                                                disabled={item?.label === current || localStorage.getItem("paymentallinfoself")}
                                                onClick={() => {
                                                    setNewInventorySelect(true);
                                                    onInventorySelect(item);
                                                }}
                                            ></button> */}
                                            <h1 className="ml-8 heading" style={{ position: "absolute", marginTop: "-8px" }}>
                                                {item?.label}
                                            </h1>
                                        </div>
                                        <div className="mt-4">
                                            <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src={`./${item.label}.jpg`} />
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="flex flex-wrap justify-content-end w-full mt-4" style={{ height: "2rem", marginTop: "16rem", marginLeft: "5rem" }}>
                                <Button className="mr-2 btn" label="Back" type="button" onClick={handleBack} />
                                <Button className="btn" label="Continue" type="button" onClick={Next} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading || !inventoryVal} />
                            </div>
                        </div>
                    ) : undefined}
                    {currentScreen === 1 ? (
                        <div style={{ marginTop: "3.5rem" }} className=" w-full flex flex-wrap flex-row justify-content-evenly">
                            <h1 className="w-full selectplan "> SELECT PLAN</h1>
                            {JSON.parse(localStorage.getItem("planprices"))?.map((item) => {
                                let include = false;
                                const inventory = localStorage.getItem("product");
                                if (inventory === "SIM") {
                                    let plans = JSON.parse(localStorage.getItem("simplan"));
                                    for (let i = 0; i < plans.length; i++) {
                                        if (plans[i].value === item?._id) {
                                            include = true;
                                            break;
                                        }
                                    }
                                } else if (inventory === "WIRELESS DEVICE") {
                                    let plans = JSON.parse(localStorage.getItem("deviceplan"));
                                    for (let i = 0; i < plans.length; i++) {
                                        if (plans[i].value === item?._id) {
                                            include = true;
                                            break;
                                        }
                                    }
                                }
                                // const product = localStorage.getItem("product");
                                // if (product === "SIM") {
                                //     setPlanVal(prev=>!prev)
                                // } else if (product === "WIRELESS DEVICE") {
                                //     setPlanVal(prev=>!prev)
                                // }
                                return include ? (
                                    <>
                                        <div className="planSelect " style={{ opacity: `${item._id === currentPlanSelect || planVal || paymentstatus === "paid" || skippayment ? "0.5" : ""} ` }}>
                                            <div className="planinfo">
                                                <h1 style={{ marginLeft: "15px", marginTop: "10px" }}>{capitalizeSentence(item?.name)}</h1>
                                                <h1 style={{ marginLeft: "15px" }}>No Hidden Fees / No Contracts</h1>
                                                <h1 style={{ marginLeft: "15px" }} className="planprice">
                                                    <span style={{ fontFamily: "Inter", fontSize: "38px", fontWeight: "600", marginTop: "1px" }}>$</span>
                                                    <span style={{ marginLeft: "36px" }}>{item?.price}</span>
                                                    <span style={{ fontWeight: "600", fontFamily: "Inter", fontSize: "18px", marginLeft: "10px" }}>Monthly</span>
                                                </h1>
                                                <button
                                                    type="button"
                                                    disabled={item?._id === currentPlanSelect || paymentInfo}
                                                    onClick={() => {
                                                        onPlanSelect(item);
                                                        setActivePlan(true);
                                                        setPlanVal(true);
                                                    }}
                                                >
                                                    GET STARTED
                                                </button>
                                            </div>
                                            <p className="voiceallowance">
                                                <Tickbtn />
                                                <span style={{ marginLeft: "6px" }}>
                                                    Voice Allowance {item?.voiceAllowance} <span>{item.voiceAllowanceUnit}</span>
                                                </span>
                                            </p>
                                            <p className="dataallowance">
                                                <Tickbtn />
                                                <span style={{ marginLeft: "10px" }}>
                                                    Data Allowance {item?.dataAllowance} <span>{item.dataAllowanceUnit}</span>
                                                </span>
                                            </p>
                                            <p className="textallowance">
                                                <Tickbtn />
                                                <span style={{ marginLeft: "10px" }}>
                                                    Text Allowance {item?.textAllowance} <span>{item.textAllowanceUnit}</span>
                                                </span>
                                            </p>
                                        </div>
                                    </>
                                ) : undefined;
                            })}
                            <div className="flex flex-wrap justify-content-end w-full mt-4">
                                <Button className="mr-2 btn" label="Back" type="button" onClick={Back} />
                                <Button
                                    className="btn"
                                    label="Continue"
                                    type="button"
                                    onClick={() => {
                                        Next();
                                        localStorage.setItem("setplan", true);
                                    }}
                                    icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                                    disabled={isLoading || (!planVal && paymentstatus !== "paid")}
                                />
                            </div>
                        </div>
                    ) : undefined}
                    {currentScreen === 2 ? (
                        <div className="flex flex-wrap w-full justify-content-left">
                            <div className="flex flex-wrap w-full calendar_field">
                                <Checkbox checked={labelCheck} onChange={(e) => setLabelCheck(e.checked)} />
                                <p className="filed_label">&nbsp;&nbsp; Generate Label on Submission</p>
                            </div>

                            <div className="calendar_field">
                                <p className="field_label">
                                    Carrier <span style={{ color: "red" }}>*</span>
                                </p>
                                <Dropdown disabled={!labelCheck} value={carrierVal} id="name" onChange={(e) => setCarrierVal(e.value)} options={carrier} optionLabel="name" placeholder="Select Carrier" optionValue="code" className="w-full " />

                                {/* <Dropdown
                                    value={carrierVal.name}
                                    id="name"
                                    disabled={!labelCheck}
                                    onChange={(e) => {
                                        setCarrierVal({ name: e.value, code: e.value.code });
                                        localStorage.setItem("carrierVal", JSON.stringify(e.value));
                                    }}
                                    options={carrier}
                                    optionLabel="name"
                                    placeholder="Select Carrier"
                                    className="w-full "
                                /> */}
                            </div>
                            <div className="calendar_field">
                                <p className="field_label ml-2">
                                    Services <span style={{ color: "red" }}>*</span>
                                </p>

                                {/* <Dropdown
                                    value={servicesVal.name}
                                    id="name"
                                    disabled={!labelCheck}
                                    onChange={(e) => {
                                        setServicesVal({ name: e.value, code: e.value.code });
                                    }}
                                    options={services}
                                    optionLabel="name"
                                    placeholder="Select Service"
                                    className="w-full ml-2"
                                /> */}
                                <Dropdown
                                    value={servicesVal}
                                    id="name"
                                    disabled={!labelCheck}
                                    onChange={(e) => {
                                        setServicesVal(e.value);
                                    }}
                                    options={services}
                                    optionLabel="name"
                                    optionValue="code"
                                    placeholder="Select Service"
                                    className="w-full ml-2"
                                />
                            </div>
                            <div className="calendar_field">
                                <p className="field_label ,l-3">
                                    Delivery Confirmation <span style={{ color: "red" }}>*</span>
                                </p>

                                {/* {carrierVal.name === "FedEx" ? (
                                    <Dropdown value={deliveryVal} disabled={!labelCheck} onChange={(e) => setDeliveryVal(e.value)} options={deliveryFedEx} optionLabel="name" placeholder="Select Delivery Option" className="w-full ml-3 " />
                                ) : (
                                    <Dropdown value={deliveryVal} disabled={!labelCheck} onChange={(e) => setDeliveryVal(e.value)} options={delivery} optionLabel="type" placeholder="Select Delivery Option" className="w-full ml-3" />
                                )} */}
                                {carrierVal.name === "FedEx" ? (
                                    <Dropdown disabled={!labelCheck} value={deliveryVal} onChange={(e) => setDeliveryVal(e.value)} options={deliveryFedEx} optionLabel="name" optionValue="name" placeholder="Select Delivery Option" className="w-full ml-3 " />
                                ) : (
                                    <Dropdown disabled={!labelCheck} value={deliveryVal} onChange={(e) => setDeliveryVal(e.value)} options={delivery} optionLabel="type" optionValue="type" placeholder="Select Delivery Option" className="w-full ml-3" />
                                )}
                            </div>
                            <div className="calendar_field">
                                <p className="field_label mt-4">
                                    Expected Cost :
                                    <span>
                                        {ratesLoading ? (
                                            <span>
                                                &nbsp; <i className="pi pi-spin pi-spinner" style={{ color: "black" }}></i>
                                            </span>
                                        ) : (
                                            rates?.map((item) => {
                                                try {
                                                    if (item?.serviceCode === servicesVal && item?.serviceName.includes("- Package")) {
                                                        let rate = item?.shipmentCost + item?.otherCost;
                                                        rate = rate?.toFixed(2);
                                                    
                                                        localStorage.setItem("shipmentCost", rate);
                                                        localStorage.setItem("shipmentName", item?.serviceName);

                                                        return <span key={item._id}> $ {rate}</span>;
                                                    }
                                                } catch (e) {
                                                  
                                                }
                                            })
                                        )}
                                    </span>
                                </p>
                            </div>
                            <div className="flex flex-wrap w-full calendar_field">
                                <Checkbox disabled={!labelCheck} checked={checked} onChange={(e) => setChecked(e.checked)} />
                                <p style={{ opacity: !labelCheck ? "0.5" : "" }} className="filed_label">
                                    &nbsp;&nbsp; Add Shipping Label as Discount
                                </p>
                            </div>
                            <div className="flex flex-wrap justify-content-end w-full mt-4">
                                <Button className="mr-2 btn" label="Back" type="button" onClick={Back} />
                                <Button
                                    className="btn"
                                    label="Continue"
                                    type="button"
                                    onClick={() => {
                                        Next();
                                        localStorage.setItem("setplan", true);
                                    }}
                                    icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                                    disabled={isLoading || (carrierVal === "" && labelCheck) || (servicesVal === "" && labelCheck) || (deliveryVal === "" && labelCheck)}
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {currentScreen === 3 ? (
                        <div style={{gap:"4px"}} className="postpaid-payment flex flex-wrap justify-content-left w-full">
                            <div className="calendar_field ">
                                <label className="field_label mb-2">Select Additional Feature</label>
                                {inventory === "SIM" ? (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2"
                                            id="additional"
                                            placeholder="Select Additional Feature"
                                            optionLabel="name"
                                            options={JSON.parse(localStorage.getItem("simadditional"))}
                                            value={formik.values.additional}
                                            onChange={(e) => {
                                                setActivePlan(true);
                                                let additional = formik.values.additional;
                                                let prerviousadditionaltotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let simpricing = JSON.parse(localStorage.getItem("simpricing"));
                                                for (let i = 0; i < additional?.length; i++) {
                                                    for (let k = 0; k < simpricing.additionalFeature?.length; k++) {
                                                        if (simpricing.additionalFeature[k]._id === additional[i]) {
                                                            prerviousadditionaltotal += parseFloat(simpricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount -= prerviousadditionaltotal;
                                                let additionalnew = e.value;
                                                let newadditionaltotal = 0;
                                                for (let i = 0; i < additionalnew?.length; i++) {
                                                    for (let k = 0; k < simpricing.additionalFeature?.length; k++) {
                                                        if (simpricing.additionalFeature[k]._id === additionalnew[i]) {
                                                            newadditionaltotal += parseFloat(simpricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount += newadditionaltotal;
                                                localStorage.setItem("simadditionalfeaturearray", JSON.stringify(e.value));
                                                formik.setFieldValue("additional", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("additional")}
                                    </>
                                ) : (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2"
                                            placeholder="Select Additional Feature"
                                            id="additional"
                                            optionLabel="name"
                                            options={JSON.parse(localStorage.getItem("deviceadditional"))}
                                            value={formik.values.additional}
                                            onChange={(e) => {
                                                setActivePlan(true);
                                                let additional = formik.values.additional;
                                                let prerviousadditionaltotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let devicepricing = JSON.parse(localStorage.getItem("devicepricing"));
                                                for (let i = 0; i < additional?.length; i++) {
                                                    for (let k = 0; k < devicepricing.additionalFeature?.length; k++) {
                                                        if (devicepricing.additionalFeature[k]._id === additional[i]) {
                                                            prerviousadditionaltotal += parseFloat(devicepricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount -= prerviousadditionaltotal;
                                                let additionalnew = e.value;
                                                let newadditionaltotal = 0;
                                                for (let i = 0; i < additionalnew?.length; i++) {
                                                    for (let k = 0; k < devicepricing.additionalFeature?.length; k++) {
                                                        if (devicepricing.additionalFeature[k]._id === additionalnew[i]) {
                                                            newadditionaltotal += parseFloat(devicepricing.additionalFeature[k].featureAmount);
                                                        }
                                                    }
                                                }
                                                totalamount += newadditionaltotal;
                                                localStorage.setItem("deviceadditionalfeaturearray", JSON.stringify(e.value));
                                                formik.setFieldValue("additional", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("additional")}
                                    </>
                                )}
                            </div>

                            <div className="calendar_field">
                                <label className="field_label mb-2 ">Net Amount</label>
                                <InputText
                                    disabled
                                    className="w-full  mt-2"
                                    id="totalamount"
                                    value={parseFloat(formik.values.totalamount).toFixed(2)}
                                    onChange={(e) => {
                                        formik.setFieldValue("totalpayment", e.value);
                                        formik.handleChange(e);
                                    }}
                                />
                                {getFormErrorMessage("totalpayment")}
                            </div>
                            {(formik.values.paymentMode === "Credit Card") ? (
                                <div className="calendar_field">
                                    <label className="field_label  mb-2">Paying Amount</label>
                                    <InputText
                                        className="w-full  mt-2 "
                                        type="number"
                                        id="paid"
                                        value={formik.values.paid}
                                        onChange={(e) => {
                                            if (e.target.value === "") {
                                                setPaidAmountRequired(true);
                                            } else {
                                                setPaidAmountRequired(false);
                                            }
                                            formik.setFieldValue("paid", e.target.value);
                                        }}
                                    />
                                    {paidAmountRequired ? <p className="p-error mt-1 ml-1">Paying Amount Is Required</p> : ""}
                                    {getFormErrorMessage("paid")}
                                </div>
                            ) : undefined}
                            <div className="calendar_field">
                                <label className="field_label mb-2 ">Select Payment Method</label>
                                {inventory === "SIM" ? (
                                    <Dropdown
                                        disabled={paymentInfo ? true : false}
                                        className="w-full mt-2 "
                                        id="paymentMode"
                                        options={JSON.parse(localStorage.getItem("simPaymentMethod"))}
                                        value={formik.values.paymentMode}
                                        onChange={(e) => {
                                            formik.setFieldValue("paymentMode", e.value);
                                            formik.handleChange(e);
                                        }}
                                        optionValue="name"
                                        optionLabel="name"
                                        filter
                                        showClear
                                        filterBy="name"
                                    />
                                ) : inventory === "WIRELESS DEVICE" ? (
                                    <Dropdown
                                        disabled={paymentInfo ? true : false}
                                        className="w-full mt-2 "
                                        id="paymentMode"
                                        options={JSON.parse(localStorage.getItem("devicePaymentMethod"))}
                                        value={formik.values.paymentMode}
                                        onChange={(e) => {
                                            formik.setFieldValue("paymentMode", e.value);
                                            formik.handleChange(e);
                                        }}
                                        optionValue="name"
                                        optionLabel="name"
                                        filter
                                        showClear
                                        filterBy="name"
                                    />
                                ) : (
                                    <Dropdown disabled={paymentInfo ? true : false} className="w-full mt-2 " id="paymentMode" optionValue="name" optionLabel="name" filter showClear filterBy="name" />
                                )}

                                <div className="ml-4">{getFormErrorMessage("paymentMode")}</div>
                            </div>
                            <div className="  calendar_field">
                                <label className="field_label mb-2">Select Discounts</label>
                                {inventory === "SIM" ? (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2"
                                            id="discount"
                                            placeholder="Select Discounts"
                                            optionLabel="discountname"
                                            optionValue="_id"
                                            options={simdiscount}
                                            value={formik.values.discounts}
                                            onChange={(e) => {
                                                let discount = formik.values.discounts;
                                                let prerviousdiscounttotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let discountpricing = simdiscount;
                                                for (let i = 0; i < discount.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discount[i] === discountpricing[k]._id) {
                                                            prerviousdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount += prerviousdiscounttotal;
                                                let discountnew = e.value;
                                                let newdiscounttotal = 0;
                                                for (let i = 0; i < discountnew.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discountnew[i] === discountpricing[k]._id) {
                                                            newdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount -= newdiscounttotal;
                                                localStorage.setItem("simdiscountobjectarray", JSON.stringify(e.value));
                                                formik.setFieldValue("discounts", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("discounts")}
                                    </>
                                ) : (
                                    <>
                                        <MultiSelect
                                            disabled={paymentInfo ? true : false}
                                            className="w-full mt-2"
                                            placeholder="Select Discounts"
                                            id="discount"
                                            optionLabel="discountname"
                                            optionValue="_id"
                                            options={devicediscount}
                                            value={formik.values.discounts}
                                            onChange={(e) => {
                                                let discount = formik.values.discounts;
                                                let prerviousdiscounttotal = 0;
                                                let totalamount = parseFloat(formik.values.totalamount);
                                                let discountpricing = devicediscount;
                                                for (let i = 0; i < discount.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discount[i] === discountpricing[k]._id) {
                                                            prerviousdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount += prerviousdiscounttotal;
                                                let discountnew = e.value;
                                                let newdiscounttotal = 0;
                                                for (let i = 0; i < discountnew.length; i++) {
                                                    for (let k = 0; k < discountpricing.length; k++) {
                                                        if (discountnew[i] === discountpricing[k]._id) {
                                                            newdiscounttotal += parseFloat(discountpricing[k].amount);
                                                        }
                                                    }
                                                }
                                                totalamount -= newdiscounttotal;
                                                localStorage.setItem("devicediscountobjectarray", JSON.stringify(e.value));
                                                formik.setFieldValue("discounts", e.value);
                                                formik.setFieldValue("totalamount", totalamount.toString());
                                            }}
                                        />
                                        {getFormErrorMessage("discounts")}
                                    </>
                                )}
                            </div>
                            <Dialog className="stripe-dialog-width" header="Stripe Payment" visible={paymentDialogVisibility} onHide={() => setPaymentDialogVisibility(false)}>
                                <PaymentStripModule amount={formik.values.totalamount} paid={formik.values.paid} object={formik.values} handleNext={handleNext} />
                            </Dialog>
                            <div className=" flex flex-wrap mt-6 w-full">
                                {labelCheck && !checked ? <p style={{ fontWeight: "500" }}>The cost of shipment has been added to the net payment and is payable by the customer.</p> : ""}
                                {labelCheck && checked ? <p style={{ fontWeight: "500" }}>The cost of shipment has not been added to the net payment. Shipment cost will be covered by the company</p> : ""}
                            </div>
                            <div className="flex flex-wrap justify-content-end w-full mt-8">
                                <div>
                                    <Button
                                        className="mr-2 btn"
                                        label="Back"
                                        type="button"
                                        onClick={() => {
                                            Back();
                                        }}
                                    />
                                    <Button
                                        className="btn"
                                        label="Continue"
                                        type="submit"
                                        icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                                        onClick={() => {
                                            if (currentScreen !== 3) {
                                                setCurrentScreen((prev) => (prev = prev + 1));
                                            } else {
                                                formik.handleSubmit();
                                            }
                                        }}
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            {/* <div>
                                <Button className="mr-2 btn" label="Back" type="button" onClick={handleBack} />
                                <Button className="btn" label="Continue" type="submit" icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                            </div> */}
                        </div>
                    ) : undefined}
                </div>
            ) : (
                handleNext()
            )}
        </form>
    );
};

export default Agree;
