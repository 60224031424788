const csrequirmentphonetype=[{ 
  label:"Y", 
  value:"Yes"
},{ 
    label:"N", 
    value:"No"
}]    
const status=[{ 
      label:"Active", 
      value:"Active"
},{ 
    label:"InActive", 
    value:"InActive"
}]  
export {csrequirmentphonetype,status}