// import React, { useState } from "react";
// import Agree from "../Eligibility_com/Agree";
// import Select from "../Eligibility_com/Select";
// const Eligibility = ({ setActiveIndex, enrollment_id, _id, csr }) => {
//     const [currentComponent, setCurrentComponent] = useState(1);

//     const zipRes = localStorage.getItem("zipData");

//     const handleNext = () => {
//       if(zipRes){
//           if (currentComponent < 2) {
//               setCurrentComponent((prev) => {
//                   return prev + 1;
//               });
//           } else {
//               setActiveIndex(2);
//           }
//       }else{
//               setActiveIndex(2);
//       }

//   };

//     const handleBack = () => {
//         if(zipRes){
//             if (currentComponent >= 2) {
//                 setCurrentComponent((prev) => {
//                     return prev - 1;
//                 });
//             } else {
//                 setActiveIndex(0);
//             }
//         }else{
//                 setActiveIndex(0);
//         }

//     };

//     let render;

//   switch (currentComponent) {
//     case 1:
//       render = zipRes ? (
//         <Select
//           handleNext={handleNext}
//           handleBack={handleBack}
//           enrollment_id={enrollment_id}
//           _id={_id}
//           csr={csr}
//         />
//       ) : (
//         <Select
//           handleNext={handleNext}
//           handleBack={handleBack}
//           enrollment_id={enrollment_id}
//           _id={_id}
//           csr={csr}
//         />
//       );
//       break;
//     case 2:
//       render = zipRes ? (
//         <Agree
//           handleNext={handleNext}
//           handleBack={handleBack}
//           enrollment_id={enrollment_id}
//           _id={_id}
//           csr={csr}
//         />
//       ) : null;
//       break;
//     default:
//       render = null;
//   }
//     return (
//         <>
//             <div className="card">
//                 <br></br>
//                 {render}
//             </div>
//         </>
//     );
// };

// export default Eligibility;
import React, { useState } from "react";
import Agree from "../Eligibility_com/Agree";
import Select from "../Eligibility_com/Select";

const Eligibility = ({ setActiveIndex, enrollment_id, _id, csr, currentScreen, setCurrentScreen, planVal, setPlanVal, inventoryVal, setInventoryVal, carrierVal, setCarrierVal, servicesVal, setServicesVal, deliveryVal, setDeliveryVal, checked, setChecked, labelCheck, setLabelCheck }) => {
    const [currentComponent, setCurrentComponent] = useState(1);
    const handleNext = () => {
        if (currentComponent < 2) {
            setCurrentComponent((prev) => {
                return prev + 1;
            });
        } else {
            setActiveIndex(2);
        }
    };

    const handleBack = () => {
        if (currentComponent >= 2) {
            setCurrentComponent((prev) => {
                return prev - 1;
            });
        } else {
            setActiveIndex(0);
        }
    };

    let render;

    switch (currentComponent) {
        case 1:
            render = (
                <Select
                    handleNext={handleNext}
                    handleBack={handleBack}
                    enrollment_id={enrollment_id}
                    _id={_id}
                    csr={csr}
                    carrierVal={carrierVal}
                    setCarrierVal={setCarrierVal}
                    servicesVal={servicesVal}
                    setServicesVal={setServicesVal}
                    deliveryVal={deliveryVal}
                    setDeliveryVal={setDeliveryVal}
                    checked={checked}
                    setChecked={setChecked}
                    labelCheck={labelCheck}
                    setLabelCheck={setLabelCheck}
                />
            );
            break;
        case 2:
            render = (
                <Agree
                    handleNext={handleNext}
                    handleBack={handleBack}
                    enrollment_id={enrollment_id}
                    _id={_id}
                    csr={csr}
                    planVal={planVal}
                    setPlanVal={setPlanVal}
                    inventoryVal={inventoryVal}
                    setInventoryVal={setInventoryVal}
                    currentScreen={currentScreen}
                    setCurrentScreen={setCurrentScreen}
                    carrierVal={carrierVal}
                    setCarrierVal={setCarrierVal}
                    servicesVal={servicesVal}
                    setServicesVal={setServicesVal}
                    deliveryVal={deliveryVal}
                    setDeliveryVal={setDeliveryVal}
                    checked={checked}
                    setChecked={setChecked}
                    labelCheck={labelCheck}
                    setLabelCheck={setLabelCheck}
                />
            );
            break;
        default:
            render = null;
    }

    return (
        <>
            <div className="card">
                <br></br>
                {render}
            </div>
        </>
    );
};

export default Eligibility;
