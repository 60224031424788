import React, { useState,useRef } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { Toast } from "primereact/toast";  
import { squareErrorMessages } from "../../../../../../../utility";
import CommonLoader from "./components/Loader/Loader";
export default function Square({ 
  setPayinvoicesVisible, setShowStripe, setRefresh, cpData, totalAmount, selectedInvoices 
}){             
    const BASE_URL = process.env.REACT_APP_BASE_URL;         
    const [isLoading,setIsLoading]=useState(false)
    const loginRes=JSON.parse(localStorage.getItem("userData"))
    const [card,setcard]=useState()         
    const [buttonDisabled,setButtonDisabled]=useState(true)        
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const toast = useRef(null);
    async function initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container'); 
        return card; 
      }      
      async function handlePaymentMethodSubmission(event, paymentMethod) {
        event.preventDefault();
         let paymentResults;
        try {
          // disable the submit button as we await tokenization and make a
          // payment request.
           
          const token = await tokenize(paymentMethod);     
          console.log("token is",token)
           paymentResults = await createPayment(token);
            
          displayPaymentResults('SUCCESS');
     
          console.log('Payment Success', paymentResults.payment?.id);        
            
          toast.current.show({ severity: "success", summary: "Payment Processed", detail: <p>Payment Proceed Successfully</p> });
        
          const idArray = selectedInvoices.map((obj) => obj._id);
          const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);
          toast.current.show({ severity: "success", summary: "Payment Proceeding", detail: "Payment Proceed Successfully" });

          Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
              customerId: cpData?._id,
              invoiceNo: idArray,
              invoiceType: invoiceTypeArray,
              paymentMethod: "Credit Card",
              stripeId: paymentResults?.id,
              userId: loginRes?._id,
              action: "success",
              reason: "success",
              transactionStatus: paymentResults?.status,
              paymentStatus: "success",
              amount: totalAmount,
          })
              .then((res) => {
                  // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
              })
              .catch((err) => {
                  ////toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
              });

          Axios.put(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/updateInvoice`, {
              invoices: idArray,
              amountPaid: totalAmount,
              invoicePaymentMethod: "Credit Card",
              paymentId: paymentResults?.id,
              customerId: cpData?._id,
              userId: loginRes?._id,
              isWallet: false,
          })
              .then(() => {
                  // toast.current.show({ severity: "success", summary: "Invoices Updation", detail: "Invoices Updated Successfully" });

                  setTimeout(() => {
                      setRefresh((prev) => !prev);
                      // setPayinvoicesVisible(false)
                       setPayinvoicesVisible(false);
                  }, 500);
                  //setRefresh(prev=>!prev)
              })
              .catch((err) => {
                  //toast.current.show({ severity: "error", summary: "Invoices Updation", detail: "Invoice Updation Failed" });
                  setTimeout(() => {
                       setPayinvoicesVisible(false);
                  }, 2000);
              });
   
   
                        
                            
                         
        
          

        } catch (error) {      
          console.log(error) 
          console.log(paymentResults)
          const errorCode = error[0]?.type;   
              console.log(errorCode)
          const errorMessage = squareErrorMessages[errorCode] || squareErrorMessages['GENERIC_ERROR'];
        
          toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{errorMessage}</p>, life: 2000 });

          const idArray = selectedInvoices.map((obj) => obj._id);
          const invoiceTypeArray = selectedInvoices.map((data) => data.invoiceType);
          Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
              customerId: cpData?._id,
              invoiceNo: idArray,
              invoiceType: invoiceTypeArray,
              paymentMethod: "Credit Card",
              stripeId: "",
              action: "failure",
              cardNumber: "",
              reason: "failure",
              transactionStatus: "",
              paymentStatus: "failure",
              userId: loginRes?._id,
              amount: totalAmount
          })
              .then((res) => {
                  setTimeout(() => {
                      setRefresh();
                       setPayinvoicesVisible(false);
                  }, 1500);
                  // toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
              })
              .catch((err) => {
                  //toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
              });
      
         }
      }    
       // Call this function to send a payment token, buyer name, and other details
 // to the project server code so that a payment can be created with 
 // Payments API
 async function createPayment(token) {
    const body = JSON.stringify({
  
      amount:(Math.round(parseFloat(totalAmount) * 100)).toString(), 
      token:token,
    });
    const paymentResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/web/invoices/sq-process-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });
    if (paymentResponse.ok) {
      return paymentResponse.json();         
    }  
    const errorBody = await paymentResponse.text();
    throw new Error(errorBody);
  }
 
  // This function tokenizes a payment method. 
  // The ‘error’ thrown from this async function denotes a failed tokenization,
  // which is due to buyer error (such as an expired card). It's up to the
  // developer to handle the error and provide the buyer the chance to fix
  // their mistakes.
  async function tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
    
    }
  }
 
  // Helper method for displaying the Payment Status on the screen.
  // status is either SUCCESS or FAILURE;
  function displayPaymentResults(status) {
    const statusContainer = document.getElementById(
      'payment-status-container'
    );
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }
 
    statusContainer.style.visibility = 'visible';
  }    
 
      useEffect(()=>{ 
      async function Loader(){  if (!window.Square) {
            throw new Error('Square.js failed to load properly');
          }
          const payments = window.Square.payments(process.env.REACT_APP_SQUARE_APPLICATION_ID,process.env.REACT_APP_SQUARE_LOCATION_ID);
          
          try {
            setcard(await initializeCard(payments))  
             setButtonDisabled(false)
          } catch (e) {
            console.error('Initializing Card failed', e);
            return;
          } 
        } 
        Loader()             
        return () => {
          if (card) {
            card.detach();
          }
        };
      },[])
     
     return( 
           <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">    
                  <div       
 style={{width:"100%",overflow:"hidden"}}
                   
                   >
            <form  id="payment-form">
   <div id="card-container"></div> 
    <div className="flex flex-wrap flex-row justify-content-center">
   <button id="card-button" style={{background:"royalblue",cursor:"pointer",border:"none",padding:"8px",borderRadius:"4px",color:"white",paddingLeft:"10px",paddingRight:"10px",fontSize:"17px"}}  onClick={(e)=>{ 
    handlePaymentMethodSubmission(e,card)    
    setIsLoading(true)
   }}type="button"  disabled={buttonDisabled || isLoading}>{isLoading ? <CommonLoader/> :  `Pay ${totalAmount}`}</button>   
   </div>
 </form>      
 </div>
 <div id="payment-status-container"></div>     
 <Toast ref={toast} />
           </div>
     )
}