import React, { useRef, useState } from "react";
import "../All-Customers/AllCustomers.css";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Calendar, DownloadIcon, Search, SelectorIcon, Status } from "../../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
import { useFormik } from "formik";
import Calendardropdown from "./Calendar/Calendardropdown";
const AllCustomers = () => {
    const [value, setValue] = useState("");
    const op = useRef(null);
    const stat = useRef(null);
    const calendar = useRef(null);
    const formik = useFormik({
        initialValues: {
            prepaid: false,
            postpaid: false,
            acp: false,
            active: false,
            prospect: false,
            suspended: false,
            disconnected: false,
            cancelled: false,
            all: false,
        },
        onSubmit: (values) => {
            console.log(values);
        },
    });
    const handleAllChange = (checked) => {
        formik.setValues(() => ({
            all: checked,
            active: checked,
            prospect: checked,
            suspended: checked,
            disconnected: checked,
            cancelled: checked,
        }));
    };

    const handleIndividualChange = (field, checked) => {
        formik.setFieldValue(field, checked);
        if (!checked) {
            formik.setFieldValue("all", false);
        } else {
            const allChecked = ["active", "prospect", "suspended", "disconnected", "cancelled"].every((key) => (key === field ? checked : formik.values[key]));
            if (allChecked) {
                formik.setFieldValue("all", true);
            }
        }
    };
    return (
        <div className="allcustomersmain">
            <div className="flex w-full justify-content-between align-items-center nav">
                <div className="flex align-items-center">
                    <h1 style={{ marginTop: "10px" }}>Customer Profile</h1>
                </div>
                <div className="flex lign-items-center" style={{ gap: "20px", height: "40px" }}>
                    <div className="inputouter">
                        <span>
                            <Search />
                        </span>
                        <InputText className="input" type="search" placeholder="Search Here" value={value} onChange={(e) => setValue(e.target.value)} />
                    </div>
                    <div>
                        <button className="calendar" onClick={(e) => calendar.current.toggle(e)}>
                            <Calendar />
                        </button>
                        <OverlayPanel ref={calendar} className="calendaroverlay">
                            <Calendardropdown />
                        </OverlayPanel>
                    </div>
                    <div>
                        <button className="selector" onClick={(e) => op.current.toggle(e)}>
                            <SelectorIcon />
                        </button>
                        <OverlayPanel ref={op}>
                            <div className="flex flex-column w-full overlay">
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("prepaid", e.target.checked)} checked={formik.values.prepaid} />
                                    <label>Prepaid</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("postpaid", e.target.checked)} checked={formik.values.postpaid} />
                                    <label>Postpaid</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("acp", e.target.checked)} checked={formik.values.acp} />
                                    <label>ACP</label>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                    <div>
                        <button className="statusselect" onClick={(e) => stat.current.toggle(e)}>
                            <Status />
                        </button>
                        <OverlayPanel ref={stat} className="overlaystatus">
                            <div className="flex flex-column w-full ">
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="active" name="active" className="check" type="checkbox" onChange={(e) => handleIndividualChange("active", e.target.checked)} checked={formik.values.active} />
                                    <label>Active</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="prospect" name="prospect" className="check" type="checkbox" onChange={(e) => handleIndividualChange("prospect", e.target.checked)} checked={formik.values.prospect} />
                                    <label>Prospect</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="suspended" name="suspended" className="check" type="checkbox" onChange={(e) => handleIndividualChange("suspended", e.target.checked)} checked={formik.values.suspended} />
                                    <label>Suspended</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="disconnected" name="disconnected" className="check" type="checkbox" onChange={(e) => handleIndividualChange("disconnected", e.target.checked)} checked={formik.values.disconnected} />
                                    <label>Disconnected</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="cancelled" name="cancelled" className="check" type="checkbox" onChange={(e) => handleIndividualChange("cancelled", e.target.checked)} checked={formik.values.cancelled} />
                                    <label>Cancelled</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="all" name="all" className="check" type="checkbox" onChange={(e) => handleAllChange(e.target.checked)} checked={formik.values.all} />
                                    <label>ALL</label>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                    <button className="export flex justify-content-evenly align-items-center">
                        <p className="flex justify-content-between">
                            Export
                            <span>
                                <DownloadIcon />
                            </span>
                        </p>
                    </button>
                </div>
            </div>
            <div className="flex flex-wrap justify-content-between" style={{ gap: "10px", marginTop: "30px" }}>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
                <div className="cust flex justify-content-evenly align-items-center">
                    <div className="flex flex-column justify-content-between align-items-start" style={{ width: "30%", height: "72px" }}>
                        <button className="custbtn">JW</button>
                        <p>Name</p>
                    </div>
                    <div className="flex flex-column justify-content-evenly align-items-start pl-3" style={{ width: "50%", height: "68px", borderLeft: "1px solid #EFF2F8" }}>
                        <div className="flex flex-column justify-content-between" style={{ height: "66px" }}>
                            <h1>Active</h1>
                            <label>ETC1212212</label>
                            <h6>(555) 555-1234</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllCustomers;
