import React, { useEffect, useState } from "react" 
import { DataTable } from "primereact/datatable" 
 import { Column } from "primereact/column"
 import Axios from "axios"
 import { useNavigate } from "react-router-dom" 
 
import { Card } from "primereact/card"
export default function LinkedAccounts(){ 
    let navigate=useNavigate()  
     const [childAccount,setChildAccount]=useState([]) 
     useEffect(()=>{ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/getAllchildaccounts?parentid=${JSON.parse(localStorage.getItem("selectedId"))}`).then(res=>{ 
    setChildAccount(res?.data)
        }).catch(err=>{ 

        })
     },[])         
     const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };
     return(    <div>   
         <Card>
          <DataTable
                            value={ childAccount}
                            size="small"
                            stripedRows
                            resizableColumns
                            paginator
                            rows={10}
                            rowsPerPageOptions={[25, 50]}
                            emptyMessage="No Child Account found.">
                           

                            <Column header="Enrollment ID" field="enrollmentId"   
                               
                                body={(rowData) => (
                                    <button style={{ border: "none", backgroundColor: "white", cursor: "pointer" }} onClick={() => handleEnrollmentIdClick(rowData)}>
                                        {rowData.enrollmentId}
                                    </button>
                                )}
                            
                              ></Column>   
                            

                            <Column header="Name" field="enrollmentId" body={(rowData)=>{ 
                                  return( 
                                    <p>{rowData?.firstName ? rowData.firstName :""} {rowData?.middleName ? rowData.middleName :""} {rowData?.lastName ? rowData.lastName :""}</p>
                                  )
                            }}></Column>  
                            <Column header="Contact" field="contact" />  
                            
                            <Column header="Address" field="address1" />  
                            
                            <Column header="Product" field="product" body={(rowData)=>{ 
                                return(   
                                   <p>{rowData?.activeBillingConfiguration?.monthlyCharge[0]?.inventoryType}</p>
                                )
                            }} /> 
                             
                            <Column header="Parent Relation" field="relationWithParent" /> 
                            <Column header="Billing Link With Parent" body={(rowData)=>{
                               return( 
                                <p>{rowData?.currentPlan?.isGenerateInvoiceWithParent ? "Yes":rowData?.currentPlan?.isGenerateInvoiceWithParent === false ? "No":"Payment Skipped"}</p>
                               )
                            }}/>
                               
                        </DataTable>   
                        </Card>

     </div>
     )
}