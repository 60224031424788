import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import CommonLoaderBlue from "../../../../billing_and_invoices/components/PayInvoices/Loaders/Loader/LoaderBlue";
import classNames from "classnames";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import "./Address.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Address = ({
    initiationData,
    setParentDetails,
    zipVerified,
    parentDetails,
    setAddressActive,
    setPersonalInfoActive,
    setConfirmationActive,
    setPaymentScreenActive,
    validateAddress,
    setValidateAddress,
    storeValidAddress,
    setStoreValidAddress,
    serviceAddress1,
    setServiceAddress1,
    serviceAddress2,
    setServiceAddress2,
    mailingAddress1,
    setMailingAddress1,
    mailingAddress2,
    setMailingAddress2,
    poBoxAddress,
    setPoBoxAddress,
    incomplete,
    setProductActive,
}) => {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const _id = initiationData?._id;
    const csr = parseLoginRes?._id;
    const [confrimAddress, setConfrimAddress] = useState("same");
    const [isSame, setIsSame] = useState(parentDetails?.isSameServiceAddress);
    const [isDifferent, setIsDifferent] = useState(parentDetails?.isNotSameServiceAddress);
    const [isMailingZip, setIsMailingZip] = useState(parentDetails?.mailingZip !== "" ? parentDetails?.mailingZip : "");
    const [ismailingaddress, setIsMailingAddress] = useState(parentDetails?.mailingAddress1 !== "" ? parentDetails?.mailingAddress1 : "");
    const [isPoBox, setIsPoBox] = useState(parentDetails?.isPoBoxAddress);
    const [isPoBoxZip, setIsPoBoxZip] = useState(parentDetails?.poBoxZip !== "" ? parentDetails?.poBoxZip : "");
    const [ispoboxaddress, setIsPoBoxAddress] = useState(parentDetails?.poBoxAddress !== "" ? parentDetails?.poBoxAddress : "");
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [buttonColor, setButtonColor] = useState(false);
    const [noButtonColor, setNoButtonColor] = useState(false);
    const validationSchema = Yup.object().shape({
        address1: Yup.string().required("Address is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            address1: serviceAddress1 !== "" ? serviceAddress1 : parentDetails?.address1,
            address2: serviceAddress2 !== "" ? serviceAddress2 : parentDetails?.address2 === null ? "" : parentDetails?.address2,
            zip: parentDetails?.zip,
            city: parentDetails?.city,
            state: parentDetails?.state,
            isSameServiceAddress: parentDetails?.isSameServiceAddress,
            isNotSameServiceAddress: parentDetails?.isNotSameServiceAddress,
            isPoBoxAddress: parentDetails?.isPoBoxAddress,
            mailingAddress1: mailingAddress1 !== "" ? mailingAddress1 : parentDetails?.mailingAddress1,
            mailingAddress2: mailingAddress2 !== "" ? mailingAddress2 : parentDetails?.mailingAddress2 === null ? "" : parentDetails?.mailingAddress2,
            mailingZip: parentDetails?.mailingZip,
            mailingCity: parentDetails?.mailingCity,
            mailingState: parentDetails?.mailingState,
            PoBoxAddress: poBoxAddress !== "" ? poBoxAddress : parentDetails?.PoBoxAddress,
            poBoxZip: parentDetails?.poBoxZip,
            poBoxState: parentDetails?.poBoxState,
            poBoxCity: parentDetails?.poBoxCity,
        },
        onSubmit: async (values, actions) => {
            // checkEligiblity();
            const userId = _id;
            const dataToSend = {
                address1: formik.values.address1 === null || formik.values.address1 === undefined || formik.values.address1 === "" ? parentDetails.address1 : formik.values.address1,
                address2: formik.values.address2 === null ? "" : formik.values.address2 === "UNDEFINED" ? "" : formik.values.address2,
                zip: formik.values.zip,
                city: formik.values.city,
                state: formik.values.state,
                isSameServiceAddress: formik.values.isSameServiceAddress,
                isNotSameServiceAddress: formik.values.isNotSameServiceAddress,
                isPoBoxAddress: formik.values.isPoBoxAddress,
                mailingAddress1: formik.values?.isNotSameServiceAddress ? formik.values.mailingAddress1 : formik.values.isSameServiceAddress ? formik.values.address1 : "",
                mailingAddress2: formik.values?.isNotSameServiceAddress ? formik.values.mailingAddress2 : formik.values.isSameServiceAddress ? formik.values.address2 : "",
                mailingZip: formik.values?.isNotSameServiceAddress ? formik.values.mailingZip : formik.values.isSameServiceAddress ? formik.values?.zip : "",
                mailingCity: formik.values?.isNotSameServiceAddress ? formik.values.mailingCity : formik.values.isSameServiceAddress ? formik.values?.city : "",
                mailingState: formik.values?.isNotSameServiceAddress ? formik.values.mailingState : formik.values.isSameServiceAddress ? formik.values?.state : "",
                PoBoxAddress: formik.values.isPoBoxAddress ? formik.values.PoBoxAddress : "",
                poBoxZip: formik.values.isPoBoxAddress ? formik.values.poBoxZip : "",
                poBoxState: formik.values.isPoBoxAddress ? formik.values.poBoxState : "",
                poBoxCity: formik.values.isPoBoxAddress ? formik.values.poBoxCity : "",
                userId: userId,
                csr: csr,
                validates: true,
            };
            setIsLoading(true);
            try {
                const response = await Axios.post(`${BASE_URL}/api/user/homeAddress`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Address saved Successfully");
                    setAddressActive(false);
                    // setConfirmationActive(true);
                    setProductActive(true);
                    setPaymentScreenActive(true);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
        },
    });
    useEffect(() => {
        if (formik.values.mailingZip && formik.values.mailingZip.length === 5) {
            async function getData() {
                const response = await Axios.get(`${BASE_URL}/api/zipCode/getByZipCode?zipCode=${formik.values.mailingZip}`);
                const data = response?.data?.data;
                formik.setFieldValue("mailingCity", data?.city);
                formik.setFieldValue("mailingState", data?.abbreviation);
                setParentDetails((prev) => ({
                    ...prev,
                    mailingCity: data?.city,
                    mailingState: data?.abbreviation,
                }));
            }
            getData();
        }
    }, [formik.values.mailingZip]);

    useEffect(() => {
        // if (formik.values.isPoBoxAddress) {
        if (formik.values.poBoxZip && formik.values.poBoxZip.length === 5) {
            async function getData() {
                const response = await Axios.get(`${BASE_URL}/api/zipCode/getByZipCode?zipCode=${formik.values.poBoxZip}`);
                const data = response?.data?.data;
                formik.setFieldValue("poBoxCity", data?.city);
                formik.setFieldValue("poBoxState", data?.abbreviation);
                setParentDetails((prev) => ({
                    ...prev,
                    poBoxCity: data?.city,
                    poBoxState: data?.abbreviation,
                }));
            }
            getData();
        }
        // }
    }, [formik.values.isPoBoxAddress, formik.values.poBoxZip]);

    const handleSame = () => {
        formik.setFieldValue("isSameServiceAddress", true);
        formik.setFieldValue("isNotSameServiceAddress", false);
        formik.setFieldValue("isPoBoxAddress", false);

        setParentDetails((prev) => ({
            ...prev,
            isSameServiceAddress: true,
            isNotSameServiceAddress: false,
            isPoBoxAddress: false,
        }));

        setIsSame(true);
        setIsDifferent(false);
        setIsPoBox(false);
    };

    const handleDifferent = () => {
        formik.setFieldValue("isNotSameServiceAddress", true);
        formik.setFieldValue("isSameServiceAddress", false);
        formik.setFieldValue("isPoBoxAddress", false);
        setParentDetails((prev) => ({
            ...prev,
            isSameServiceAddress: false,
            isNotSameServiceAddress: true,
            isPoBoxAddress: false,
        }));
        setIsSame(false);
        setIsDifferent(true);
        setIsPoBox(false);
    };

    const handlePobox = () => {
        formik.setFieldValue("isPoBoxAddress", true);
        formik.setFieldValue("isSameServiceAddress", false);
        formik.setFieldValue("isNotSameServiceAddress", false);
        setParentDetails((prev) => ({
            ...prev,
            isSameServiceAddress: false,
            isNotSameServiceAddress: false,
            isPoBoxAddress: true,
        }));
        setIsSame(false);
        setIsDifferent(false);
        setIsPoBox(true);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small style={{ color: "red", fontSize: "12px", fontFamily: "Inter", fontWeight: "400" }}>{formik.errors[name]}</small>;
    };

    useEffect(() => {
        if (isDifferent) {
            setConfrimAddress("different");
        } else if (isPoBox) {
            setConfrimAddress("pobox");
        }
    }, [isDifferent, isPoBox]);

    const handleAddress = async () => {
        setIsLoading(true);
        try {
            const dataToSend = {
                streetAddress: formik.values.address1,
                secondaryAddress: formik.values.address2 === null ? "" : formik.values.address2,
                city: formik.values.city,
                state: formik.values.state,
                ZIPCode: formik.values.zip,
                isNotSameServiceAddress: formik.values.isNotSameServiceAddress,
                mailingAddress1: formik.values.mailingAddress1,
                mailingAddress2: formik.values.mailingAddress2 === undefined ? "" : formik.values.mailingAddress2,
                mailingZip: formik.values.mailingZip,
                mailingCity: formik.values.mailingCity,
                mailingState: formik.values.mailingState,
                isPoBoxAddress: formik.values.isPoBoxAddress,
                PoBoxAddress: `PO BOX ${formik.values.PoBoxAddress}`,
                poBoxZip: formik.values.poBoxZip,
                poBoxCity: formik.values.poBoxCity,
                poBoxState: formik.values.poBoxState,
            };
            const response = await Axios.post(`${BASE_URL}/api/user/getAddressUsps`, dataToSend);
            setIsLoading(false);
            setStoreValidAddress(response?.data);
            toast.error(response?.data?.serviceAddress?.error?.message);
        } catch (e) {
            toast.error("Error Fetching Address");
            // setIsLoading(true);
            setVisible(false);
        }
    };
    const handleChangeAddress = () => {
        setServiceAddress1(storeValidAddress?.serviceAddress?.address?.streetAddress !== undefined ? storeValidAddress?.serviceAddress?.address?.streetAddress : parentDetails?.address1);
        setServiceAddress2(storeValidAddress?.serviceAddress?.address?.secondaryAddress !== undefined ? storeValidAddress?.serviceAddress?.address?.secondaryAddress : parentDetails?.address2);
        setMailingAddress1(storeValidAddress?.mailingAddress?.address?.streetAddress !== undefined ? storeValidAddress?.mailingAddress?.address?.streetAddress : parentDetails?.mailingAddress2);
        setMailingAddress2(storeValidAddress?.mailingAddress?.address?.secondaryAddress !== undefined ? storeValidAddress?.mailingAddress?.address?.secondaryAddress : parentDetails?.mailingAddress2);
        setPoBoxAddress(storeValidAddress?.poBoxAddress?.address?.streetAddress);
        formik.setFieldValue("address1", storeValidAddress?.serviceAddress?.address?.streetAddress);
        formik.setFieldValue("address2", storeValidAddress?.serviceAddress?.address?.secondaryAddress === "UNDEFINED" ? "" : storeValidAddress?.serviceAddress?.address?.secondaryAddress === null ? "" : storeValidAddress?.serviceAddress?.address?.secondaryAddress);
        formik.setFieldValue("mailingAddress1", storeValidAddress?.mailingAddress?.address?.streetAddress);
        formik.setFieldValue("mailingAddress2", storeValidAddress?.mailingAddress?.address?.secondaryAddress === "UNDEFINED" ? "" : storeValidAddress?.mailingAddress?.address?.secondaryAddress === null ? "" : storeValidAddress?.mailingAddress?.address?.secondaryAddress);
        formik.setFieldValue("PoBoxAddress", storeValidAddress?.poBoxAddress?.address?.streetAddress);
        setValidateAddress(true);
        setVisible(false);
    };
    return (
        <>
            <ToastContainer />
            <form onSubmit={formik.handleSubmit} className="addressmain" style={{ marginLeft: "3.0rem" }}>
                <h1 className="heading" style={{ fontSize: "1.4rem", paddingLeft: "2.0rem", fontWeight: "600" }}>
                    ADDRESS INFORMATION
                </h1>
                <p className="para" style={{ paddingLeft: "2.0rem" }}>
                    Please provide the address at which you will receive service, P.O. Box addresses are not acceptable.
                </p>

                <div className="flex flex-wrap flex-row justify-content-left addressouter" style={{ gap: "2.5%", paddingLeft: "2rem", marginTop: "-3%" }}>
                    <div className="calendar_field">
                        <p className="field_label ">
                            Address 1 <span style={{ color: "red" }}>*</span>
                        </p>
                        <InputText
                            type="text"
                            value={formik.values.address1}
                            name="address1"
                            placeholder="Address1"
                            onChange={(e) => {
                                formik.setFieldValue("address1", e.target.value);
                                setValidateAddress(false);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    address1: e.target.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className={`w-full input_text ${formik.touched.address1 && formik.errors.address1 ? "input-error" : ""}`}
                            // className="w-full input_text"
                            minLength={10}
                            autoComplete="new-password"
                        />
                        {formik.touched.address1 && formik.errors.address1 ? (
                            <p className="field_label" style={{ color: "red" }}>
                                {formik.errors.address1}
                            </p>
                        ) : null}
                    </div>
                    <div className="calendar_field">
                        <p className="field_label">Address 2</p>
                        <InputText
                            type="text"
                            value={formik.values.address2}
                            name="address2"
                            placeholder="Address2"
                            onChange={(e) => {
                                formik.setFieldValue("address2", e.target.value);
                                setValidateAddress(false);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    address2: e.target.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className="w-full input_text"
                            autoComplete="new-password"
                        />
                    </div>
                    <div className="calendar_field">
                        <p className="field_label">
                            City <FontAwesomeIcon className="disable-icon-color icon-size" />
                        </p>
                        <InputText
                            type="text"
                            value={formik.values.city}
                            name="city"
                            placeholder="Miami"
                            className="w-full disable-color input_text"
                            onChange={(e) => {
                                formik.setFieldValue("city", e.target.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    city: e.target.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="calendar_field addstate">
                        <p className="field_label" style={{ marginTop: "-1rem" }}>
                            State <FontAwesomeIcon className="disable-icon-color icon-size" />
                        </p>
                        <InputText
                            type="text"
                            onBlur={formik.handleBlur}
                            placeholder="Florida"
                            onChange={(e) => {
                                formik.setFieldValue("state", e.target.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    state: e.target.value,
                                }));
                            }}
                            disabled={!zipVerified || parentDetails?.izZipVerified}
                            value={formik.values.state}
                            name="state"
                            className="w-full disable-color input_text"
                        />
                    </div>
                    <div className="calendar_field addzip">
                        <p className="field_label" style={{ marginTop: "-1rem" }}>
                            {/* Zip Code <FontAwesomeIcon className="disable-icon-color icon-size" icon={faBan} /> */}
                            Zip Code <FontAwesomeIcon className="disable-icon-color icon-size" />
                        </p>
                        <InputText
                            disabled
                            value={formik.values.zip}
                            name="zip"
                            placeholder="33157"
                            onChange={(e) => {
                                formik.setFieldValue("zip", e.target.value);
                                setParentDetails((prev) => ({
                                    ...prev,
                                    zip: e.target.value,
                                }));
                            }}
                            onBlur={formik.handleBlur}
                            className="w-full disable-color input_text"
                        />
                    </div>
                </div>

                <div className="addresscontainer" style={{ marginTop: "3rem" }}>
                    <div className="calendar_field1">
                        <p className="field_label" style={{ fontWeight: "600", marginTop: "-2%" }}>
                            Is Your Mailling Address?
                        </p>
                    </div>

                    <div className="flex flex-wrap w-full items-center justify-between">
                        <div className="mr-3 flex items-center">
                            <RadioButton
                                className="mt-0.5" // Adjust margin-top slightly for better centering
                                inputId="confrimAddress"
                                name="address"
                                value="same"
                                onClick={handleSame}
                                onChange={(e) => setConfrimAddress(e.value)}
                                checked={confrimAddress === "same"}
                            />
                            <label
                                htmlFor="sameAddress"
                                className=" mt-1 text-gray-500 text-lg font-normal sameasservices" // Margin left for spacing
                                style={{ fontSize: "1.2rem", marginLeft: "0rem" }}
                            >
                                Same As Service Address
                            </label>
                        </div>
                        <div className="mr-3 flex items-center">
                            <RadioButton className="mt-0.5" inputId="confrimAddress" name="address" value="different" onClick={handleDifferent} onChange={(e) => setConfrimAddress(e.value)} checked={confrimAddress === "different"} />
                            <label htmlFor="differentAddress" className=" mt-1 text-gray-500 text-lg font-normal difffromservices" style={{ fontSize: "1.2rem", marginLeft: "0rem" }}>
                                Different from Service Address
                            </label>
                        </div>
                        <div className="mr-3 flex items-center">
                            <RadioButton className="mt-0.5" inputId="confrimAddress" name="address" value="pobox" onClick={handlePobox} onChange={(e) => setConfrimAddress(e.value)} checked={confrimAddress === "pobox"} />
                            <label htmlFor="poboxAddress" className=" mt-1 text-gray-500 text-lg font-normal mailingaddress" style={{ fontSize: "1.2rem", marginLeft: "0rem" }}>
                                My mailing address is a PO BOX
                            </label>
                        </div>
                    </div>

                    {isDifferent && (
                        <>
                            <div className="flex flex-wrap flex-row justify-content-left diffaddress" style={{ gap: "2.5%", marginTop: "-2%" }}>
                                <div className="calendar_field  ">
                                    <label className="field_label mb-2 ">
                                        Address 1 <span className="steric">*</span>
                                    </label>
                                    <InputText
                                        id="mailingAddress1"
                                        value={formik.values.mailingAddress1}
                                        placeholder="725 NE 166th St"
                                        onChange={(e) => {
                                            formik.setFieldValue("mailingAddress1", e.target.value);
                                            setValidateAddress(false);
                                            setIsMailingAddress(e.target.value);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                mailingAddress1: e.target.value,
                                            }));
                                        }}
                                        className={classNames({ "p-invalid": isFormFieldValid("mailingAddress1") }, "input_text", "w-full")}
                                        autoComplete="new-password"
                                    />
                                    {getFormErrorMessage("mailingAddress1")}
                                </div>
                                <div className="calendar_field space">
                                    <label className="field_label mb-2"> Address 2 </label>
                                    <InputText
                                        id="mailingAddress2"
                                        placeholder="725 NE 166th St"
                                        value={formik.values.mailingAddress2}
                                        onChange={(e) => {
                                            formik.setFieldValue("mailingAddress2", e.target.value);
                                            setValidateAddress(false);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                mailingAddress2: e.target.value,
                                            }));
                                        }}
                                        autoComplete="new-password"
                                        className="w-full input_text"
                                    />
                                </div>

                                <div className="calendar_field space">
                                    <label className="field_label  mb-2">
                                        City <FontAwesomeIcon className="disable-icon-color icon-size" />{" "}
                                    </label>
                                    <InputText
                                        id="mailingCity"
                                        // disabled={formik.values.mailingZip ? "" : "disabled"}
                                        disabled
                                        placeholder="Miami"
                                        onChange={(e) => {
                                            formik.setFieldValue("mailingCity", e.target.value);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                mailingCity: e.target.value,
                                            }));
                                        }}
                                        value={formik.values.mailingCity}
                                        className="disable-color w-full input_text"
                                    />
                                </div>
                                <div className="calendar_field space">
                                    <label className="field_label" style={{ marginTop: "-2rem" }}>
                                        State <FontAwesomeIcon className="disable-icon-color icon-size" />{" "}
                                    </label>
                                    <InputText
                                        placeholder="Florida"
                                        id="mailingState"
                                        // disabled={formik.values.mailingZip ? "" : "disabled"}
                                        disabled
                                        value={formik.values.mailingState}
                                        onChange={(e) => {
                                            formik.setFieldValue("mailingState", e.target.value);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                mailingState: e.target.value,
                                            }));
                                        }}
                                        className="disable-color w-full mt-2 input_text"
                                    />
                                </div>
                                <div className="calendar_field space " style={{ marginBottom: "1rem" }}>
                                    <label className="field_label  " style={{ marginTop: "-2rem" }}>
                                        Zip Code <span className="steric">*</span>
                                    </label>
                                    <InputText
                                        placeholder="33162"
                                        id="mailingZip"
                                        value={formik.values.mailingZip}
                                        onChange={(e) => {
                                            formik.setFieldValue("mailingZip", e.target.value);
                                            setValidateAddress(false);
                                            setIsMailingZip(e.target.value);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                mailingZip: e.target.value,
                                            }));
                                        }}
                                        className={classNames({ "p-invalid": isFormFieldValid("mailingZip") }, "w-full input_text", "mt-2")}
                                        keyfilter={/^\d{0,5}$/}
                                        maxLength={5}
                                    />
                                    {getFormErrorMessage("mailingZip")}
                                </div>
                            </div>
                        </>
                    )}
                    {isPoBox && (
                        <>
                            <div className="flex flex-wrap justify-content-left poboxaddress" style={{ gap: "2.5%", marginTop: "-3rem" }}>
                                <div className="calendar_field ">
                                    <label className="field_label  ">
                                        PO Box No <span className="steric">*</span>
                                    </label>
                                    <InputText
                                        id="PoBoxAddress"
                                        placeholder="123456"
                                        value={formik.values.PoBoxAddress}
                                        onChange={(e) => {
                                            formik.setFieldValue("PoBoxAddress", e.target.value);
                                            setValidateAddress(false);
                                            setIsPoBoxAddress(e.target.value);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                PoBoxAddress: e.target.value,
                                            }));
                                        }}
                                        className={classNames({ "p-invalid": isFormFieldValid("PoBoxAddress") }, "input_text w-full mt-3")}
                                        // keyfilter={/^[0-9]*$/}
                                        autoComplete="new-password"
                                    />
                                    {getFormErrorMessage("PoBoxAddress")}
                                </div>

                                <div className="calendar_field ">
                                    <label className="field_label ">City</label>
                                    <InputText
                                        id="poBoxCity"
                                        value={formik.values.poBoxCity}
                                        placeholder="Miami"
                                        onChange={(e) => {
                                            formik.setFieldValue("poBoxCity", e.target.value);
                                            setValidateAddress(false);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                poBoxCity: e.target.value,
                                            }));
                                        }}
                                        className="disable-color input_text w-full mt-3"
                                        disabled={formik.values.poBoxZip ? "" : "disabled"}
                                    />
                                </div>
                                <div className="calendar_field ">
                                    <label className="field_label ">State</label>
                                    <InputText
                                        id="poBoxState"
                                        value={formik.values.poBoxState}
                                        placeholder="Florida"
                                        onChange={(e) => {
                                            formik.setFieldValue("poBoxState", e.target.value);
                                            setValidateAddress(false);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                poBoxState: e.target.value,
                                            }));
                                        }}
                                        disabled={formik.values.poBoxZip ? "" : "disabled"}
                                        className="disable-color input_text w-full mt-3"
                                    />
                                </div>
                                <div className="calendar_field poxboxzip" style={{ marginBottom: "3rem" }}>
                                    <label className="field_label">
                                        Zip Code <span className="steric">*</span>
                                    </label>
                                    <InputText
                                        id="poBoxZip"
                                        value={formik.values.poBoxZip}
                                        placeholder="33157"
                                        onChange={(e) => {
                                            formik.setFieldValue("poBoxZip", e.target.value);
                                            setValidateAddress(false);
                                            setIsPoBoxZip(e.target.value);
                                            setParentDetails((prev) => ({
                                                ...prev,
                                                poBoxZip: e.target.value,
                                            }));
                                        }}
                                        maxLength={5}
                                        keyfilter={/^[0-9]*$/}
                                        className="input_text w-full mt-3"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <div className="flex w-full flex-row justify-content-end align-items-center mb-2 mt-8  ">
                        <div className="flex flex-wrap flex-row justify-content-left">
                            <Button
                                className="resbtn"
                                label="Back"
                                type="button"
                                onClick={() => {
                                    setPersonalInfoActive(true);
                                    setAddressActive(false);
                                }}
                                style={{ borderRadius: ".8rem", width: "6.6rem" }}
                            />
                            {validateAddress || (parentDetails?.validates && incomplete) ? (
                                <Button
                                    type="submit"
                                    className="resbtn ml-1"
                                    label="Continue"
                                    icon={isLoading === true ? "pi pi-spin pi-spinner " : ""}
                                    disabled={isLoading || (isDifferent && (ismailingaddress === "" || isMailingZip === "")) || (isPoBox && (ispoboxaddress === undefined || ispoboxaddress === "" || isPoBoxZip === ""))}
                                />
                            ) : (
                                <div>
                                    <Button
                                        type="button"
                                        className="resbtn ml-1"
                                        label="Validate"
                                        onClick={() => {
                                            setVisible(true);
                                            handleAddress();
                                        }}
                                        style={{ borderRadius: ".8rem" }}
                                    />

                                    <Dialog visible={visible} style={{ width: "37%", height: "auto", color: "red", borderRadius: "1.0rem" }} header={null} closable={false} className="custom-dialog">
                                        <div className="flex flex-wrap justify-content-center flex-column ">
                                            <h1 className="flex justify-content-center w-full absolute " style={{ color: "#A20100", fontSize: "2.4rem ", fontWeight: "700", marginTop: "-19rem" }}>
                                                USPS ADDRESS SUGGESTION
                                            </h1>
                                            {isLoading ? (
                                                <div className="flex flex-wrap justify-content-center mt-4 mb-4">
                                                    <CommonLoaderBlue />
                                                </div>
                                            ) : (
                                                <div>
                                                    <span className="flex flex-wrap justify-center items-center mt-2 mb-2. px-4 py-3 uspsaddressfield">
                                                        Services Address: {storeValidAddress?.serviceAddress?.address?.streetAddress}
                                                        {storeValidAddress?.serviceAddress?.address?.secondaryAddress ? `, ${storeValidAddress.serviceAddress.address.secondaryAddress}` : ""}
                                                        {storeValidAddress?.serviceAddress?.address?.city ? `, ${storeValidAddress.serviceAddress.address.city}` : ""}
                                                        {storeValidAddress?.serviceAddress?.address?.state ? `, ${storeValidAddress.serviceAddress.address.state}` : ""}
                                                        {storeValidAddress?.serviceAddress?.address?.ZIPCode ? ` ${storeValidAddress.serviceAddress.address.ZIPCode}` : ""}
                                                    </span>
                                                    {storeValidAddress?.mailingAddress !== null ? (
                                                        <span className="flex flex-wrap justify-center items-center mt-2 mb-2. px-4 py-3 uspsaddressfield">
                                                            Mailing Address: {storeValidAddress?.mailingAddress?.address?.streetAddress}
                                                            {storeValidAddress?.mailingAddress?.address?.secondaryAddress ? `,${storeValidAddress?.mailingAddress?.address?.secondaryAddress}` : ""}
                                                            {storeValidAddress?.mailingAddress?.address?.city ? `, ${storeValidAddress.mailingAddress.address.city}` : ""}
                                                            {storeValidAddress?.mailingAddress?.address?.state ? `, ${storeValidAddress.mailingAddress.address.state}` : ""}
                                                            {storeValidAddress?.mailingAddress?.address?.ZIPCode ? ` ${storeValidAddress.mailingAddress.address.ZIPCode}` : ""}
                                                        </span>
                                                    ) : null}
                                                    {storeValidAddress?.poBoxAddress !== null ? (
                                                        <span className="flex flex-wrap justify-center items-center mt-2 mb-2 px-4 py-3 uspsaddressfield">
                                                            PO BOX Address: {storeValidAddress?.poBoxAddress?.address?.streetAddress}
                                                            {storeValidAddress?.poBoxAddress?.address?.city ? `, ${storeValidAddress.poBoxAddress.address.city}` : ""}
                                                            {storeValidAddress?.poBoxAddress?.address?.state ? `, ${storeValidAddress.poBoxAddress.address.state}` : ""}
                                                            {storeValidAddress?.poBoxAddress?.address?.ZIPCode ? ` ${storeValidAddress.poBoxAddress.address.ZIPCode}` : ""}
                                                        </span>
                                                    ) : undefined}
                                                </div>
                                            )}
                                            <span className="pt-3 flex flex-wrap justify-content-center mt-2 dialoguespan">Would you like to proceed with the suggested address?</span>
                                        </div>
                                        <div className="flex flex-wrap justify-content-center mt-3" style={{ gap: "1%" }}>
                                            <Button
                                                label="No"
                                                onClick={() => {
                                                    setVisible(false);
                                                    setValidateAddress(true);
                                                }}
                                                className={noButtonColor ? "nobuttontrue" : "nobutton"}
                                                onMouseEnter={() => setNoButtonColor(true)}
                                                onMouseLeave={() => setNoButtonColor(false)}
                                                style={{ width: "5.1rem", border: ".1rem solid #c6dfff", height: "3.4rem" }}
                                            />

                                            <Button
                                                label="Yes"
                                                onClick={handleChangeAddress}
                                                onMouseEnter={() => setButtonColor(true)}
                                                onMouseLeave={() => setButtonColor(false)}
                                                className={buttonColor ? "yesbuttontrue" : "yesbutton"}
                                                style={{ width: "5.7rem", border: ".1rem solid #c6dfff", height: "3.4rem" }}
                                            />
                                        </div>
                                    </Dialog>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Address;
