import React from "react"
export const Calendar=()=>{  
     return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 2.5V6.5" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 2.5V6.5" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 9.5H21" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 4.5H5C3.895 4.5 3 5.395 3 6.5V19.5C3 20.605 3.895 21.5 5 21.5H19C20.105 21.5 21 20.605 21 19.5V6.5C21 5.395 20.105 4.5 19 4.5Z" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.01268 13.229C6.87468 13.229 6.76268 13.341 6.76368 13.479C6.76368 13.617 6.87568 13.729 7.01368 13.729C7.15168 13.729 7.26368 13.617 7.26368 13.479C7.26368 13.341 7.15168 13.229 7.01268 13.229" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.0127 13.229C11.8747 13.229 11.7627 13.341 11.7637 13.479C11.7637 13.617 11.8757 13.729 12.0137 13.729C12.1517 13.729 12.2637 13.617 12.2637 13.479C12.2637 13.341 12.1517 13.229 12.0127 13.229" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.0127 13.229C16.8747 13.229 16.7627 13.341 16.7637 13.479C16.7637 13.617 16.8757 13.729 17.0137 13.729C17.1517 13.729 17.2637 13.617 17.2637 13.479C17.2637 13.341 17.1517 13.229 17.0127 13.229" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.01268 17.229C6.87468 17.229 6.76268 17.341 6.76368 17.479C6.76368 17.617 6.87568 17.729 7.01368 17.729C7.15168 17.729 7.26368 17.617 7.26368 17.479C7.26368 17.341 7.15168 17.229 7.01268 17.229" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.0127 17.229C11.8747 17.229 11.7627 17.341 11.7637 17.479C11.7637 17.617 11.8757 17.729 12.0137 17.729C12.1517 17.729 12.2637 17.617 12.2637 17.479C12.2637 17.341 12.1517 17.229 12.0127 17.229" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>)
 

}  
export const ModuleSelector=()=>{ 
    return( 
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 21.5H5C3.895 21.5 3 20.605 3 19.5V15.5C3 14.395 3.895 13.5 5 13.5H9C10.105 13.5 11 14.395 11 15.5V19.5C11 20.605 10.105 21.5 9 21.5Z" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11 17.5H14C15.105 17.5 16 16.605 16 15.5V10.5C16 9.395 15.105 8.5 14 8.5H9C7.895 8.5 7 9.395 7 10.5V13.5" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 14.5H19C20.105 14.5 21 13.605 21 12.5V5.5C21 4.395 20.105 3.5 19 3.5H12C10.895 3.5 10 4.395 10 5.5V8.5" stroke="#475CED" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        

    )
}