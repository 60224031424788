import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; 
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css"
import store from "./app/store/store"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <HashRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </HashRouter>
);