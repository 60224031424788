import React, { useEffect, useState } from "react";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const QuerySim = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [esn, setEsn] = useState("");
    const [data, setData] = useState("");
    const [disable, setDisable] = useState(false);
    const [cpData, setCpData] = useState("");
    const userData = localStorage.getItem("userData");
    const parseUserData = JSON.parse(userData);
    const getQueryNetwork = async () => {
        setIsLoading(true);
        try {
            const dataToSend = {
                enrollmentId: cpData,
                userId: parseUserData?._id,
                esn: esn,
            };

            const resp = await Axios.post(`${BASE_URL}/api/user/querySim`, dataToSend);
            setData(resp?.data?.data);
            if (resp?.status === 200 || resp?.status === 201) {
                setIsLoading(false);
                toast.success(resp?.data?.msg);
            }
        } catch (error) {
            toast.error(`${error?.response?.data?.msg}: ${error?.response?.data?.status?.errors?.error?.message}`);
            setIsLoading(false);
        }
    };
    const handleESN = (e) => {
        const inputEsn = e.target.value;
        const sanitizedEsn = inputEsn.replace(/\D/g, "");
        const trimmedEsn = sanitizedEsn.slice(0, 19);
        setEsn(trimmedEsn);
    };
    const custId = JSON.parse(localStorage.getItem("selectedId"));

    useEffect(() => {
        const getUserData = async () => {
            try {
                const res = await Axios.get(`${BASE_URL}/api/user/userDetails?userId=${custId}`);
                if (res?.status === 200 || res?.status === 201) {
                    setCpData(res?.data?.data);
                }
            } catch (e) {}
        };
        getUserData();
    }, []);
    return (
        <div className="card">
            <div flex flex-wrap justify-content-left>
                <h2 style={{ fontWeight: "600" }}>Query Sim</h2>
            </div>
            <br />
            <div className=" flex flex-wrap w-full  justify-content-between">
                <h1 style={{ fontSize: "14px", fontWeight: "600", paddingTop: "10px" }}>ESN</h1>
                <InputText disabled={disable} style={{ width: "70%" }} value={esn} onChange={handleESN} />
            </div>
            <div className="flex flex-wrap w-full justify-content-end mt-4">
                <Button
                    loading={isLoading}
                    label="Submit"
                    disabled={disable}
                    onClick={() => {
                        if (cpData.esn === undefined) {
                            toast.error("Esn is not Assigned");
                            setIsLoading(false);
                            return;
                        } else if (esn.length < 19) {
                            toast.error("Please enter a valid ESN");
                            setIsLoading(false);

                            return;
                        } else {
                            setDisable(true);
                            getQueryNetwork();
                        }
                    }}
                />
            </div>
            <div className=" flex justify-content-center">
                <div className="flex flex-wrap justtify-content-around w-full mt-4 pt-4" style={{ backgroundColor: "#ebfaf8" }}>
                    <div className="justify-content-left pl-4 " style={{ width: "50%" }}>
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>ESN</h1>
                        </div>
                        <br />

                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>Activation Eligible</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>WFC Eligible</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>PUK1</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>PUK2</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>Create Date</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>Expiration Date</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>ICC ID Status</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>Status Code</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "600" }}>Description</h1>
                        </div>
                        <br />
                    </div>

                    <div className="justify-content-left " style={{ width: "50%" }}>
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{esn}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.ACTIVATIONELIGIBLE}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.WFCEligible}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.PUK1}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.PUK2}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}> {data?.CREATEDATE ? new Date(Date.parse(data.CREATEDATE)).toISOString().substring(0, 10) : ""}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.EXPIRATIONDATE ? new Date(Date.parse(data.EXPIRATIONDATE)).toISOString().substring(0, 10) : ""}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.ICCIDSTATUS}</h1>
                        </div>

                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.statusCode}</h1>
                        </div>
                        <br />
                        <div>
                            <h1 style={{ fontSize: "14px", fontWeight: "400" }}>{data?.description}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuerySim;
