import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./login.css";
import Axios from "axios";
import { Password } from "primereact/password";
import { CheckTickBtn, Eyebutton, LoginPageCircle, LoginRightBottom, LoginRightTop, SplashScreen, SplashTeleyorkLogo, TeleyorkLogo } from "../../../../utility";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function LoginScreen({ setShowApps, setRefreshApp }) {
    const [showPassowrd, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errormsg, setErrorMsg] = useState(null);
    const [splashScreen, setSplashScreen] = useState(false);
    const [forgetScreen, setForgetScreen] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);

    setTimeout(() => {
        setSplashScreen(true);
    }, 3000);
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email").required("Email is required"),
            password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
        }),
        onSubmit: async (values) => {
            setLoading((prev) => !prev);
            Axios.post(`${BASE_URL}/api/web/user/login`, formik.values)
                .then((response) => {
                    const allowdPerms = response?.data?.data?.permissions;
                    localStorage.setItem("userData", JSON.stringify(response?.data?.data));
                    localStorage.setItem("accessToken", JSON.stringify(response?.data?.data?.token));
                    localStorage.setItem("refreshToken", JSON.stringify(response?.data?.refreshToken));

                    localStorage.setItem("permissions", JSON.stringify(allowdPerms));
                    setRefreshApp((prev) => !prev);
                    setShowApps(true);
                })
                .catch((err) => {
                    setErrorMsg(err.response?.data?.msg);
                    setLoading((prev) => !prev);
                });
        },
    });
    useEffect(() => {
        var currentURL;
        var modifiedURL;
        currentURL = window.location.href;
        // currentURL = "http://dev-ijwireless.teleyork.com/#/login";
        if (currentURL.includes("dev-")) {
            modifiedURL = currentURL.replace("http://dev-", "");
            modifiedURL = modifiedURL.replace("/#/login", "");
        } else {
            modifiedURL = currentURL.replace("http://", "");
            modifiedURL = modifiedURL.replace("/#/login", "");
        }
        const sendURl = async () => {
            try {
                const response = await Axios.get(`${BASE_URL}/api/web/serviceProvider/getSPdetailByDomain?subDomain=${modifiedURL}`);
            } catch (error) {}
        };
        sendURl();
    }, []);
    return (
        <>
            {splashScreen ? (
                <div className="flex justify-content-center align-items-center" style={{ minHeight: "100vh", backgroundColor: "white" }}>
                    {!forgetScreen ? (
                        <div className="card1">
                            <div className="flex flex-start" style={{ marginTop: "24px" }}>
                                <p className="p " style={{ fontWeight: "600", fontFamily: "Inter", opacity: "0.7" }}>
                                    WELCOME!
                                </p>
                            </div>
                            <div style={{ opacity: "0.7" }} className="teleyorklogo">
                                <TeleyorkLogo />
                            </div>
                            <div className="flex flex-start">
                                <p className="p1">Please Login to access your account</p>
                                <br />
                            </div>
                            {errormsg ? (
                                <div className="flex justify-content-center mb-2">
                                    <p style={{ color: "red" }} className="font-semibold text-xl">
                                        Invalid Credentials
                                    </p>
                                </div>
                            ) : null}
                            <div className="flex flex-wrap ">
                                <form onSubmit={formik.handleSubmit}>
                                    <label className="labell">Email</label>
                                    <br />
                                    <InputText type="text" name="email" className="emailfield" placeholder="Enter Email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                    {formik.touched.email && formik.errors.email ? (
                                        <p style={{ height: "10px" }} className="text-red-500 p-error ml-1 text-sm mt-0 ">
                                            {formik.errors.email}
                                        </p>
                                    ) : null}
                                    {!formik.errors.email ? (
                                        <>
                                            <br />
                                            <br />
                                        </>
                                    ) : null}
                                    <label className="labell">Password</label>
                                    <div className="passworddiv" style={{ height: "40px", marginTop: "10px", width: "335px" }}>
                                        <Password type="password" style={{ height: "35px" }} feedback={false} tabIndex={1} name="password" className="" placeholder="Enter password" value={formik.values.password} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                        <i
                                            className={`${showPassowrd ? "pi-eye" : "pi-eye-slash"} pi`}
                                            onClick={(e) => {
                                                if (e.target.previousElementSibling.children[0].type === "text") {
                                                    e.target.previousElementSibling.children[0].type = "password";
                                                } else {
                                                    e.target.previousElementSibling.children[0].type = "text";
                                                }
                                                setShowPassword((prev) => !prev);
                                            }}
                                        />
                                    </div>

                                    {formik.touched.password && formik.errors.password ? <p className="text-red-500 p-error ml-1 text-sm mt-0">{formik.errors.password}</p> : null}
                                    <div style={{ marginTop: "5px" }}>
                                        {/* <NavLink className="forgot" to={{ pathname: "/sendotp", state: { email: formik.values.email } }}> */}
                                        <div className="forgot">
                                            <NavLink to={{ pathname: "/sendotp", state: { email: formik.values.email } }}>
                                                <span onClick={() => setForgetScreen(true)}>Forgot Password?</span>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <Button type="submit" className="login" label="Login" disabled={loading} icon={loading === true ? "pi pi-spin pi-spinner " : ""} style={{ marginBottom: "5%" }} />
                                </form>
                            </div>
                        </div>
                    ) : sendEmail ? (
                        <div className="flex flex-column align-items-center justify-content-center forgetpasspopup" style={{ gap: "3%" }}>
                            <div>
                                <CheckTickBtn />
                            </div>
                            <div className="flex flex-column align-items-center checkinbox mt-4">
                                <h1>Check Your Inbox</h1>
                                <p>We’ve sent a password reset link to your email. Follow the instructions to regain access to your Sniptel account.</p>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-column justify-content-evenly align-items-center forgotpass">
                            <div style={{ width: "355px" }}>
                                <h1>Reset Your Password</h1>
                                <p className="flex mt-4">Enter your email, and we’ll send you a link to get back on track with Sniptel.</p>
                            </div>
                            <div className="flex flex-column " style={{ width: "355px" }}>
                                <div className="flex justify-content-between">
                                    <label className={formik.touched.email && formik.errors.email ? "emaillabelerr" : "emaillabel"}>Email</label>
                                    {formik.touched.email && formik.errors.email ? <span className="err">{formik.errors.email}</span> : ""}
                                </div>
                                <InputText id="email" name="email" className={formik.touched.email && formik.errors.email ? "emailinputerr mt-2" : "emailinput mt-2"} placeholder="example@email.com" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                                <div className="flex w-full" style={{ marginTop: "25px" }}>
                                    <Button onClick={() => setSendEmail(true)} disabled={formik.errors.email ? true : false} className="forgetbtn" label="Reset Password" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="splashContainer">
                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  className="splashimg" src="./splash.png" alt="splash" />
                    <div className="splashinnerdiv">
                        <SplashTeleyorkLogo />
                    </div>
                </div>
            )}
        </>
    );
}
