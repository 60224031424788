import React from "react";
import "./Prepaid-Enrollment-Queue-Card.css";
import { SortUp } from "../Assets/Asset";
import { useNavigate } from "react-router-dom";
export default function PrepaidEnrollmentQueueCard({ sideCardsData }) {
    let navigate = useNavigate();
    return (
        <div
            class="prepaid-enrollment-queue-main-container"
            onClick={() => {
                navigate("/prepaid-allenrollment");
            }}
            style={{ cursor: "pointer" }}
        >
            <div className="circle7"></div>
            <div class="circle-main-container">
                <div class="circle0"></div>
                <div class="circle1"></div>
                <div class="circle2"></div>
                <div class="circle3"></div>
                <div class="circle4"></div>

                <div class="circle6"></div>
            </div>
            <div className="flex flex-wrap flex-row justify-content-center w-full">
                <div className="enrollment-queue-top-flex flex flex-wrap flex-row justify-content-between ">
                    <p className="enrollment-queue-top-tag">Enrollment Queue</p>
                    <div className="enrollment-queue-top-stat  flex flex-wrap flex-row justify-content-center align-items-center">
                        <SortUp />
                        <p>{sideCardsData?.EnrollmentQueue?.percentageIncrease}</p>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap flex-row justify-content-center w-full">
                <div className="main-enrollment-queue-stat-tag flex flex-wrap flex-row justify-content-between ">
                    <p className="enrollment-tag">{sideCardsData?.EnrollmentQueue?.count}</p>
                    <p className="enrollment-tag-create-new">{sideCardsData?.EnrollmentQueue?.percentageIncrease} from Last Day </p>
                </div>
            </div>
            <p className="view-all-enrollment-queue">View All </p>
        </div>
    );
}
