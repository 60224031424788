import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Axios from "axios";
import { CheckTickBtn, Loginbg, Sniptel } from "../../../../utility";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const SendOTP = () => {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email").required("Email is required"),
        }),
        onSubmit: async (values) => {
            const dataToSend = {
                email: formik.values.email,
            };
            setLoading(true);
            Axios.post(`${BASE_URL}/api/web/user/forgotPassword`, dataToSend)
                .then((res) => {
                    if (res?.status === 200 || res?.status === 201) {
                        toast.current.show({ severity: "success", summary: "Email", detail: res?.data?.msg });
                        setEmailSent(true);
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Email", detail: err?.response?.data?.msg });
                    setLoading(false);
                });
        },
    });
    return (
        <div className="flex w-full flex-column justify-content-center align-items-center" style={{ height: "100vh", background: "white", overflowY: "auto" }}>
            <div className="bggradient flex w-full">
                <Loginbg />
            </div>

            <Toast ref={toast} />
            {!emailSent ? (
                <div className="flex flex-column align-items-center justify-content-between" style={{ height: "588px", width: "483px" }}>
                    <div className="sniptel">
                        <span>
                            <Sniptel />
                        </span>
                    </div>
                    <div className="flex flex-column justify-content-center align-items-center forgotpass">
                        <div style={{ width: "355px", zIndex: "1" }}>
                            <h1>Reset Your Password</h1>
                            <p className="flex mt-4">Enter your email, and we’ll send you a link to get back on track with Sniptel.</p>
                        </div>
                        <div className="flex flex-column " style={{ width: "355px" }}>
                            <div className="flex justify-content-between">
                                <label className={formik.touched.email && formik.errors.email ? "emaillabelerr" : "emaillabel"}>Email</label>
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="err" style={{ marginTop: "30px" }}>
                                        {formik.errors.email}
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>
                            <InputText id="email" name="email" className={formik.touched.email && formik.errors.email ? "emailinputerr mt-2" : "emailinput mt-2"} placeholder="example@email.com" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} />
                            <div className="flex w-full" style={{ marginTop: "30px" }}>
                                <Button type="submit" loading={loading} onClick={formik.handleSubmit} disabled={formik.values.email === "" || formik.errors.email ? true : false} className="forgetbtn" label="Reset Password" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex flex-column align-items-center justify-content-between " style={{ width: "483px", height: "497px" }}>
                    <div className="sniptel">
                        <span>
                            <Sniptel />
                        </span>
                    </div>
                    <div className="flex flex-column justify-content-center align-items-center forgetpasspopup " style={{ width: "483px", height: "329px", background: "white", zIndex: "1" }}>
                        <div>
                            <CheckTickBtn />
                        </div>
                        <div className="flex flex-column align-items-center checkinbox mt-4">
                            <h1>Check Your Inbox</h1>
                            <p>We’ve sent a password reset link to your email. Follow the instructions to regain access to your Sniptel account.</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SendOTP;
