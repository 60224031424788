import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import Axios from "axios";
import "./service_availability.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function InitiateEnrollment({ setParentDetails, setInitiationData, parentDetails, setPersonalInfoActive, setInitiateActive, billingModelSelected, setCurrentBillingModel, setBillingModelSelected, setZipVerified }) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isCreate, setIsCreate] = useState(false);
    const [pwgApiCheck, setPwgApiCheck] = useState("pending");
    const [pwgApiCheckFound, setPwgApiCheckFound] = useState(false);
    const [pwgDbCheck, setPwgDbCheck] = useState("pending");
    const [pwgDbCheckFound, setPwgDbCheckFound] = useState(false);
    const [uspsCheck, setUspsCheck] = useState("pending");
    const [uspsCheckFound, setUspsCheckFound] = useState(false);
    const [showCheckCoverage, setShowCheckCoverage] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const [buttonColor, setButtonColor] = useState(false);
    const [noButtonColor, setNoButtonColor] = useState(false);
    const location = useLocation();
    const currentPath = location?.pathname;
    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);
        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };

    useEffect(() => {
        actionBasedChecks();
        Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                let billingmodels = res?.data?.data;
                for (let i = 0; i < billingmodels.length; i++) {
                    if (billingmodels[i].billingModel === "PREPAID") {
                        setCurrentBillingModel(billingmodels[i].billingModel);
                        setBillingModelSelected(billingmodels[i]._id);
                    } else if (billingmodels[i].billingModel === "POSTPAID") {
                        setCurrentBillingModel(billingmodels[i].billingModel);
                        setBillingModelSelected(billingmodels[i]._id);
                    }
                }
            })
            .catch((err) => {});
    }, []);

    // Get user data from localStorage
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const validationSchema = Yup.object().shape({
        zipCode: Yup.string().required("Please enter Zip code"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            zipCode: "",
        },
        onSubmit: async (values, actions) => {
            const serviceProvider = parseLoginRes?.company;
            const department = parseLoginRes?.department;
            const csr = parseLoginRes?._id;
            const dataToSend = { serviceProvider, csr, department, ...values, accountType: "Prepaid" };
            setIsLoading(true);
            setShowCheckCoverage(true);
            setNoButtonColor(false);
            Axios.post(`${BASE_URL}/api/user/PWGverifyZip`, dataToSend)
                .then((res) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(true);
                    setParentDetails({
                        ...res?.data?.data,
                        accountType: "Prepaid",
                    });
                    setInitiationData({
                        ...res?.data?.data,
                        accountType: "Prepaid",
                    });
                    Axios.post(`${BASE_URL}/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                    setZipVerified(true);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                    setZipVerified(true);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                    setZipVerified(true);
                                });
                        });
                })
                .catch((err) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(false);
                    Axios.post(`${BASE_URL}/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);
                            setParentDetails({
                                ...res?.data?.data,
                                accountType: "Prepaid",
                            });
                            setInitiationData({
                                ...res?.data?.data,
                                accountType: "Prepaid",
                            });
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                    setZipVerified(true);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                    setZipVerified(true);
                                    setParentDetails({
                                        ...res?.data?.data,
                                        accountType: "Prepaid",
                                    });
                                    setInitiationData({
                                        ...res?.data?.data,
                                        accountType: "Prepaid",
                                    });
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        });
                });
        },
    });

    return (
        <div className="flex flex-column  personalinfomain">
            <div className="grid  justify-content-start ">
                <div className=" personalInfo" style={{ overflow: "hidden" }}>
                    <h1 className="heading">Service Availability</h1>
                    <form className="form" onSubmit={formik.handleSubmit}>
                        <p className="paragraph">Please Provide the Address at which you will receive services, P.O. Box addresses are not acceptable</p>

                        <div className="flex flex-column mt-6">
                            <label className="ziplabel">Zipcode</label>
                            <InputText
                                type="text"
                                name="zipCode"
                                className="w-full zipfield"
                                placeholder="Enter a 5 digit US Zip Code"
                                value={formik.values.zipCode}
                                onChange={(e) => {
                                    formik.setFieldValue("zipCode", e.target.value);
                                    if (pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending") {
                                        formik.setFieldValue("zipCode", e.value);
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                    }
                                }}
                                keyfilter={/^\d{0,5}$/}
                                minLength={5}
                                maxLength={5}
                                disabled={isLoading}
                            />
                        </div>

                        {formik.touched.zipCode && formik.errors.zipCode ? (
                            <p className="mt-0" style={{ color: "red", fontFamily: "Inter", marginLeft: "2rem" }}>
                                {formik.errors.zipCode}
                            </p>
                        ) : null}
                        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                        {!pwgApiCheckFound && !pwgDbCheckFound && !uspsCheckFound && pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending" ? (
                            <Button
                                label="Next"
                                type="button"
                                onClick={() => {
                                    if (pwgApiCheckFound || pwgDbCheckFound || uspsCheckFound) {
                                        // setInitiateActive(false);
                                        setPersonalInfoActive(true);
                                        setZipVerified(true);
                                    } else {
                                        const serviceProvider = parseLoginRes?.company;
                                        const department = parseLoginRes?.department;
                                        const csr = parseLoginRes?._id;
                                        const carrier = "6455532566d6fad6eac59e34";
                                        const dataToSend = { serviceProvider, csr, department, carrier, ...formik.values, accountType: "Prepaid" };

                                        Axios.post(`${BASE_URL}/api/user/withoutzip`, dataToSend)
                                            .then((res) => {
                                                setParentDetails({
                                                    ...res?.data?.data,
                                                    accountType: "Prepaid",
                                                });
                                                setInitiationData({
                                                    ...res?.data?.data,
                                                    accountType: "Prepaid",
                                                });

                                                //localStorage.setItem("izZipVerified", "no");
                                                setZipVerified(true);
                                                setPersonalInfoActive(true);
                                                setInitiateActive(false);
                                            })
                                            .catch((err) => {});
                                    }
                                }}
                                className="checkcoverageactive vlidatebtndiv"
                            />
                        ) : (
                            <div className="flex w-full justify-content-end vlidatebtndiv">
                                <Button label="Validate" type="submit" className={formik.values.zipCode?.length < 5 || isLoading ? "checkcoverage" : "checkcoverageactive"} disabled={isLoading || !isCreate} />
                            </div>
                        )}
                        {showCheckCoverage ? (
                            <div className="checkcoverageapi flex flex-column">
                                <div className="flex  w-full mt-4 align-items-center" style={{ height: "18px" }}>
                                    <span style={{ width: "16px", height: "16px", fontSize: "16px", color: "#98ADF8" }} className={`pi ${pwgApiCheck === "pending" ? "pi-spin pi-spinner" : pwgApiCheckFound ? "pi-check trueicoin " : "pi-times falseicon"}`}></span>
                                    <p
                                        style={{
                                            color: "#414064",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            paddingLeft: "1rem",
                                        }}
                                    >
                                        Checking through PWG API
                                    </p>
                                </div>

                                <div className="flex w-full mt-4 align-items-center" style={{ height: "18px" }}>
                                    <span style={{ width: "16px", height: "16px", fontSize: "16px", color: "#98ADF8" }} className={`pi ${pwgDbCheck === "pending" ? "pi-spin pi-spinner" : pwgDbCheckFound ? "pi-check trueicoin " : "pi-times falseicon"}`}></span>
                                    <p
                                        style={{
                                            color: "#414064",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            paddingLeft: "1rem",
                                        }}
                                    >
                                        Checking through PWG Database Data
                                    </p>
                                </div>
                                <div className="flex w-full mt-4 align-items-center" style={{ height: "18px" }}>
                                    <span style={{ width: "16px", height: "16px", fontSize: "16px", color: "#98ADF8" }} className={`pi ${uspsCheck === "pending" ? "pi-spin pi-spinner" : uspsCheckFound ? "pi-check trueicoin  " : "pi-times falseicon"}`}></span>
                                    <p
                                        style={{
                                            color: "#414064",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            paddingLeft: "1rem",
                                        }}
                                    >
                                        Checking through USPS
                                    </p>
                                </div>

                                {/* <Button iconPos="right" className="coveragebutton mt-4" type="button" icon={pwgApiCheck === "pending" ? "pi pi-spin pi-spinner" : pwgApiCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"} label="Checking through PWG API" /> */}

                                {/* <Button
                                    iconPos="right"
                                    className="mt-4  coveragebutton"
                                    type="button"
                                    icon={pwgDbCheck === "pending" ? "pi pi-spin pi-spinner" : pwgDbCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"}
                                    label={
                                        <span
                                            style={{
                                                background:
                                                    pwgDbCheck === "pending"
                                                        ? "linear-gradient(90deg, rgba(138, 237, 246, 0.905882) 0%, #60A2F5 100%)" // Gradient for text during pending
                                                        : "none",
                                                WebkitBackgroundClip: pwgDbCheck === "pending" ? "text" : "unset",
                                                WebkitTextFillColor: pwgDbCheck === "pending" ? "transparent" : pwgDbCheckFound ? "#46B146" : "#A20100",
                                            }}
                                        >
                                            CHECKING THROUGH PWG DATABASE DATA
                                        </span>
                                    }
                                    style={{
                                        color: "#3957ED",
                                        boxShadow: "-.1rem -.1rem .5rem 0rem #9CC8FF, .1rem .1rem .5rem 0rem #9CC8FF",
                                    }}
                                />
                                <Button
                                    iconPos="right"
                                    className="coveragebutton mt-4"
                                    type="button"
                                    icon={uspsCheck === "pending" ? "pi pi-spin pi-spinner" : uspsCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"}
                                    label={
                                        <span
                                            style={{
                                                background: uspsCheck === "pending" ? "linear-gradient(90deg, rgba(138, 237, 246, 0.905882) 0%, #60A2F5 100%)" : "none",
                                                WebkitBackgroundClip: uspsCheck === "pending" ? "text" : "unset",
                                                WebkitTextFillColor: uspsCheck === "pending" ? "transparent" : uspsCheckFound ? "#46B146" : "#A20100",
                                            }}
                                        >
                                            CHECKING THROUGH USPS
                                        </span>
                                    }
                                    style={{
                                        color: "#3957ED",
                                        boxShadow: "-.1rem -.1rem .5rem 0rem #9CC8FF, .1rem .1rem .5rem 0rem #9CC8FF",
                                    }}
                                /> */}
                            </div>
                        ) : (
                            ""
                        )}
                        {/* <Dialog
                            header={pwgApiCheckFound && pwgDbCheckFound && uspsCheckFound ? "Great news!" : !pwgApiCheckFound && !pwgDbCheckFound && !uspsCheckFound ? "Unfortunately" : !pwgApiCheckFound || !pwgDbCheckFound || !uspsCheckFound ? "Proceed with Limited Coverage?" : null}
                            closable={false}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "70rem",
                                height: "auto",
                                backgroundColor: "#E0EDFF",
                            }}
                            onHide={() => {
                                setShowCheckCoverage(false);
                                setIsLoading(false);
                                setPwgApiCheck("pending");
                                setPwgApiCheckFound(true);
                                setPwgDbCheck("pending");
                                setPwgDbCheckFound(true);
                                setUspsCheck("pending");
                                setUspsCheckFound(true);
                            }}
                            visible={pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending"}
                        >
                            {pwgApiCheckFound && pwgDbCheckFound && uspsCheckFound ? (
                                <>
                                    <h3 className="flex w-full justify-content-center" style={{ color: "#46B146", fontSize: "1.2rem", fontWeight: "700" }}>
                                        Coverage is available in this area
                                    </h3>
                                    <p style={{ color: "#a20100", fontSize: "1.4rem", fontWeight: "600", color: "#666666" }}>Do you want to proceed with the enrollment?</p>
                                </>
                            ) : null}
                            {!pwgApiCheckFound && !pwgDbCheckFound && !uspsCheckFound ? (
                                <>
                                    <h3 className="flex w-full justify-content-center" style={{ color: "#a20100", fontSize: "1.2rem", fontWeight: "700" }}>
                                        coverage is not available in this area at this time
                                    </h3>
                                    <p style={{ color: "#a20100", fontSize: "1.4rem", fontWeight: "600", color: "#666666" }}>Would you still like to proceed?</p>
                                </>
                            ) : null}
                            {(!pwgApiCheckFound || !pwgDbCheckFound || !uspsCheckFound) && (pwgApiCheckFound || pwgDbCheckFound || uspsCheckFound) ? (
                                <>
                                    <h3 className="flex w-full justify-content-center" style={{ color: "#a20100", fontSize: "1.4rem", fontWeight: "700" }}>
                                        Proceed with Limited Coverage?
                                    </h3>
                                    <p style={{ color: "#a20100", fontSize: "1.2rem", fontWeight: "600", color: "#666666" }}>Based on the results, coverage is not available for this ZIP Code</p>
                                    <p style={{ color: "#a20100", fontSize: "1.4rem", fontWeight: "600", color: "#666666" }}>Would you still like to proceed?</p>
                                </>
                            ) : null}

                            <div className="mt-4 flex flex-wrap flex-row justify-content-center dialogmain">
                                <button
                                    className={noButtonColor ? "nobuttontrue" : "nobutton"}
                                    onMouseEnter={() => setNoButtonColor(true)}
                                    onMouseLeave={() => setNoButtonColor(false)}
                                    onClick={() => {
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                        setNoButtonColor(false);
                                    }}
                                    style={{ width: "5.1rem", border: ".1rem solid #c6dfff", height: "3.4rem" }}
                                >
                                    No
                                </button>
                                <button
                                    onClick={() => {
                                        if (pwgApiCheckFound || pwgDbCheckFound || uspsCheckFound) {
                                            setInitiateActive(false);
                                            setPersonalInfoActive(true);
                                            setZipVerified(false);
                                        } else {
                                            const serviceProvider = parseLoginRes?.company;
                                            const department = parseLoginRes?.department;
                                            const csr = parseLoginRes?._id;
                                            const carrier = "6455532566d6fad6eac59e34";
                                            const dataToSend = { serviceProvider, csr, department, carrier, ...formik.values, accountType: "Prepaid" };

                                            Axios.post(`${BASE_URL}/api/user/withoutzip`, dataToSend)
                                                .then((res) => {
                                                    setParentDetails({
                                                        ...res?.data?.data,
                                                        accountType: "Prepaid",
                                                    });
                                                    setInitiationData({
                                                        ...res?.data?.data,
                                                        accountType: "Prepaid",
                                                    });
                                                    setZipVerified(true);
                                                    setPersonalInfoActive(true);
                                                    setInitiateActive(false);
                                                })
                                                .catch((err) => {});
                                        }
                                    }}
                                    onMouseEnter={() => setButtonColor(true)}
                                    onMouseLeave={() => setButtonColor(false)}
                                    className={buttonColor ? "yesbuttontrue" : "yesbutton"}
                                    style={{ width: "5.7rem", border: ".1rem solid #c6dfff", height: "3.4rem" }}
                                >
                                    Yes
                                </button>
                              
                            </div>
                        </Dialog> */}
                        {/* <Button
                            label="Next"
                            onClick={() => {
                                if (pwgApiCheckFound || pwgDbCheckFound || uspsCheckFound) {
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                    setZipVerified(false);
                                } else {
                                    const serviceProvider = parseLoginRes?.company;
                                    const department = parseLoginRes?.department;
                                    const csr = parseLoginRes?._id;
                                    const carrier = "6455532566d6fad6eac59e34";
                                    const dataToSend = { serviceProvider, csr, department, carrier, ...formik.values, accountType: "Prepaid" };

                                    Axios.post(`${BASE_URL}/api/user/withoutzip`, dataToSend)
                                        .then((res) => {
                                            setParentDetails({
                                                ...res?.data?.data,
                                                accountType: "Prepaid",
                                            });
                                            setInitiationData({
                                                ...res?.data?.data,
                                                accountType: "Prepaid",
                                            });

                                            //localStorage.setItem("izZipVerified", "no");
                                            setZipVerified(true);
                                            setPersonalInfoActive(true);
                                            setInitiateActive(false);
                                        })
                                        .catch((err) => {});
                                }
                            }}
                        /> */}
                    </form>
                </div>
            </div>
        </div>
    );
}
