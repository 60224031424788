import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { Backbtn, Export, ExportHover, Filter, Import, ImportHover, Nextbtn, Sortby } from "../../../../../utility";
import "./completed.css";
import CommonLoaderBlue from "../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Postpaid_Completed_Enrollments = () => {
    // State For Select Row
    const [selectedEnrollments, setSelectedEnrollments] = useState(null);
    const [rowClick, setRowClick] = useState(true);
    const [createDateToFilterValue, setCreatedDateToFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enrollment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdFilter: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        createdTo: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    });
    const [enrollmentIdFilterValue, setEnrollmentIdFilterValue] = useState("");
    const [createDateFilterValue, setCreatedDateFilterValue] = useState("");
    const [allCompletedEnrollments, setAllCompletedEnrollments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [hover, setHover] = useState(false);
    const [hoverLeave, setHoverLeave] = useState(false);
    const [hoverAfter, setHoverAfter] = useState(false);
    const [hoverLeaveAfter, setHoverLeaveAfter] = useState(false);

    const [customerSearchName, setCustomerSearchName] = useState("");
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [enrollmentFrom, setEnrollmentFrom] = useState(1);
    const [enrollmentTo, setEnrollmentTo] = useState(10);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [selectedNumberToShow, setSelectedNumberToShow] = useState(10);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const onGlobalFilterValueChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onNameDateEnrollmentIdValueFilter = (e, field) => {
        const value = e.target.value;
        let _filters = { ...filters };
        if (field === "enrollment") {
            _filters["enrollment"].value = value;
            setFilters(_filters);
            setEnrollmentIdFilterValue(value);
        } else if (field === "createdTo") {
            const parsedDate = DateTime.fromJSDate(new Date(e.value));
            let easternDateTime = parsedDate.setZone("America/New_York", { keepLocalTime: true });
            easternDateTime = easternDateTime.set({
                hour: 23,
                minute: 59,
                second: 0,
            });

            const formattedEasternTime = easternDateTime.toFormat("d LLL yyyy, h:mm a");
            const etDateObject = DateTime.fromFormat(formattedEasternTime, "d LLL yyyy, h:mm a", { zone: "America/New_York" });
            const value2 = etDateObject.toSeconds();
            setCreatedDateToFilterValue(e.value);
            _filters["createdTo"].value = value2;
            setFilters(_filters);
        } else {
            const parsedDate = DateTime.fromJSDate(new Date(e.value));
            const easternDateTime = parsedDate.setZone("America/New_York", { keepLocalTime: true });
            const formattedEasternTime = easternDateTime.toFormat("d LLL yyyy, h:mm a");
            const etDateObject = DateTime.fromFormat(formattedEasternTime, "d LLL yyyy, h:mm a", { zone: "America/New_York" });
            const value = etDateObject.toSeconds();

            setCreatedDateFilterValue(e.value);
            _filters["createdFilter"].value = value;
            setFilters(_filters);
        }
    };

    const navigate = useNavigate();

    const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };

    // const rowExpansionTemplate = (data) => {
    //     return (
    //         <div>

    //   <DataTable value={[data]} stripedRows >

    //                 <Column field="plan.name" header="Plan Name" />
    //                 <Column field="plan.price" header="Plan Price" />
    //                 <Column field="Phonecost" header="Phone Cost" />
    //                 <Column field="Amountpaid" header="Amount Paid by Customer" />
    //                 <Column field="Postingdate" header="Posting Date" />
    //                 <Column field="EsnNumber" header="ESN Number" />

    //                 <Column field="Activationcall" header="Activation Call" />
    //                 <Column field="Activationcalldatetime" header="Activation Call Date Time" />

    //                 <Column field="Handover" header="Handover Equipment" />

    //                 <Column field="Enrolltype" header="Enroll Type" />
    //                 <Column field="Reviewernote" header="Reviewer Note" />
    //             </DataTable>
    //         </div>
    //     );
    // };

    // Get user data from ls
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    // Get role name  from login response
    const roleName = parseLoginRes?.role?.role;
    const toCapital = roleName ? roleName.toUpperCase() : "DEFAULT_VALUE";

    const getAllCompletedEnrollments = async () => {
        setIsLoading(true);
        try {
            const res = await Axios.get(`${BASE_URL}/api/user/prePostCompletedEnrollmentsList?userId=${parseLoginRes?._id}&accountType=Postpaid`);
            if (res?.status === 200 || res?.status === 201) {
                const updatedData = res?.data?.data.map((item) => ({
                    ...item,
                    enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                    name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                    createdDate: new Date(item.createdAt)
                        .toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                        })
                        .replace(/\//g, "-"),
                    createdFilter: DateTime.fromFormat(item.createdAt, "d LLL yyyy, h:mm a", { zone: "America/New_York" }).toSeconds(),
                    createdTo: DateTime.fromFormat(item.createdAt, "d LLL yyyy, h:mm a", { zone: "America/New_York" }).toSeconds(),
                }));
                // Pagination logic
                setAllCompletedEnrollments(updatedData.slice(0, 10)); // Initially display first 10 records
                setCompleteListEnrollment(updatedData); // Full list for manual pagination
                setTotalPage(Math.ceil(updatedData.length / 10)); // Total number of pages

                setIsLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCompletedEnrollments();
    }, []);

    const actionTemplate = (rowData) => {
        return (
            <div className="outeractionbutton">
                <div className="inneractionbutton">
                    <span className="flex justify-content-start align-items-center" style={{ gap: ".5rem" }}>
                        <span
                            className="-ml-2"
                            style={{
                                display: "inline-block",
                                width: "1.2rem",
                                height: "1.2rem",
                                borderRadius: "1.2rem",
                                backgroundColor: "#46b146",
                                boxShadow: ".2rem .2rem .3rem .0rem #aeaec040, .0rem .1rem .4rem .0rem #aeaec040",
                                marginRight: ".8rem", // Added for spacing between circle and text
                            }}
                        ></span>
                        <span
                            className="-ml-2"
                            style={{
                                fontSize: "1.0rem",
                                fontWeight: "500",
                                color: "#163c16",
                            }}
                        >
                            Enable
                        </span>
                    </span>
                </div>
            </div>
        );
    };
    return (
        <div className="postpaidcompleted">
            <ToastContainer />
            <div className="flex w-full flex-column justify-content-start h-4" style={{ marginBottom: "1.5rem" }}>
                <h3 className="heading">Completed Enrollments</h3>
                <div className="flex  justify-content-left mt-2 w-full">
                    <div className="flex flex-wrap w-full justify-content-between ">
                        <div className="flex">
                            <InputText
                                className="searchbar"
                                value={customerSearchName}
                                onChange={(e) => {
                                    setCustomerSearchName(e.target.value);
                                    setCurrentPage(1);

                                    if (e.target.value.length > 0) {
                                        let searchResult = completeListEnrollment.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

                                        setAllCompletedEnrollments(searchResult);
                                        setTotalIncaseSearch(searchResult.length);
                                        setTotalSearchResult(searchResult);
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(searchResult.length > 10 ? 10 : searchResult.length);
                                    } else {
                                        setTotalSearchResult([]);
                                        setAllCompletedEnrollments(completeListEnrollment.slice(0, 10));
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(completeListEnrollment.length > 10 ? 10 : completeListEnrollment.length);
                                    }
                                }}
                                placeholder="Search Enrollment"
                            />{" "}
                        </div>
                        <div className="flex">
                            <button
                                onMouseEnter={() => {
                                    setHover(true);
                                    setHoverLeave(false);
                                }}
                                onMouseLeave={() => {
                                    setHover(false);
                                    setHoverLeave(true);
                                }}
                                className="importbtn"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <div className={hover ? "importexternal" : hoverLeave ? "importexternalleave" : ""}></div>
                                <span className="flex w-full justify-content-center align-items-center">
                                    {hover ? (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(160deg)" }}>
                                            <ImportHover />
                                        </span>
                                    ) : (
                                        <span>
                                            <Import style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(0deg)" }} />
                                        </span>
                                    )}
                                    &nbsp; Import
                                </span>
                            </button>
                            <button
                                onMouseEnter={() => {
                                    setHoverAfter(true);
                                    setHoverLeaveAfter(false);
                                }}
                                onMouseLeave={() => {
                                    setHoverAfter(false);
                                    setHoverLeaveAfter(true);
                                }}
                                className="exportbtn cursor-pointer"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <div className={hoverAfter ? "exportexternal" : hoverLeaveAfter ? "exportexternalleave" : ""}></div>
                                <span className="flex w-full justify-content-center align-items-center">
                                    {hoverAfter ? (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(160deg)" }}>
                                            <ExportHover />
                                        </span>
                                    ) : (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(0deg)" }}>
                                            <Export />
                                        </span>
                                    )}
                                    &nbsp; Export
                                </span>
                            </button>
                            <button className="sortbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                <span>
                                    <Sortby />
                                </span>
                                &nbsp; Sort by
                            </button>
                            <button className="filterbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                <span>
                                    <Filter />
                                </span>
                                &nbsp; Filter
                            </button>
                        </div>
                        <div className="flex">
                            <span className="flex align-items-center ml-6">
                                <span style={{ fontSize: "1.4rem" }} className="flex align-items-center ">
                                    Result per page
                                    <button style={{ backgroundColor: "#FFFFFF33" }} className="resultbtn">
                                        {selectedNumberToShow}
                                    </button>
                                    &nbsp; {enrollmentFrom}-{enrollmentTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                                </span>
                            </span>
                            &nbsp;
                            <button
                                onClick={() => {
                                    const itemsPerPage = 10;
                                    const calculateTotalPages = (data) => Math.ceil(data.length / itemsPerPage);

                                    const handlePageNavigation = (data, setDataFn) => {
                                        const totalPages = calculateTotalPages(data);
                                        if (currentPage > 1) {
                                            let startIdx, endIdx;

                                            if (totalPages - 1 === currentPage) {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else if (totalPages === currentPage) {
                                                startIdx = data.length - (data.length % itemsPerPage) - itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            }

                                            setDataFn(data.slice(startIdx, endIdx));
                                            setCurrentPage((prev) => prev - 1);
                                            setEnrollmentFrom(startIdx + 1);
                                            setEnrollmentTo(endIdx);
                                        }
                                    };

                                    if (customerSearchName.length > 0) {
                                        handlePageNavigation(totalSearchResult, setAllCompletedEnrollments);
                                    } else {
                                        handlePageNavigation(completeListEnrollment, setAllCompletedEnrollments);
                                    }
                                }}
                                className="cursor-pointer backbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <Backbtn />
                            </button>
                            <button
                                style={{ backgroundColor: "#FFFFFF33" }}
                                onClick={() => {
                                    if (customerSearchName.length > 0) {
                                        let totalPages = Math.ceil(totalSearchResult.length / 10);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 10;
                                            if (totalPages - 1 === currentPage) {
                                                setAllCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(totalSearchResult.length);
                                            } else {
                                                setAllCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 10));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 10);
                                            }
                                        }
                                    } else {
                                        let totalPages = Math.ceil(completeListEnrollment.length / 10);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 10;
                                            if (totalPages - 1 === currentPage) {
                                                setAllCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(completeListEnrollment.length);
                                            } else {
                                                setAllCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 10));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 10);
                                            }
                                        }
                                    }
                                }}
                                className="cursor-pointer nextbutton"
                            >
                                <Nextbtn />
                            </button>
                        </div>
                        {/* <div
                            className="cal"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "43%",
                            }}
                        >
                            <span style={{ fontWeight: "400", paddingLeft: "2%" }}>From</span>
                            <Calendar
                                value={createDateFilterValue}
                                dateFormat="mm/dd/yy"
                                // placeholder="Start Date"
                                onChange={(e) => {
                                    onNameDateEnrollmentIdValueFilter(e, "createdAt");
                                }}
                                showIcon
                                style={{ height: "3.0rem", width: "40%" }}
                            />
                            <span style={{ fontWeight: "400" }}>To</span>
                            <Calendar
                                value={createDateToFilterValue}
                                dateFormat="mm/dd/yy"
                                // placeholder="End Date"
                                onChange={(e) => {
                                    onNameDateEnrollmentIdValueFilter(e, "createdTo");
                                }}
                                showIcon
                                style={{ height: "3.0rem", border: "none", width: "40%" }}
                            />
                        </div> */}
                    </div>
                </div>
            </div>
            <div>
                <DataTable
                    selectionMode={rowClick ? null : "checkbox"}
                    selection={selectedEnrollments}
                    onSelectionChange={(e) => setSelectedEnrollments(e.value)}
                    value={allCompletedEnrollments}
                    filters={filters}
                    globalFilterFields={["enrollmentId", "name"]}
                    // header={header}
                    stripedRows
                    resizableColumns
                    size="small"
                    emptyMessage={
                        isLoading ? (
                            <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">
                                <CommonLoaderBlue customSize={16} />
                            </div>
                        ) : (
                            "Enrollment Not Found"
                        )
                    }
                >
                    {/* <Column expander style={{ width: "3em" }} /> */}
                    {/* <Column header="#" field="SNo"></Column> */}
                    <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

                    <Column
                        header="Enrollment ID"
                        field="enrollmentId"
                        body={(rowData) => (
                            <button style={{ fontSize: "1.272rem", fontWeight: "500", color: "#5E79FF", border: "none", backgroundColor: "transparent", cursor: "pointer" }} onClick={() => handleEnrollmentIdClick(rowData)}>
                                {rowData.enrollmentId}
                            </button>
                        )}
                    ></Column>
                    <Column header="Name" field="name"></Column>
                    <Column field="contact" header="Contact" />
                    <Column
                        header="Status"
                        body={(rowData) => (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                <span
                                    style={{
                                        display: "inline-block",
                                        width: ".9rem",
                                        height: ".9rem",
                                        borderRadius: "50%",
                                        backgroundColor: rowData?.status === "active" ? "#46B146" : rowData?.status === "disconnected" ? "#A20100" : rowData?.status === "suspended" ? "#EDA145" : "transparent",
                                        marginRight: ".5rem",
                                    }}
                                />
                                {rowData?.status ? rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1).toLowerCase() : ""}
                            </div>
                        )}
                    />
                    <Column field="createdBy.name" header="Created By" />
                    {/* {toCapital.includes("PROVISION") ? <Column field="activatedBy.name" header="Activated By" /> : ""}
                    {toCapital.includes("PROVISION") ? (
                        <Column
                            field="activatedAt"
                            header="Activated At"
                            body={(rowData) => {
                                if (rowData?.activatedAt) {
                                    return new Date(rowData.activatedAt)
                                        .toLocaleDateString("en-US", {
                                            month: "2-digit",
                                            day: "2-digit",
                                            year: "numeric",
                                        })
                                        .replace(/\//g, "-");
                                }
                                return null; // Handle the case when activatedAt is not available
                            }}
                        />
                    ) : (
                        ""
                    )} */}
                    <Column field="createdDate" header="Created At" />
                    <Column
                        header="Linked"
                        body={(rowData) => {
                            return <p className={rowData?.linkedAccount ? "yes" : "no"}>{rowData?.linkedAccount ? "Yes" : "No"}</p>;
                        }}
                    />
                    <Column header="Actions" body={actionTemplate}></Column>

                    {/* <Column
                        field="Phase"
                        header="Phase"
                        body={(rowData) => (
                            <span>
                                {rowData.assignedToUser.map((user) => (
                                    <span key={user?.department?.department}>{user?.department?.department}</span>
                                ))}
                            </span>
                        )}
                    /> */}
                </DataTable>
            </div>
            <br />
        </div>
    );
};

export default Postpaid_Completed_Enrollments;
