import React, { useRef, useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Axios from "axios";
import moment from "moment";
import AreaChartFilterDialog from "./Area-Chart-Filter-Dialog";
import CommonLoaderBlue from "../../../../components/Loader/LoaderBlue";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, ChartDataLabels, Legend, Filler);
 
const AreaChart = ({ shortSelection, setShortSelection, setSelectedRange, selectedRange }) => {
    const [allEnrollmentStat, setAllEnrollmentStat] = useState(0);   
    const [apiHit,setApiHit]=useState(false)
    const [enrollmentQueueData, setEnrollmentQueueData] = useState([]);
    const [enrollmentQueueChartLabels, setEnrollmentQueueChartLabels] = useState([]);
    const [completedEnrollmentsData, setCompletedEnrollmentsData] = useState([]);
    const [completedEnrollmentsLabels, setCompletedEnrollmentsLabels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);     
        
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/dashboard/getAllEnrollments?accountType=Prepaid`, {
            params: {
                filter: shortSelection,
                startDate: moment(selectedRange[0])?.isValid() ? moment(selectedRange[0])?.format("MM-DD-YYYY") : null,
                endData: moment(selectedRange[1])?.isValid() ? moment(selectedRange[1])?.format("MM-DD-YYYY") : null,
            },
        })
            .then((res) => {
                const enrollmentQueueLabels = res.data.data.EnrollmentQueue.map((item) => item.month);
                const enrollmentQueueData = res.data.data.EnrollmentQueue.map((item) => item.totalEnrollments);
                const completedEnrollmentLabels = res.data.data.CompletedEnrollment.map((item) => item.month);
                const completedEnrollmentData = res.data.data.CompletedEnrollment.map((item) => item.totalEnrollments);
                // Combine and deduplicate labels
                const allLabels = Array.from(new Set([...enrollmentQueueLabels, ...completedEnrollmentLabels]));
                if(completedEnrollmentData.length > enrollmentQueueData.length){ 
                    for(let i=enrollmentQueueData.length;i<completedEnrollmentData.length;i++){ 
                        enrollmentQueueData.push(0)               
                    }
                      
                     
                  }          
                  else if(completedEnrollmentData.length < enrollmentQueueData.length){ 
                    for(let i=completedEnrollmentData.length;i<enrollmentQueueData.length;i++){ 
                      completedEnrollmentData[i]=0                  
                    }
                  }      
                setEnrollmentQueueData(enrollmentQueueData);
                setEnrollmentQueueChartLabels(allLabels);
                setCompletedEnrollmentsData(completedEnrollmentData);
                setCompletedEnrollmentsLabels(allLabels);
                setAllEnrollmentStat(res?.data?.data?.totalEnrollment || 0);     
                setApiHit(true)
               
            })
            .catch((err) => {
                setEnrollmentQueueData([]);
                setEnrollmentQueueChartLabels([]);
                setCompletedEnrollmentsData([]);
                setCompletedEnrollmentsLabels([]);
                setAllEnrollmentStat(0);
                setIsLoading(false); 
                setApiHit(true)
            });
    }, [shortSelection, selectedRange]);

    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const updateChart = () => {
        const chart = chartRef.current;
        if (chart) {
            const ctx = chart.ctx;
            const fontSize = window.innerHeight < 639 ? 8 : window.innerHeight < 800 ? 10 : 12; // Dynamic font size
            const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
            gradient1.addColorStop(0, "rgba(52, 152, 219, 1)");
            gradient1.addColorStop(0.2, "rgba(52, 152, 219, 0.2)");
            const gradient2 = ctx.createLinearGradient(0, 0, 0, 400);
            gradient2.addColorStop(0, "rgba(26, 188, 156, 1)");
            gradient2.addColorStop(0.2, "rgba(26, 188, 156, 0.2)");

            setChartData({
                labels: Array.from(new Set([...enrollmentQueueChartLabels, ...completedEnrollmentsLabels])),
                datasets: [
                    {
                        label: "Enrollment Queue",
                        data: enrollmentQueueData,
                        borderColor: gradient1,
                        backgroundColor: gradient1,
                        fill: true,
                        pointRadius: 0,
                        tension: 0.4,
                        borderWidth: 1,
                    },
                    {
                        label: "Completed Enrollments",
                        data: completedEnrollmentsData,
                        borderColor: gradient2,
                        backgroundColor: gradient2,
                        fill: true,
                        pointRadius: 0,
                        borderWidth: 1,
                        tension: 0.4,
                    },
                ],
            });
            chart.options.scales.x.ticks.font.size = fontSize;
            chart.options.scales.y.ticks.font.size = fontSize;
            chart.update();
        }
       
    };

    useEffect(() => {
        if (enrollmentQueueChartLabels.length && completedEnrollmentsLabels.length) {
            updateChart();  
            setIsLoading(false);
        } 
        else { 
            if(apiHit){    
                updateChart();
                setIsLoading(false)
            }
        }
        const handleResize = () => {
            updateChart();          

        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [enrollmentQueueChartLabels, completedEnrollmentsLabels, enrollmentQueueData, completedEnrollmentsData]);

    const options = {
        responsive: true,
        devicePixelRatio: window.devicePixelRatio || 1,
        layout: {
            padding: {
                top: 20,
            },
        },
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: true,      
                font: {
                    size: 14, // Custom font size
                    family: 'inter', // Optional: specify a custom font family
                    weight: 'bold', // Optional: specify font weight
                  },   
                  titleFont: {
                      family: 'Inter', // Set font family for tooltip title
                      size: 13, // Font size for title
                      weight: '400', // Font weight for title
                    },   
                    bodyFont: {
                      family: 'Inter', // Set font family for tooltip body
                      size: 12, // Font size for body
                    },
                    footerFont: {
                      family: 'Inter', // Set font family for tooltip footer
                      size: 12, // Font size for footer
                    },
            },
            datalabels: {
                display: false,
                color: "#666666",
                align: "top",
                font: {
                    weight: "400",
                    size: window.innerHeight < 639 ? 8 : window.innerHeight < 800 ? 10 : 12, // Initial font size setting
                    family: "Inter",
                },
                formatter: function (value) {
                    return value;
                },
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    font: {
                        family: "Inter",
                        size: window.innerHeight < 639 ? 8 : window.innerHeight < 800 ? 10 : 12, // X-axis dynamic font size
                    },
                    color: "#666666",
                    drawTicks: false,
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
                borderColor: "transparent",
            },
            y: {
                beginAtZero: true,
                ticks: {
                    font: {
                        family: "Inter",
                        size: window.innerHeight < 639 ? 8 : window.innerHeight < 800 ? 10 : 12, // Y-axis dynamic font size
                    },
                    color: "#666666",
                    drawTicks: false,
                },
                grid: {
                    drawBorder: false,
                    color: "#66666612",
                },
                borderColor: "transparent",
            },
        },
    };

    const [showDateFilter, setShowDateFilter] = useState(false);

    return (
        <div className="area-chart-main-here w-full h-full">
            <div className="area-chart-main-header flex flex-wrap flex-row justify-content-between align-items-center">
                <p className="total-enrollment-header">Total Enrollment</p>
                <div>
                    <p
                        onClick={() => {
                            setShowDateFilter((prev) => !prev);
                        }}
                        className="cursor-pointer date-filter-chart"
                    >
                        Date Filter
                    </p>
                    {showDateFilter ? <AreaChartFilterDialog setShowDateFilter={setShowDateFilter} shortSelection={shortSelection} setShortSelection={setShortSelection} setSelectedRange={setSelectedRange} selectedRange={selectedRange} /> : null}
                </div>
            </div>
            <h6 className="all-enrollment-area-chart">{allEnrollmentStat}</h6>
            <div className="flex flex-wrap area-chart-info flex-row justify-content-between align-items-start">
                <h1>
                    You gained <span>40,034</span> last 6 months. That is the best result in last 2 years.
                </h1>
                <div className="chart-legend">
                    <div className="flex flex-wrap flex-row justify-content-start align-items-center">
                        <div className="area-legend-circle1"></div>
                        <h5>Enrollment Queue</h5>
                    </div>
                    <div className="flex flex-wrap flex-row justify-content-start align-items-center">
                        <div className="area-legend-circle2"></div>
                        <h5>Completed Enrollments</h5>
                    </div>
                </div>
            </div>
            <div className="area-chart-main">
                {isLoading ? (
                    <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">
                        <CommonLoaderBlue sizehere={20} color={"rgb(78, 105, 251)"} />
                    </div>
                ) : (
                    ""
                )}{" "}
                <Line ref={chartRef} data={chartData} options={options} />
            </div>
        </div>
    );
};

export default AreaChart;
