import "./Carrier-Earning.css";
import React from "react";
import CarrierChart from "./CarrierEarningComponents/Carrier-Chart";
import EarningChart from "./CarrierEarningComponents/Earning-Chart";
export default function CarrierEarningChart({ shortSelection, selectedRange }) {
    return (
        <div className="carrier-earning-chart">
            <CarrierChart shortSelection={shortSelection} selectedRange={selectedRange} />
            <EarningChart shortSelection={shortSelection} selectedRange={selectedRange} />
        </div>
    );
}
