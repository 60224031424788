import React, { useRef } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function BankOfAmericaDummy() {
    const timeout = useRef(null); // Initialize a useRef to store the timeout ID
    const navigate = useNavigate(); // Get the navigate function from react-router-dom

    useEffect(() => {
        // Set the timeout
        timeout.current = setTimeout(() => {
            navigate("/payment-success");
        }, 1000);

        // Cleanup function to clear the timeout when the component unmounts
        return () => {
            clearTimeout(timeout.current);
        };
    }, [navigate]);
    return <h1>Bank Of america</h1>;
}
