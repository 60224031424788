import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MenuIcon, SidebarSelect } from "./assets/svgs/svg-assets-main";
import "./AppMenu.css";

export const AppMenu = ({ setMobileMenuActive, setSearchByValueClick, setSearchBy, model}) => {
    const location = useLocation();

    useEffect(() => {
        // Ensure model exists and is an array with items
        if (model && model.length > 0 && model[0]?.items) {
            model[0].items.forEach((element, index) => {
                element.items.forEach((element2) => {
                    if (element2?.to === location?.pathname) {
                        // Fix: Wrap these in braces to ensure no-unused-expressions
                        setCurrentActive(index);
                        setCurrentLocation(element2?.to);
                    }
                });
            });
        }
        if (location.pathname === "/") {
            setCurrentActive();
            setCurrentLocation();
        }
    }, [location, model]);

    let navigate = useNavigate();
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const capitalCompanyName = parseLoginRes?.companyName?.toUpperCase();
    const [currentLocation, setCurrentLocation] = useState();
    const [currentActive, setCurrentActive] = useState();
    return (
        <div className="layout-menu-container sidebar-wrapper ">
        
            {/* <AppSubmenu items={props.model} activeTab={props.activeTab} className="layout-menu" root={true} role="menu" />     */}
            <div className="w-full main-menu  flex flex-wrap flex-row justify-content-center align-content-start align-items-start" style={{ marginTop: "60px" }}>
                {model[0]?.items?.map((element, index) => {
                    return (
                        <div className="cursor-pointer sidebar-container  flex flex-wrap flex-row align-items-center justify-content-between">
                            <div
                                onClick={() => {
                                    if (currentActive !== index) {
                                        setCurrentActive(index);
                                    } else {
                                        setCurrentActive();
                                        setCurrentLocation();
                                        navigate("/");
                                    }
                                }}
                                className=" w-full flex flex-wrap flex-row align-items-center justify-content-between"
                            >
                                <div className=" main-sidebar-item-container flex flex-wrap flex-row align-items-center justify-content-start">
                                    <MenuIcon />
                                    {currentActive === index ? (
                                        <div className="sidebar-svg-design">
                                            <SidebarSelect />
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <span className="main-sidebar-item-label">{element.label}</span>
                                </div>
                                {element.items && <i className=" dropdown-svg pi pi-fw pi-angle-down menuitem-toggle-icon"></i>}
                            </div>
                            {currentActive === index ? (
                                <div className="w-full flex flex-wrap flex-row justify-content-center" style={{ position: "relative", marginTop: "10px" }}>
                                    <hr className="sidebar-hr-gradient" />

                                    {element.items?.map((element2) => {
                                        return (
                                            <div className="main-dropdown-list w-full mt-4 flex flex-wrap flex-row align-items-center justify-content-between">
                                                <div
                                                    onClick={(e) => {
                                                        // setCurrentActive(index);
                                                        //e.stopPropagation();
                                                        e.stopPropagation();
                                                        if (window.innerWidth < 978) {
                                                            setMobileMenuActive(false);
                                                        }
                                                        navigate(element2?.to);
                                                        setCurrentLocation(element2?.to);
                                                    }}
                                                    style={{ gap: "10px" }}
                                                    className="flex flex-wrap flex-row align-items-center justify-content-start"
                                                >
                                                    <svg className="svg-indicator" xmlns="http://www.w3.org/2000/svg">
                                                        <defs>
                                                            <linearGradient id="gradientStroke" x1="0%" y1="0%" x2="100%" y2="0%">
                                                                <stop offset="0%" stopColor="rgba(237, 174, 249, 0.905)" />
                                                                <stop offset="100%" stopColor="#81B1FA" />
                                                            </linearGradient>
                                                        </defs>
                                                        <path d="M 2 0 Q 2 13 17 11" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round" stroke="url(#gradientStroke)" />
                                                    </svg>
                                                    {currentLocation === element2.to ? <div className="selected-route"></div> : undefined}
                                                    {
                                                        //<MenuIcon />
                                                    }
                                                    <span className={`${currentLocation === element2.to ? "selected-span-sidebar" : ""} sidebar-dropdown-menu-span`}>{element2.label}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
