import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import "./style/stripe_payment_form.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Axios from "axios";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import PaymentStripModuleAlternateCard from "../../Alternate card Dialogs/stripe_payment";
//import AlternateCardPaymentStripModule from "../../AlternateCardAutoPay/stripe_payment_dialog/stripe_payment";
const BASE_URL = process.env.REACT_APP_BASE_URL; 
 let parseLoginRes=JSON.parse(localStorage.getItem("userData"))
export default function PaymentStripeForm({
    parentDetails,
    initiationData,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
}) {
    const submitbuttonref = useRef(null);
    const [cardNumber, setCardNumber] = useState("");
    const [cvcNumber, setCvcNumber] = useState("");
    const [cardMonth, setCardMonth] = "";
    const [cardYear, setCardYear] = useState("");
    const [autoPayDate, setAutoPayDate] = useState("");
    const [alternateCardToken, setAlternateCardToken] = useState("");
    const [alternateCardDetailVisibility, setAlternateCardDetailVisibility] = useState(false);
    const [autoPay, setAutoPay] = useState(true);
    const stripe = useStripe();
    const [disableSubmit, setDisableSubmit] = useState(false);
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const toast = useRef(null);
    const elements = useElements();
    const handleSubmit = async (event) => {
        submitbuttonref.current.style.opacity = "0.5";
        setDisableSubmit(true);
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });

        if (error) {
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                customerId: initiationData?._id,
                paymentMethod: "Credit Card",
                stripeId: paymentIntent?.id,
                action: "failure",
                reason: "failure",
                transactionStatus: paymentIntent?.status,
                paymentStatus: "failure",
                amount: paid,  
                userId: parseLoginRes?._id,
            })
                .then((res) => {
                    toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                })
                .catch((err) => {
                    toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                });
             toast.current.show({ severity: "error", summary: "Payment Processing", detail: <p>{error?.message ? error?.message:"An error occurred while processing the payment"}</p> });

            setTimeout(() => {
                setPaymentDialogVisibility(false);
            }, 2000);
        } else {   
            toast.current.show({ severity: "success", summary: "Payment Processed", detail: <p>{paymentIntent?.status}</p> });
                            
            
            if (alternateCardToken === "") {
                stripe
                    .createToken(elements.getElement(CardElement))
                    .then(function (result) {
                        const token = result?.token?.id;
                        const datatosendincreateAccount = {
                            name: `${parentDetails?.firstName} ${parentDetails?.lastName}`,
                            email: `${parentDetails?.email}`,
                            phone: `+1${parentDetails?.contact}`,
                            description: "New customer account",
                        };
                        Axios.post(`${BASE_URL}/api/bannerRouter/create_account`, datatosendincreateAccount)
                            .then((customercreation) => {
                              
                                let customerId = customercreation?.data?.data?.id;
                                setPaymentDone(true);
                                //setActiveIndex(3);
                                let additionalFeature = [];
                                let discounts = [];
                                let dueDate = "";
                                let applyLateFee = "";
                                let oneTimeCharge = "";
                                let planName = "";
                                let planId = "";
                                let planCharges = "";
                                dueDate = currentBillingConfig?.dueDate;
                                oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                                applyLateFee = currentBillingConfig?.applyLateFee;
                                let selectedDiscounts = paymentScreenDetails.discount;

                                for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                                    for (let k = 0; k < selectedDiscounts.length; k++) {
                                        if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                            let obj = {
                                                name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                                amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                            };
                                            discounts.push(obj);
                                        }
                                    }
                                }
                                for (let i = 0; i < allplans?.length; i++) {
                                    if (object.plan === allplans[i]?._id) {
                                        planName = allplans[i]?.name;
                                        planCharges = allplans[i]?.price;

                                        planId = allplans[i]?._id;
                                    }
                                }

                                let additionalFeatures = paymentScreenDetails?.additional;
                                for (let k = 0; k < additionalFeatures?.length; k++) {
                                    for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                        if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                            let obj = {
                                                name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                                amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                            };
                                            additionalFeature.push(obj);
                                        }
                                    }
                                }

                                let plan = object?.plan;
                                // let dateincasepart
                                //object.totalAmount === paid ? dueDate:"Partial"
                                const loginRes = localStorage.getItem("userData");
                                const parseLoginRes = JSON.parse(loginRes);
                                const shipmentName = localStorage.getItem("shipmentName");
                                const shipmentCost = localStorage.getItem("shipmentCost");
                                let dataToSend = {
                                    paymentId: paymentIntent.id,
                                    isAutopay: autoPay,
                                    customerId: object.customerid,
                                    invoiceType: "Sign Up",
                                    totalAmount: object.totalamount,
                                    additionalCharges: additionalFeature,
                                    discount: discounts,
                                    amountPaid: paid,
                                    selectProduct: object?.billId,
                                    invoiceDueDate: dueDate,
                                    isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                                    lateFee: applyLateFee,
                                    invoiceOneTimeCharges: oneTimeCharge,
                                    invoiceStatus: object.totalamount <= paid ? "Paid" : "Partial",
                                    planId: plan,
                                    planName: planName,
                                    planCharges: planCharges,
                                    chargingType: "monthly",
                                    invoicePaymentMethod: object?.paymentMode,
                                    printSetting: "Both",  
                                    isWithInvoice:true, 
                                     
                                    isInvoice: true,
                                    userId: parseLoginRes?._id,
                                    billingPeriod: {
                                        from: "onActivation",
                                        to: "onActivation",
                                    },
                                    isShipmentCostDiscounted: checkLabel,
                                    shipmentCost: {
                                        name: shipmentName,
                                        amount: shipmentCost,
                                    },
                                };
                                if (autoPay) {
                                    dataToSend.paymentMethodId = token;
                                    dataToSend.stripeCustomerId = customerId;
                                }
                                setDataSendForInvoice(dataToSend);
                                Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                                    .then((response) => {  
                                        Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                                            customerId: initiationData?._id,
                                            paymentMethod: "Credit Card",
                                            stripeId: paymentIntent?.id,
                                            action: "success",
                                            reason: "success",
                                            transactionStatus: paymentIntent?.status,
                                            paymentStatus: "success",  
                                            "invoiceNo":response?.data?.data?.invoice,
                                            amount: paid,
                                        })
                                            .then((res) => {
                                                toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });   
                                                setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                                setPaymentScreenActive(false);
                                                setPreviewScreenActive(true); 
                                                },500)
                                            })
                                            .catch((err) => {
                                                toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" }); 
                                                setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                                    setPaymentScreenActive(false);
                                                    setPreviewScreenActive(true); 
                                                    },500)
                                            });
                                          

                                        
                                    })
                                    .catch((err) => {
                                    
                                    });
                            })
                            .catch((err) => {
                       
                            });
                    })
                    .catch((err) => {});
            } else {
                const datatosendincreateAccount = {
                    name: `${parentDetails?.firstName} ${parentDetails?.lastName}`,
                    email: `${parentDetails?.email}`,
                    phone: `+1${parentDetails?.contact}`,
                    description: "New customer account",
                };
                Axios.post(`${BASE_URL}/api/bannerRouter/create_account`, datatosendincreateAccount)
                    .then((customercreation) => {
                        let customerId = customercreation?.data?.data?.id;
                        setPaymentDone(true);
                        //setActiveIndex(3);

                        let additionalFeature = [];
                        let discounts = [];
                        let dueDate = "";
                        let applyLateFee = "";
                        let oneTimeCharge = "";
                        let planName = "";
                        let planId = "";
                        let planCharges = "";
                        dueDate = currentBillingConfig?.dueDate;
                        oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                        applyLateFee = currentBillingConfig?.applyLateFee;
                        let selectedDiscounts = paymentScreenDetails.discount;

                        for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                            for (let k = 0; k < selectedDiscounts.length; k++) {
                                if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                    let obj = {
                                        name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                        amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                    };
                                    discounts.push(obj);
                                }
                            }
                        }
                        for (let i = 0; i < allplans?.length; i++) {
                            if (object.plan === allplans[i]?._id) {
                                planName = allplans[i]?.name;
                                planCharges = allplans[i]?.price;

                                planId = allplans[i]?._id;
                            }
                        }

                        let additionalFeatures = paymentScreenDetails?.additional;
                        for (let k = 0; k < additionalFeatures?.length; k++) {
                            for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                                if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                    let obj = {
                                        name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                        amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                    };
                                    additionalFeature.push(obj);
                                }
                            }
                        }

                        let plan = object?.plan;
                        // let dateincasepart
                        //object.totalAmount === paid ? dueDate:"Partial"

                        const loginRes = localStorage.getItem("userData");
                        const parseLoginRes = JSON.parse(loginRes);
                        const shipmentName = localStorage.getItem("shipmentName");
                        const shipmentCost = localStorage.getItem("shipmentCost");
                        let dataToSend = {
                            paymentId: paymentIntent.id,
                            isAutopay: autoPay,

                            customerId: object.customerid,
                            invoiceType: "Sign Up",
                            totalAmount: object.totalamount,
                            additionalCharges: additionalFeature,
                            discount: discounts,
                            amountPaid: paid,
                            selectProduct: object?.billId,  
                            isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                            invoiceDueDate: dueDate,
                            lateFee: applyLateFee,
                            invoiceOneTimeCharges: oneTimeCharge,
                            invoiceStatus: object.totalamount === paid ? "Paid" : "Partial",
                            planId: plan,
                            planName: planName,    
                            isWithInvoice:true, 
                            planCharges: planCharges,
                            chargingType: "monthly",
                            invoicePaymentMethod:object?.paymentMode,
                            printSetting: "Both",
                            isInvoice: true,

                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },

                            userId: parseLoginRes?._id,
                        };
                        if (autoPay) {
                            dataToSend.paymentMethodId = alternateCardToken;
                            dataToSend.stripeCustomerId = customerId;
                        }
                        setDataSendForInvoice(dataToSend);
                        Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                            .then((response) => {
                              
                                Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`, {
                                    customerId: initiationData?._id,
                                    paymentMethod: "Credit Card",
                                    stripeId: paymentIntent?.id,
                                    action: "success",
                                    reason: "success",
                                    transactionStatus: paymentIntent?.status,
                                    paymentStatus: "success",  
                                    "invoiceNo":response?.data?.data?.invoice,
                                    amount: paid,
                                })
                                    .then((res) => {
                                        toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });   
                                        setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                        setPaymentScreenActive(false);
                                        setPreviewScreenActive(true); 
                                        },500)
                                    })
                                    .catch((err) => {
                                        toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" }); 
                                        setTimeout(()=>{ setAllPaymentInfo(response?.data)
                                            setPaymentScreenActive(false);
                                            setPreviewScreenActive(true); 
                                            },500)
                                    });
                               
                            })
                            .catch((err) => {});

                    })
                    .catch((err) => {});
            }
        }
    };
    const cardElementOptions = {
        hidePostalCode: false,
        style: {
            base: {
                fontSize: "16px",
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="flex w-full flex-wrap flex-row mb-4 p-2 justify-content-center ">
                <div>
                    <div className=" p-2 flex flex-wrap flex-row justify-content-center justify-center">
                        <InputSwitch
                            style={{ backgroundColor: "#0475B4", borderRadius: "100px" }}
                            checked={autoPay}
                            onChange={(e) => {
                                setAutoPay((prev) => !prev);
                            }}
                        />
                        <p className="ml-2"> Auto Pay</p>
                    </div>
                </div>
                {autoPay ? (
                    <Button
                        style={{ backgroundColor: "#7270D1", height: "34px", fontWeight: "300", fontFamily: "Inter", fontSize: "14px" }}
                        label="Auto Pay Card Details"
                        onClick={() => {
                            setAlternateCardDetailVisibility(true);
                        }}
                        className="ml-4"
                    />
                ) : undefined}
            </div>

            <form onSubmit={handleSubmit}>
                <CardElement options={cardElementOptions} />
                <div className="flex flex-wrap flex-row justify-content-center w-full">
                    <button className="paymentsub" ref={submitbuttonref} disabled={disableSubmit}>
                        Submit
                    </button>
                </div>
            </form>
            <Dialog
                header="Alternate Card Details"
                visible={alternateCardDetailVisibility}
                style={{ width: "50vw" }}
                onHide={() => {
                    setAlternateCardDetailVisibility(false);
                }}
            >
                {/*  <AlternateCardPaymentStripModule setAutoPayDate={setAutoPayDate} cardNumber={cardNumber} cardYear={cardYear} cardMonth={cardMonth} cvcNumber={cvcNumber} setCardNumber={setCardNumber} setCvcNumber={setCvcNumber} setCardYear={setCardYear} setCardMonth={setCardMonth} setAlternateCardDetailVisibility={setAlternateCardDetailVisibility} />  */}
                <PaymentStripModuleAlternateCard setAlternateCardToken={setAlternateCardToken} setAlternateCardDetailVisibility={setAlternateCardDetailVisibility} />
            </Dialog>
        </>
    );
}
