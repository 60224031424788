import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import "./Earning-Chart.css";
import moment from "moment";
import Axios from "axios";
import CommonLoaderBlue from "../../../../../components/Loader/LoaderBlue";

const EarningDonutChart = ({ shortSelection, selectedRange }) => {
    const [chartData, setChartData] = useState([]);
    const chartRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const chartContainerRef = useRef(null);

    const initializeChart = () => {
        if (chartContainerRef.current) {
            chartRef.current = echarts.init(chartContainerRef.current, null, { renderer: "svg" });
        }
    };

    const getColorPalette = (numColors) => {
        const baseColors = ["#07DBFA", "#988AFC", "#1F94FF", "#FF928A", "#7086FD", "#6FD195", "#FFAE4C"];
        const extendedColors = [];
        while (extendedColors.length < numColors) {
            extendedColors.push(...baseColors);
        }
        return extendedColors.slice(0, numColors);
    };

    const updateChart = (data) => {
        if (!chartRef.current) return;

        const length = window.innerHeight < 600 ? 15 : window.innerHeight < 800 ? 15 : 15;
        const fontSize = window.innerHeight < 600 ? 8 : window.innerHeight < 800 ? 10 : 12;

        const option = {
            tooltip: {
                trigger: "item", 
                formatter: "{a} <br/>{b} : {c} ({d}%)",     
                backgroundColor: 'rgba(0,0,0,.7)', // Set background color to black
     
                textStyle: {
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize,   
                    color: '#fff',
                },
                padding: [2, 10, 2, 10],
            },
            series: [
                {
                    name: "Earning",
                    type: "pie",
                    radius: ["40%", "70%"],
                    center: ["50%", "50%"],
                    roseType: "radius",
                    label: {
                        fontFamily: "Inter",
                        fontWeight: 400,
                        fontSize,
                        lineHeight: fontSize + 2,
                        formatter: (params) => `${params.name}\n${params.value}`,
                        position: "outside",
                        verticalAlign: "middle",
                    },
                    labelLine: {
                        length: length,
                        length2: length,
                        maxSurfaceAngle: 90,
                    },
                    data,
                },
            ],
        };

        chartRef.current.setOption(option);
    };

    const fetchDataAndUpdateChart = () => {
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/dashboard/getCustomerDetailsWithInventoryType?accountType=Prepaid`, {
            params: {
                filter: shortSelection,
                startDate: moment(selectedRange[0])?.isValid() ? moment(selectedRange[0])?.format("MM-DD-YYYY") : null,
                endData: moment(selectedRange[1])?.isValid() ? moment(selectedRange[1])?.format("MM-DD-YYYY") : null,
            },
        })
            .then((res) => {
                const colors = getColorPalette(res.data.inventoryDetails.length);
                const newChartData = res.data.inventoryDetails.map((item, index) => ({
                    name: item.inventoryName,
                    value: item.totalAmountPaid,
                    itemStyle: { color: colors[index] },
                    label: { color: colors[index] },
                }));
                setChartData(newChartData);
                updateChart(newChartData);
                setIsLoading(false);
            })
            .catch(() => {});
    };

    useEffect(() => {
        initializeChart();
        fetchDataAndUpdateChart();

        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.resize();
                fetchDataAndUpdateChart();
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            if (chartRef.current) {
                chartRef.current.dispose();
            }
        };
    }, [shortSelection, selectedRange]);

    return (
        <>
            <div className="earning-chart-main">
                {isLoading ? (
                    <div className=" flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">
                        <CommonLoaderBlue sizehere={20} color={"rgb(78, 105, 251)"} />
                    </div>
                ) : (
                    " "
                )}
                <h1>Total Earning</h1>
                <div className="flex flex-wrap earning-chart-container-main flex-row justify-content-center">
                    <div ref={chartContainerRef} id="chartdiv" style={{ width: "100%", height: "100%" }}></div>
                </div>
            </div>
        </>
    );
};

export default EarningDonutChart;
