import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckTickBtn, Loginbg, Sniptel } from "../../../../utility";
import { Button } from "primereact/button";
const Login = () => {
    const navigate = useNavigate();
    return (
        <div className="flex w-full flex-column justify-content-center align-items-center" style={{ height: "100vh", background: "white" }}>
            <div className="bggradient flex w-full">
                <Loginbg />
            </div>
            <div className="flex flex-column justify-content-between align-items-center " style={{ width: "483px", height: "561px" }}>
                <div className="sniptel">
                    <span>
                        <Sniptel />
                    </span>
                </div>
                <div className="flex flex-column justify-content-center align-items-center forgetpasspopup " style={{ width: "483px", height: "393px" }}>
                    <div>
                        <CheckTickBtn />
                    </div>
                    <div className="flex flex-column align-items-center checkinbox mt-4">
                        <h1>Success</h1>
                        <p>Your password has been reset. Please log in to continue using Sniptel.</p>
                        <Button label="Login" type="button" onClick={() => navigate("/login")} className="forgetbtn mt-4" />
                    </div>
                </div>
            </div>
        </div>
        // <div className="flex w-full justify-content-center align-items-center" style={{ height: "100vh", background: "white" }}>
        //     <div className="bggradient w-full">
        //         <Loginbg />
        //     </div>
        //     <div className="flex flex-column justify-content-between align-items-center forgetpasspopup">
        //         <div className="flex w-full justify-content-center" style={{ width: "393px", height: "483px" }}>
        //             <div>
        //                 <CheckTickBtn />
        //             </div>
        //             <div className="flex justify-content-center align-items-center flex-column checkinboxsuccess">
        //                 <h1>Success</h1>
        //                 <p>Your password has been reset. Please log in to continue using Sniptel.</p>
        //                 <Button label="Login" type="button" onClick={() => navigate("/login")} className="forgetbtn mt-4" />
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default Login;
