import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";

import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Toast } from "primereact/toast";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CardAuthPayment = ({
    toast,
    parentDetails,
    initiationData,
    currentBillingModel,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
    setdialogForCardAuth,
}) => {
    const [formattedCardNumber, setFormattedCardNumber] = useState("");
    const [cvc, setCvc] = useState("");
    const basicData = localStorage.getItem("basicData");
    const parsebasicData = JSON.parse(basicData);
    const userDetails = parsebasicData?.data;
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        cardNumber: Yup.string().required("Please Enter Card Number"),
        cardCode: Yup.string().required("Please Enter CVC"),
        expirationDate: Yup.string().required("Please select Exp Date"),
        totalAmount: Yup.string().required("Please Select Amount"),
    });
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checked");
        checkLabel = storedItem ? JSON.parse(storedItem) : false;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = false;
    }
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const shipmentName = localStorage.getItem("shipmentName");
    const shipmentCost = localStorage.getItem("shipmentCost");
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            totalAmount: paid,
            cardNumber: "",
            cardCode: "",
            expirationDate: "",
            amount: parseFloat(object.totalamount).toFixed(2),
        },

        onSubmit: async (values, actions) => {
            setIsLoading(true);
            let serviceprovider = JSON.parse(localStorage.getItem("userData")).company;
            let Modules = JSON.parse(localStorage.getItem("permissions"));
            let moduleid;
            for (let i = 0; i < Modules.length; i++) {
                if (Modules[i].module === "Postpaid Orders") {
                    moduleid = Modules[i].moduleId;
                }
            }
            const dataToSend = {
                amount: formik.values.totalAmount,
                cardNumber: formattedCardNumber.replace(/-/g, ""),
                cardCode: formik.values.cardCode,
                expirationDate: formik.values.expirationDate,
                invoiceNo: initiationData.accountId,
                customerId: initiationData?._id,
                userId: parseLoginRes?._id,
            };
            try {
                const response = await Axios.post(`${BASE_URL}/api/web/invoices/PaymentCreditCard`, dataToSend);
                if (response?.data?.data?.status && response?.data?.data?.status === "COMPLETED" && response?.data?.data?.purchase_units[0]?.payments?.captures[0]?.status && response?.data?.data?.purchase_units[0]?.payments?.captures[0]?.status === "COMPLETED") {
                    let additionalFeature = [];
                    let discounts = [];
                    setPaymentDone(true);
                    let dueDate = "";
                    let applyLateFee = "";
                    let oneTimeCharge = "";
                    let planName = "";
                    let planId = "";
                    let planCharges = "";
                    let selectedDiscounts = paymentScreenDetails.discount;
                    dueDate = currentBillingConfig?.dueDate;
                    oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                    applyLateFee = currentBillingConfig?.applyLateFee;
                    for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                        for (let k = 0; k < selectedDiscounts.length; k++) {
                            if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                    amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    for (let i = 0; i < allplans?.length; i++) {
                        if (object.plan === allplans[i]?._id) {
                            planName = allplans[i]?.name;
                            planCharges = allplans[i]?.price;

                            planId = allplans[i]?._id;
                        }
                    }

                    let additionalFeatures = paymentScreenDetails?.additional;
                    for (let k = 0; k < additionalFeatures?.length; k++) {
                        for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                            if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                let obj = {
                                    name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                    amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }

                    if (currentBillingModel === "POSTPAID") {
                        const dataToSend = {
                            invoiceType: "Sign Up",
                            customerId: initiationData?._id,
                            planId: object.plan,
                            paymentId: response?.data?.data?.transId,
                            additionalCharges: object.additional,
                            discount: discounts,
                            totalAmount: parseFloat(object.totalamount).toFixed(2),
                            amountPaid: formik.values.totalAmount,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            invoiceStatus: parseFloat(object.totalamount) <= parseFloat(formik.values.totalAmount) ? "Paid" : parseFloat(object.totalamount) > parseFloat(formik.values.totalAmount) && parseFloat(formik.values.totalAmount) > 0 ? "Partial" : "Unpaid",
                            paymentMethod: "Credit Card",
                            chargingType: "Monthly",
                            printSetting: "Both",

                            isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                            selectProduct: object.billId,
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };

                        setDataSendForInvoice(dataToSend);
                        try {
                            const response = await Axios.post(`${BASE_URL}/api/user/postpaidpaymentDetails`, dataToSend);
                            if (response?.status === 201 || response?.status === 200) {
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            }
                        } catch (error) {
                            toast.error(error?.response?.data?.msg);
                            setIsLoading(false);
                        }
                        setIsLoading(false);
                    } else {
                        const loginRes = localStorage.getItem("userData");
                        const parseLoginRes = JSON.parse(loginRes);

                        let dataToSend = {
                            paymentId: response?.data.data?.transId,
                            isAutopay: false,
                            customerId: object.customerid,
                            invoiceType: "Sign Up",
                            totalAmount: object.totalamount,
                            additionalCharges: additionalFeature,
                            discount: discounts,
                            amountPaid: formik.values.totalAmount,
                            selectProduct: object?.billId,
                            invoiceDueDate: dueDate,
                            isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                            lateFee: applyLateFee,
                            invoiceOneTimeCharges: oneTimeCharge,
                            invoiceStatus: parseFloat(object.totalamount) <= parseFloat(formik.values.totalAmount) ? "Paid" : parseFloat(object.totalamount) > parseFloat(formik.values.totalAmount) && parseFloat(formik.values.totalAmount) > 0 ? "Partial" : "Unpaid",
                            planId: plan,
                            planName: planName,
                            planCharges: planCharges,
                            chargingType: "monthly",
                            invoicePaymentMethod: object?.paymentMode,
                            printSetting: "Both",
                            isInvoice: true,
                            userId: parseLoginRes?._id,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };

                        setDataSendForInvoice(dataToSend);
                        Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                            .then((response) => {
                                setPaymentScreenDetails((prev) => ({ ...prev, paid: formik.values.totalAmount }));

                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setDataSendForInvoice(response?.data);

                                setPreviewScreenActive(true);
                            })
                            .catch((err) => {});
                    }
                    toast.current.show({ severity: "success", summary: "Payment Processed", detail: <p>payment proceed successfully</p> });
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Payment Proceed",
                        detail: <p>Network error. Please check your internet connection and try again</p>,
                    });
                    setdialogForCardAuth(false);
                }
            } catch (err) {
                setdialogForCardAuth(false);
                let business_layer_passed = false;
                let errResponse;
                try {
                    errResponse = JSON.parse(err?.response?.data?.data);
                } catch (err2) {
                    business_layer_passed = true;
                    errResponse = err?.response?.data?.data ? err?.response?.data?.data : err?.response?.data;
                }
                try {
                    toast.current.show({
                        severity: "error",
                        summary: "Payment Processed",
                        detail: !business_layer_passed ? (
                            errResponse?.message ? (
                                errResponse?.details[0]?.description ? (
                                    errResponse?.details[0]?.description
                                ) : (
                                    errResponse?.message
                                )
                            ) : (
                                errResponse?.msg
                            )
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "0500" ? (
                            <p>Your Card Has Been Refused</p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "9500" ? (
                            <p>Your Card Has Been Flagged For Fraudulent Activity. </p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "5400" ? (
                            <p>Your Card Has Been Expired</p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "5180" ? (
                            <p>Your Card Number Is Invalid.</p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "5120" ? (
                            <p>Your Card Has Insufficient Funds</p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "9520" ? (
                            <p>Your Card Has Been Reported Lost or Stolen</p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "1330" ? (
                            <p>Your Card Is Not Valid</p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "5100" ? (
                            <p>Your Card Has Been Declined</p>
                        ) : errResponse?.purchase_units[0]?.payments?.captures[0]?.processor_response?.response_code === "00N7" ? (
                            <p>Your Card's CVC Is Incorrect.</p>
                        ) : (
                            <p>{errResponse?.msg ? errResponse?.msg : "Your Card Has Been Declined"}</p>
                        ),
                    });
                } catch (err) {
                    toast.current.show({
                        severity: "error",
                        summary: "Payment Processed",
                        detail: errResponse?.msg,
                    });
                }
            }

            setIsLoading(false);
        },
    });

    const formatExpirationDate = (value) => {
        value = value.replace(/\D/g, "");
        if (value.length > 4) {
            value = value.slice(0, 4) + "-" + value.slice(4);
        }
        return value;
    };

    // const formatCardNumber = (value) => {
    //     return value
    //         .replace(/\D/g, "")
    //         .replace(/(.{4})/g, "$1-") // Insert hyphens after every 4 digits
    //         .trim()
    //         .slice(0, 19); // Limit to 19 characters
    // };
    const formatCardNumber = (value) => {
        const cleanedValue = value.replace(/\D/g, "");
        if (cleanedValue.startsWith("34") || cleanedValue.startsWith("37")) {
            setCvc("AMEX");
            return cleanedValue
                .replace(/(\d{4})(\d{0,6})(\d{0,5})/, function (match, p1, p2, p3) {
                    if (p3) return `${p1}-${p2}-${p3}`;
                    if (p2) return `${p1}-${p2}`;
                    return p1;
                })
                .slice(0, 17); // Max length of AMEX card format
        } else {
            setCvc("OTHER");
            return cleanedValue.replace(/(.{4})/g, "$1-").slice(0, 19);
        }
    };

    const handleCardNumberChange = (e) => {
        const formattedValue = formatCardNumber(e.target.value);
        formik.setFieldValue("cardCode", "");
        setFormattedCardNumber(formattedValue);
        formik.setFieldValue("cardNumber", formattedValue.replace(/-/g, ""));
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleDialog = () => {
        setdialogForCardAuth(false);
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div>
                    <div className="paymentmethodmain">
                        <div className="flex  w-full justify-content-left align-items-center paymentmethods">
                            <div>
                                <h1>Payment Method</h1>
                            </div>
                            <div className="flex justify-content-between " style={{ marginLeft: "9rem" }}>
                                <button className="paymentbtn ml-6">
                                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  style={{ width: "4rem", height: "4rem" }} src="./paypal.png" alt="" />
                                </button>
                                <button className="paymentbtn ml-4">
                                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  style={{ width: "4rem", height: "4rem", marginLeft: "-.5rem" }} src="./visa.png" alt="" />
                                </button>
                                <button className="paymentbtn ml-4">
                                    <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  style={{ width: "4rem", height: "4rem", marginLeft: "-.5rem" }} src="./american.png" alt="" />
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-column">
                            <label>Card Holder Name</label>
                            <InputText className="flex w-full mt-2 inputlabel" placeholder="Name" />
                        </div>
                        <div className="flex w-full mt-3">
                            <div className="flex flex-column" style={{ width: "33%" }}>
                                <label>City</label>
                                <InputText className=" inputlabel flex mt-2" placeholder="City" />
                            </div>
                            <div className="flex flex-column" style={{ width: "33%" }}>
                                <label className="ml-4">State</label>
                                <InputText className=" inputlabel flex mt-2 ml-4" placeholder="State" />
                            </div>
                            <div className="flex flex-column" style={{ width: "34%" }}>
                                <label className="ml-4">Zip Code</label>
                                <InputText className=" inputlabel flex mt-2 ml-4" placeholder="Zip Code" />
                            </div>
                        </div>
                        <div className="flex flex-column mt-3">
                            <label>Street Address</label>
                            <InputText className=" inputlabel flex w-full mt-2" placeholder="Street Address" />
                        </div>
                        <div className="flex w-full mt-3">
                            <div className="flex flex-column" style={{ width: "49%" }}>
                                <label>Card Number</label>
                                <InputText placeholder="4242-4242-4242-4242" className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("cardNumber") }, "inputlabel mt-2")} type="text" id="cardNumber" value={formattedCardNumber} onChange={handleCardNumberChange} />
                                {getFormErrorMessage("cardNumber")}
                            </div>
                            <div className="flex flex-column ml-3" style={{ width: "49%" }}>
                                <label className="ml-2">Expiry Date</label>
                                <InputText
                                    className={classNames({ " ml-3": true, "p-invalid": isFormFieldValid("expirationDate") }, "inputlabel mt-2 ml-2")}
                                    type="text"
                                    id="expirationDate"
                                    maxLength={7}
                                    placeholder="YYYY-MM"
                                    value={formatExpirationDate(formik.values.expirationDate)}
                                    onChange={(e) => {
                                        const formattedValue = formatExpirationDate(e.target.value);
                                        formik.setFieldValue("expirationDate", formattedValue);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex w-full mt-3">
                            <div className="flex flex-column" style={{ width: "49%" }}>
                                <label>CVC</label>
                                <InputText
                                    placeholder="123"
                                    keyfilter="int"
                                    className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("cardCode") }, "inputlabel mt-2")}
                                    type="text"
                                    id="cardCode"
                                    value={formik.values.cardCode}
                                    maxLength={cvc === "AMEX" ? 4 : 3}
                                    onChange={formik.handleChange}
                                />
                                {getFormErrorMessage("cardCode")}
                            </div>
                            <div className="flex flex-column ml-3" style={{ width: "49%" }}>
                                <label className="ml-2">Total Amount</label>
                                <InputText className="inputlabel flex mt-2 ml-2" type="text" id="amount" value={formik.values.amount} onChange={formik.handleChange} disabled />
                            </div>
                        </div>
                        <div className="flex flex-column mt-3">
                            <label>Payable Amount</label>
                            <InputText disabled type="text" id="totalAmount" value={formik.values.totalAmount} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("totalAmount") }, "inputlabel flex  w-full mt-2")} />
                            {getFormErrorMessage("totalAmount")}
                        </div>
                        <div className="flex w-full justify-content-end mt-3 ">
                            <div>
                                <button type="button" onClick={handleDialog} className="cancelbtn" style={{ marginRight: "10px" }}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button type="submit" disabled={isLoading} className="cancelbtn">
                                    {isLoading && <i className="pi pi-spin pi-spinner " style={{ color: "#3957ed" }} />}
                                    Confirm Payment
                                </button>

                                {/* <Button className="cancelbtn" label="Submit" type="submit" disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} style={{}} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};
export default CardAuthPayment;
