import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import UpdateCard from "./UpdateCard";
import "primeicons/primeicons.css";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const UpdateAutoPay = ({ invoiceData, setShowAutoPay, setRefersh, userDetails, autoPayStatus }) => {
    const [isLoading, setIsLoading] = useState();
    const [showDialog, setShowDialog] = useState(false);
    const [ingredient, setIngredient] = useState("");
    const [showExestingCard, setShowExestingCard] = useState(false);
    const [autoPayDetails, setautoPayDetails] = useState();
    const [cardStatus, setCardStatus] = useState();
    const [cardDetails, setCardDetails] = useState([]);
    const [showDateDialoge, setShowDateDialoge] = useState();
    const [cardId, setCardId] = useState();
    const [showDialogeForActivateCard, setShowDialogeForActivateCard] = useState(false);
    const [showDialogeForRemoveCard, setShowDialogeForRemoveCard] = useState(false);
    const [updateDetails, setUpdateDetails] = useState(false);
    const [updateCard, setUpdateCard] = useState(false);
    const [updateData, setUpdateData] = useState([]);

    const validationSchema = Yup.object().shape({
        day: Yup.string().required("Required"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            day: "",
        },
        onSubmit: async (values, actions) => {},
    });

    useEffect(() => {
        if (autoPayDetails) {
            formik.setValues({ day: autoPayDetails?.day || "" });
        }
    }, [autoPayDetails]);

    const options = [
        { label: "Select", value: "" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        // { label: "29", value: "29" },
        // { label: "30", value: "30" },
    ];

    useEffect(() => {
        if (autoPayStatus) {
            setIngredient("true");
        } else {
            setIngredient("false");
        }
    }, [updateDetails]);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    const changeStatus = async () => {
        try {
            const response = await Axios.put(`${BASE_URL}/api/user/AutoPayStatus?customerId=${userDetails?._id}&isAutopay=${ingredient}`);
            if (response?.status === 200 || response?.status === 201) {
                if (autoPayStatus) {
                    toast.success("Successfully Disabled");
                } else {
                    toast.success("Successfully Enabled");
                }
                setIsLoading(false);
                setTimeout(() => {
                    setRefersh(true);
                    setShowDialog(false);
                }, 500);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setShowDialog(false);
            setIsLoading(false);
        }
    };
    const handleYesClick = async () => {
        setIsLoading(true);
        changeStatus();
    };
    const handleNoClick = () => {
        setShowDialog(false);
        setShowDateDialoge(false);
        setShowDialogeForActivateCard(false);
        setShowDialogeForRemoveCard(false);
        setUpdateDetails((prev) => !prev);
    };

    const getCardDetails = async () => {
        const response = await Axios.get(`${BASE_URL}/api/web/invoices/autopayDetails?customerId=${userDetails?._id}`);

        if (response?.status === 200 || response?.status === 201) {
            setautoPayDetails(response?.data);

            setCardDetails(response?.data?.cardDetails);
        }
    };
    useEffect(() => {
        getCardDetails();
    }, [updateDetails]);

    const handleDayUpdate = async () => {
        setIsLoading(true);
        const dataToSend = {
            customerId: userDetails?._id,
            day: formik.values.day,
        };
        try {
            const response = await Axios.put(`${BASE_URL}/api/web/invoices/dayUpdate`, dataToSend);
            if (response?.status === 200 || response?.status === 201) {
                toast.success("Successfully Updated");
                setShowDateDialoge(false);
                setIsLoading(false);
                setRefersh(true);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
        setIsLoading(false);
    };
    const handleActiveCard = async () => {
        const dataToSend = {
            customerId: userDetails?._id,
            cardId: cardId,
            activeCard: true,
        };
        if (parseLoginRes?.companyName.includes("IJ")) {
            setIsLoading(true);
            try {
                const response = await Axios.put(`${BASE_URL}/api/web/billing/updateCardStatus`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Successfully Activated");
                    setShowDialogeForActivateCard(false);
                    setIsLoading(false);
                    setUpdateDetails((prev) => !prev);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
            setIsLoading(false);
        } else {
            setIsLoading(true);
            try {
                const response = await Axios.put(`${BASE_URL}/api/web/invoices/updateCardStatus`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Successfully Activated");
                    setShowDialogeForActivateCard(false);
                    setIsLoading(false);
                    setUpdateDetails((prev) => !prev);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
            setIsLoading(false);
        }
    };

    const handleRemoveCard = async () => {
        if (cardStatus) {
            toast.error("You cannot remove the only auto pay card. If you wish to change the card, please provide a different card for auto pay. If you wish to disable this option, please disable the auto pay feature..");
            setShowDialogeForRemoveCard(false);
        } else {
            setIsLoading(true);
            try {
                const response = await Axios.delete(`${BASE_URL}/api/web/invoices/removeCardDetail?customerId=${userDetails?._id}&cardId=${cardId}`);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Removed Successfully");
                    setShowDialogeForRemoveCard(false);
                    setIsLoading(false);
                    setUpdateDetails((prev) => !prev);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
            setIsLoading(false);
        }
    };
    return (
        <>
            <ToastContainer />
            {updateData.length > 0 && (
                <Dialog draggable={false} visible={updateCard} header={`Update Card Details`} style={{ width: "60vw" }} onHide={() => setUpdateCard((prev) => !prev)}>
                    <UpdateCard userDetails={userDetails} setUpdateCard={setUpdateCard} setUpdateDetails={setUpdateDetails} updateData={updateData} />
                </Dialog>
            )}
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-10  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>
                                        <tr className="text-lg">
                                            <td>Current Auto Pay Status</td>
                                            <td>
                                                <RadioButton
                                                    inputId="enabled"
                                                    name="pizza"
                                                    value="true"
                                                    onChange={(e) => {
                                                        setIngredient(e.value);
                                                        setShowDialog(true);
                                                    }}
                                                    checked={ingredient === "true"}
                                                />
                                                <label htmlFor="enabled" className="ml-2">
                                                    Enabled
                                                </label>
                                                <RadioButton
                                                    className="ml-5"
                                                    inputId="disabled"
                                                    name="pizza"
                                                    value="false"
                                                    onChange={(e) => {
                                                        setIngredient(e.value);
                                                        setShowDialog(true);
                                                    }}
                                                    checked={ingredient === "false"}
                                                />
                                                <label htmlFor="disabled" className="ml-2">
                                                    Disabled
                                                </label>
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>Amount</td>
                                            {/* <td>${invoiceData[0]?.dueAmount}</td> */}
                                            <td>$ {autoPayDetails?.totalAmount}</td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Day Of Month <span style={{ color: "red" }}>*</span>
                                            </td>
                                            <td>
                                                <Dropdown
                                                    options={options}
                                                    value={formik.values.day}
                                                    onChange={(e) => {
                                                        formik.handleChange(e);
                                                        setShowDateDialoge(true);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    id="day"
                                                    className={classNames({ "p-invalid": isFormFieldValid("day") })}
                                                />
                                                <span className="ml-2">Of every month</span>
                                                {getFormErrorMessage("day")}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="show-card" onClick={() => setShowExestingCard((prev) => !prev)}>
                    <p>Auto Pay Cards {showExestingCard ? <i className="pi pi-arrow-down ml-2"></i> : <i className="pi pi-arrow-right ml-2"></i>} </p>
                </div>
                {showExestingCard && (
                    <>
                        {cardDetails.map((item, index) => (
                            <div key={item?._id}>
                                <div>
                                    <RadioButton
                                        inputId={`cardEnabled_${item?._id}`} // Ensure unique inputId
                                        name="cardStatus"
                                        value="true"
                                        onChange={() => {
                                            setCardId(item?._id);
                                            setShowDialogeForActivateCard(true);
                                        }}
                                        checked={item?.activeCard === true}
                                        className="mt-3 ml-3"
                                    />
                                </div>
                                <div className="credit-card mt-1 ml-5 mb-2">
                                    <div className="credit-card__details">
                                        <div className="credit-card__pics">
                                            <div className="flex">
                                                <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" width={48} className="mb-3" />
                                                <p className="font-bold ml-3 mt-1">{item?.cardType}</p>
                                            </div>
                                            <img  onError={(e)=>{ 
    e.target.src="./placeholderimage.jpg"
  }}  src={`${process.env.PUBLIC_URL}/chip.png`} alt="chip" width={48} className="mb-3 mr-2" />
                                        </div>
                                        <div className="card-num-name">
                                            <div className="credit-card__number">**** **** **** {item?.cardNumber}</div>
                                            <div className="credit-card__expiry">Expires: {item?.expirationDate}</div>
                                        </div>
                                        <div className="credit-card__holder">
                                            {item?.nameOnCard}
                                            <span className="update-btn">
                                                <p
                                                    className="ml-5 mr-2 card-button"
                                                    onClick={() => {
                                                        setUpdateData([
                                                            {
                                                                nameOnCard: item?.nameOnCard,
                                                                cardId: item?._id,
                                                                cardType: item?.cardType,
                                                                cardNumber: item?.cardNumber,
                                                                expirationDate: item?.expirationDate,
                                                                billingAddress: item?.billingAddress,
                                                                billingAddress2: item?.billingAddress2,
                                                                securityCode: item?.securityCode,
                                                            },
                                                        ]);
                                                        setUpdateCard((prev) => !prev);
                                                    }}
                                                >
                                                    Update
                                                </p>

                                                <p className="separator"></p>

                                                <p
                                                    className="ml-2 mr-2 card-button"
                                                    onClick={() => {
                                                        setCardId(item?._id);
                                                        setCardStatus(item?.activeCard);
                                                        setShowDialogeForRemoveCard(true);
                                                    }}
                                                >
                                                    Remove
                                                </p>
                                            </span>
                                        </div>
                                        <div className="edit-address">Billing Address:</div>
                                        <div className="mt-3">{item?.billingAddress}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}

                <div className="add-card" onClick={() => setShowAutoPay((prev) => !prev)}>
                    <p>
                        Add New Card <i className="pi pi-arrow-right ml-2 "></i>
                    </p>
                </div>
            </form>
            <Dialog
                header="Warning"
                visible={showDialog}
                style={{ width: "30vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleYesClick} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => {
                    setShowDialog(false);
                    setUpdateDetails((prev) => !prev);
                }}
            >
                {autoPayStatus ? (
                    <>
                        {" "}
                        <p>
                            {" "}
                            <span className="font-bold">Are you sure you want to disable Auto Pay? </span> Disabling this feature will stop automatic payments, and all future payments will have to be made manually.
                        </p>
                    </>
                ) : (
                    <>
                        {" "}
                        <p>
                            {" "}
                            <span className="font-bold">Are you sure you want to Enabe Auto Pay? </span> Enabling this feature will allow automatic payments, and all future payments will have to be made automatically.
                        </p>{" "}
                    </>
                )}
            </Dialog>

            <Dialog
                header="Warning"
                visible={showDateDialoge}
                style={{ width: "30vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleDayUpdate} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => setShowDateDialoge(false)}
            >
                <p>
                    <span className="font-bold"> Are you sure you want to change the Auto Pay date? </span> This action will update the scheduled date for auto payments.
                </p>
            </Dialog>

            <Dialog
                header="Warning"
                visible={showDialogeForActivateCard}
                style={{ width: "30vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleActiveCard} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => setShowDialogeForActivateCard(false)}
            >
                <p>
                    <span className="font-bold"> Are you sure you want to switch the credit card for Auto Pay? </span> This action will change the auto pay method to the currently selected card, and all future payments will be deducted from this card.{" "}
                </p>
            </Dialog>

            <Dialog
                header="Warning"
                visible={showDialogeForRemoveCard}
                style={{ width: "30vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleRemoveCard} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => setShowDialogeForRemoveCard(false)}
            >
                <p>
                    <span className="font-bold"> Are you sure you want to Remove this card? </span>{" "}
                </p>
            </Dialog>
        </>
    );
};

export default UpdateAutoPay;
