import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckFalseBtn, CheckTickBtn, Loginbg } from "../../../../utility";
import { Button } from "primereact/button";

const Invalid = () => {
    const navigate = useNavigate();
    return (
        <div className="flex w-full justify-content-center align-items-center" style={{ height: "100vh", background: "white" }}>
            <div className="bggradient">
                <Loginbg />
            </div>
            <div className="flex flex-column justify-content-evenly align-items-center forgetpassinvalid">
                <div>
                    <CheckFalseBtn />
                </div>
                <div className="flex justify-content-center align-items-center flex-column checkinboxinvalid">
                    <h1>Link Expired</h1>
                    <p>This Password change link is expired for the security reasons. Please click on the last link you received or re-send an email to recover my password. </p>
                    <Button label="Reset Password" type="button" onClick={() => navigate("/sendotp")} className="forgetbtn mt-4" />
                </div>
            </div>
        </div>
    );
};

export default Invalid;
