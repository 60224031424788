import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./css/billingmodelconfig.css";
import Axios from "axios";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import AddBillingModel from "./components/add_billing_model";
import { DeActive, InActive } from "../assets/svg";
import InventoryList from "./components/InventoryList/InventoryList";
import CommonLoaderBlue from "./Loaders/components/Loader/LoaderBlue";
import { InputSwitch } from "primereact/inputswitch";
import UpdateBillingModel from "./components/UpdateBillingModel/UpdateBillingModel";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function BillingModelConfigurations() {
    const loginRes = localStorage.getItem("userData");
    const [loader, setLoader] = useState(true);
    const [statusChangeVisibility, setStatusChangeVisibility] = useState(false);
    const [statusLoading, setStatusLoading] = useState(false);
    const parseLoginRes = JSON.parse(loginRes);
    const [billingModelList, setBillingModelList] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [addBillingModelVisibility, setAddBillingModelVisibility] = useState(false);
    const [refresh, setRefresh] = useState(false); 
    const [updateBillingVisibility,setUpdateBillingVisibility]=useState(false)
    const [inventoryListVisibility, setInventoryListVisibility] = useState(false);
    useEffect(() => {
        Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setBillingModelList(res?.data?.data);
                setLoader(false);
            })
            .catch((err) => {});
    }, [refresh]);

    const updateBillingModelStatus = () => {
        setStatusLoading(true);
        Axios.put(`${BASE_URL}/api/billingModel/statusUpdate`, { billingModelId: rowData._id, active: rowData?.active ? false : true })
            .then((res) => {
                toast.success(res?.data?.msg !== undefined ? res?.data?.msg : `Billing Modlel ${rowData?.active ? "Deactivated" : "Activated"} Successfully`);
                setStatusLoading(false);
                setStatusChangeVisibility(false);
                setRefresh((prev) => !prev);
            })
            .catch((err) => {
                toast.error(err?.response?.data?.msg !== undefined ? err?.response?.data?.msg : `Billing Modlel ${rowData?.active ? "Deactivation" : "Activation"} Successfully`);
            });
    };
    return (
        <div className="overflow-hidden main-billingconfig card">
            <ToastContainer />
            <div className="updaterestore">
                {/*   <Button className="text-center mr-0" label="Restore Models" 
                  onClick={()=>{ 
                    setRestoreModelsVisiblity(prev=>!prev)
                  }}
                 />   */}
                <Button
                    onClick={() => {
                        setAddBillingModelVisibility(true);
                    }}
                    icon="pi pi-plus"
                    className="text-center mr-0"
                    label="Add Billing Model"
                />
            </div>

            <Dialog
                header="Add Billing Model"
                visible={addBillingModelVisibility}
                style={{ width: "85vw" }}
                onHide={() => {
                    setAddBillingModelVisibility(false);
                    setRefresh((prev) => !prev);
                }}
            >
                <AddBillingModel setRefresh={setRefresh} setAddBillingModelVisibility={setAddBillingModelVisibility} />
            </Dialog>

            <DataTable
                value={billingModelList}
                size="small"
                emptyMessage={
                    loader ? (
                        <div className="w-full flex flex-wrap flex-row justify-content-center align-items-center ">
                            <CommonLoaderBlue />
                        </div>
                    ) : (
                        "Billing Models Not Found"
                    )
                }
                stripedRows
                resizableColumns
                style={{ marginTop: "10px" }}
            >
                <Column
                    header="ID"
                    field="_id"
                    body={(rowData) => {
                        let id = rowData._id.toUpperCase();
                        return (
                            <p
                                onClick={() => {
                                    setRowData(rowData);
                                    setInventoryListVisibility(true);
                                }}
                                className="cursor-pointer underline"
                            >
                                {id.substring(Math.max(0, id.length - 4))}
                            </p>
                        );
                    }}
                />
                <Column header="Billing Model" field="billingModel" />

                <Column
                    header="Actions"
                    headerClassName="p-3"
                    body={(rowData) => {
                        return (
                            
                            <div className="actionsbilling flex flex-wrap flex-row justify-content-left gap-4">
                                   <Button
                                        label=""
                                        style={{ height: "25px", paddingTop: "0px !imprtant", paddingBottom: "0px !important" }}
                                        icon="pi pi-pencil"
                                        onClick={() => {
                                            setRowData(rowData);
                                            setUpdateBillingVisibility(true)
                                        }}
                                    />
                                 
                                  {!rowData?.active ? (
                                   <InputSwitch checked={false}    
                                   onClick={()=>{ 
                                       setRowData(rowData);
                                       setStatusChangeVisibility(true); 
                                   }}  
                                    onChange={(e) => { 
                                        e.preventDefault()   
                                        setRowData(rowData);
                                       setStatusChangeVisibility(true); 

                                    }}     
                                       />
                                ) : (
                                                
                                    <InputSwitch checked={true}    
                                    onClick={()=>{ 
                                        setRowData(rowData);
                                        setStatusChangeVisibility(true); 
                                    }}  
                                     onChange={(e) => { 
                                         e.preventDefault()   
                                         setRowData(rowData);
                                        setStatusChangeVisibility(true); 

                                     }}   
                                     
                                     />
                                )}
                            </div>
                        );
                    }}
                    field="Edit"
                />
            </DataTable>
            <Dialog
                visible={inventoryListVisibility}
                onHide={() => {
                    setInventoryListVisibility(false);
                }}
                style={{ width: "85vw" }}
            >
                <InventoryList billingModelSelect={rowData?._id} />
            </Dialog>    
            <Dialog
                visible={updateBillingVisibility}
                onHide={() => {
                    setUpdateBillingVisibility(false);
                }}
            >
                <UpdateBillingModel setRefresh={setRefresh} setUpdateBillingVisibility={setUpdateBillingVisibility} rowData={rowData} />
            </Dialog>
            <Dialog
                header={rowData?.active ? "Billing Model Deactivation" : "Billing Model Activation"}
                headerClassName="font-poppins text-main-color"
                visible={statusChangeVisibility}
                onHide={() => {
                    setStatusChangeVisibility((prev) => !prev);
                }}
            >
                <p className="text-main-color font-poppins">Do You Want To {rowData?.active ? "Deactivate" : "Activate"} the Billing Model ?</p>
                <div className="mt-4  changestatusdialogbutton flex font-poppins flex-wrap flex-row ">
                    <Button onClick={updateBillingModelStatus} loading={statusLoading} disabled={statusLoading} label="Yes" />

                    <Button
                        label="No"
                        onClick={() => {
                            setStatusChangeVisibility(false);
                        }}
                    />
                </div>
            </Dialog>
        </div>
    );
}
