import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import React from "react";
import { Button } from "primereact/button";
import classNames from "classnames";
import ShowConfrimMessage from "./ShowConfrimMessage";
import { Dialog } from "primereact/dialog";

 const ChangeDueDate =({invoiceData,setChangeDueDate,setRefresh,cpData})=>{
    const [showConfrimMessage,setShowConfrimMessage]=useState(false)
    const validationSchema = Yup.object().shape({
        newDate: Yup.string().required("This is Required"),
    })
const formik = useFormik({
    validationSchema:validationSchema,
   initialValues:{
    newDate:""
   } ,
   onSubmit:()=>{
    setShowConfrimMessage(true);
   }
})
    const options = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "24", value: "24" },
        { label: "25", value: "25" },
        { label: "26", value: "26" },
        { label: "27", value: "27" },
        { label: "28", value: "28" },
        // { label: "29", value: "29" },
        // { label: "30", value: "30" },
    ]
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return(
        <>
        <form onSubmit={formik.handleSubmit}>
      <Dialog visible={showConfrimMessage} style={{ width: "50vw" }} onHide={() => setShowConfrimMessage((prev) => !prev)}>
        <ShowConfrimMessage setChangeDueDate={setChangeDueDate} setShowConfrimMessage={setShowConfrimMessage} setRefresh={setRefresh} cpData={cpData} newDate={formik.values.newDate}/>
      </Dialog>
         <div className="p-fluid formgrid grid">
                    <div className="field col-12 md:col-4">
                        <label className="field_label">
                            Current Due Date
                        </label>
                        <InputText
                            value={invoiceData[0]?.invoiceDueDate}
                        /> 
                    </div>
                    <div className="field col-12 md:col-4">
                        <label className="field_label">
                            Change Due Date To
                        </label>
                        <Dropdown
                        options={options}
                        value={formik.values.newDate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="newDate"
                        className={classNames({ "p-invalid": isFormFieldValid("salesChannel") })}
                        />
                        {getFormErrorMessage("newDate")}
                    </div>
                    <label className=" mt-5">
                    of each month
                        </label>

                    </div>
                    <div className="dueDatebtn">
                    <Button className="w-15rem" label="Submit" type="submit"/>
                    </div>
                  
                    </form>
        </>
    )
}
export default ChangeDueDate;